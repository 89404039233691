/* eslint-disable */
import React, { useState, useEffect } from 'react';
import config from 'react-global-configuration';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useKeycloak } from '@react-keycloak/web';
import { WatDatePicker } from 'thaidatepicker-react';
import { Button, TextField } from '@material-ui/core';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import th from 'date-fns/locale/th';
registerLocale('th', th);
import isRole from '../util/isRole';
import DateInput from '../util/DateInput';

import 'react-datepicker/dist/react-datepicker.css';

import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';

import $ from 'jquery';

const ReportLeaveDetail = () => {
  const [keycloak] = useKeycloak();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dataTable, setDataTable] = useState(null);
  const [workbu, setWorkbu] = useState([]);
  const [orgId, setOrgId] = useState('');
  const [dayCount, setDayCount] = useState({
    workDayCount: 0,
    empWorkDayCount: 0,
    empNotWorkDayCount: 0,
    lateDayCount: 0,
  });

  useEffect(() => {
    fetchData();
  }, [keycloak.token]);

  const fetchData = async () => {
    let rsWorkbu = await axios.get(
      config.get('apiUrl') + '/api/master/org/1/1001',
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    rsWorkbu.data.data.records.sort((a, b) => (a.name > b.name ? 1 : -1));
    setWorkbu(rsWorkbu.data.data.records);

    if (keycloak.tokenParsed) {
      let rsUser = await axios.get(
        config.get('apiUrl') +
          '/api/employee/user-login/work-bu1',
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsUser.data.status === 'success') {
        let empOrgId = rsUser.data.data || '';
        setOrgId(empOrgId);

        initDataTable(empOrgId);
        searchDayCount(empOrgId);
      }
    }
  };

  const initDataTable = (empOrgId = null) => {
    $.fn.dataTable.ext.errMode = 'none';

    empOrgId = empOrgId ? empOrgId : orgId;

    setDataTable(
      $('#data-table').DataTable({
        order: [0, 'asc'],
        ajax: {
          contentType: 'application/json',
          url:
            config.get('apiUrl') +
              '/api/export-excel/report/working-in-out/datatable?' +
              'startDate=' +
              moment(startDate).format('YYYY-MM-DD') +
              '&endDate=' +
              moment(endDate).add(1, 'day').format('YYYY-MM-DD') +
              '&orgId=' +
              empOrgId,
          type: 'POST',
          data: function (d) {
            return JSON.stringify(d);
          },
          headers: {
            Authorization: `bearer ${keycloak.token}`,
          },
        },
        processing: true,
        serverSide: true,
        columns: [
          { data: 'workDate', visible: false },
          {
            data: 'workDateShow',
            className: 'text-center',
            responsivePriority: 1,
          },
          { data: 'empFullName' },
          {
            data: 'empTimeIn',
            className: 'text-center',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          { data: 'locationIn', className: 'text-center' },
          {
            data: 'empTimeOut',
            className: 'text-center',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          { data: 'locationOut', className: 'text-center' },
        ],
        lengthChange: true,
        ordering: true,
        info: true,
        autoWidth: false,
        responsive: true,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'ค้นหา...',
          lengthMenu: '_MENU_ รายการ/หน้า',
          sLengthMenu: '_MENU_ รายการ/หน้า',
          sProcessing: 'กำลังดำเนินการ...',
          sZeroRecords: 'ไม่พบข้อมูล',
          sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
          sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
          sInfoFiltered: '',
          sSearch: '',
          sUrl: '',
          oPaginate: {
            sFirst: 'หน้าแรก',
            sPrevious: 'ก่อนหน้า',
            sNext: 'ถัดไป',
            sLast: 'หน้าสุดท้าย',
          },
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
        },
      })
    );

    // Datable Component CSS.
    document
      .querySelector('div.dataTables_length select')
      .classList.add('datatable-length');
    document
      .querySelector('div.dataTables_filter input')
      .classList.add('datatable-search');
  };

  const startDateChange = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const endDateChange = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setStartDate(date);
    }
  };

  const searchDataTable = () => {
    if (dataTable) {
      searchDayCount();
      dataTable.ajax
        .url(
          config.get('apiUrl') +
            '/api/export-excel/report/working-in-out/datatable?' +
            'startDate=' +
            moment(startDate).format('YYYY-MM-DD') +
            '&endDate=' +
            moment(endDate).add(1, 'day').format('YYYY-MM-DD') +
            '&orgId=' +
            orgId
        )
        .load();
    }
  };

  const searchDayCount = async (empOrgId = null) => {
    empOrgId = empOrgId ? empOrgId : orgId;
    let params =
      '?startDate=' +
      moment(startDate).format('YYYY-MM-DD') +
      '&endDate=' +
      moment(endDate).add(1, 'day').format('YYYY-MM-DD') +
      '&orgId=' +
      empOrgId;
    await axios
      .get(
        config.get('apiUrl') +
          '/api/export-excel/report/working-in-out/day-count' +
          params,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      )
      .then((response) => {
        setDayCount(response.data);
      })
      .catch((error) => console.log(error));
  }

  const exportFile = async () => {
    let params =
      '?startDate=' +
      moment(startDate).format('YYYY-MM-DD') +
      '&endDate=' +
      moment(endDate).add(1, 'day').format('YYYY-MM-DD') +
      '&orgId=' +
      orgId;
    await axios
      .get(
        config.get('apiUrl') +
          '/api/export-excel/report/working-in-out' +
          params,
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">รายงาน</li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    รายงานตารางเข้างาน ออกงาน
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                          รายงานตารางเข้างาน ออกงาน
                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              ช่วงวันที่
                            </span>
                          </div>
                          <div className="col-12 col-md-4 pt-1">
                            <div className="row">
                              <div className="col-5">
                                <DateInput
                                  value={startDate}
                                  onChange={(date) => startDateChange(date)}
                                  label="วันที่เริ่มต้น"
                                />
                              </div>
                              <div className="col-2 text-center mt-2">ถึง</div>
                              <div className="col-5">
                                <DateInput
                                  value={endDate}
                                  onChange={(date) => endDateChange(date)}
                                  label="วันที่สิ้นสุด"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              หน่วยงาน
                            </span>
                          </div>
                          <div className="col-12 col-md-4 pt-1">
                            <select
                              className="form-control shadow-none"
                              name="workbu"
                              value={orgId}
                              onChange={(e) => setOrgId(e.target.value)}
                              disabled={isRole(['admin', 'boss']) ? false : true}
                            >
                              <option value="">กรุณาเลือก</option>
                              {workbu.map((item) => (
                                <option key={item.org_id} value={item.org_id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-no-border btn-color-search"
                            onClick={searchDataTable}
                          >
                            <i className="fas fa-search pr-2"></i>
                            ค้นหา
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-no-border btn-color-search ml-3"
                            onClick={exportFile}
                          >
                            <i className="fas fa-file-export pr-2"></i>
                            ส่งออกข้อมูล
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="card">
                  <div className="small-box bg-primary mb-0">
                    <div className="inner">
                      <p>ทั้งหมด</p>
                      <h2 className="text-center">{dayCount.workDayCount}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="small-box bg-warning mb-0">
                    <div className="inner">
                      <p>มาทำงาน</p>
                      <h2 className="text-center">{dayCount.empWorkDayCount}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="small-box bg-success mb-0">
                    <div className="inner">
                      <p>มาสาย</p>
                      <h2 className="text-center">{dayCount.lateDayCount}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="small-box bg-danger mb-0">
                    <div className="inner">
                      <p>ไม่มาทำงาน</p>
                      <h2 className="text-center">{dayCount.empNotWorkDayCount}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <table
                    id="data-table"
                    className="table table-striped table-bordered display w-100 margin-bottom-30"
                  >
                    <thead>
                      <tr>
                        <th className="text-center"></th>
                        <th className="text-center">วันที่</th>
                        <th className="text-center">ชื่อ-สกุล</th>
                        <th className="text-center">เวลาเข้า</th>
                        <th className="text-center">พิกัดเข้า</th>
                        <th className="text-center">เวลาออก</th>
                        <th className="text-center">พิกัดออก</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default ReportLeaveDetail;
