/* eslint-disable */
import React, { useState, useEffect } from 'react';
import config from 'react-global-configuration';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useKeycloak } from '@react-keycloak/web';
import { WatDatePicker } from 'thaidatepicker-react';
import { Button, TextField } from '@material-ui/core';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import th from 'date-fns/locale/th';
registerLocale('th', th);
import isRole from '../util/isRole';
import DateInput from '../util/DateInput';
import {useSelector, useDispatch} from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';
import {
  userId,
  setUserId,
} from "../../app/Admin/PersonnelInfoSlice";

import $ from 'jquery';
import { hasAnyRole } from '../util/AuthUtil';

const PersonnelInfoDataTable = () => {
  let history = useHistory();
  const [keycloak] = useKeycloak();

  const [dataTable, setDataTable] = useState(null);
  const [workbu, setWorkbu] = useState([]);
  const [orgId, setOrgId] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [keycloak.token]);

  const addUser = () => {
    dispatch(setUserId(''));
    let path = `/admin-personnel-infomation`; 
    history.push(path);
  }

  const fetchData = async () => {
    let rsWorkbu = await axios.get(
      config.get('apiUrl') + '/api/master/org/1/1001',
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    rsWorkbu.data.data.records.sort((a, b) => (a.name > b.name ? 1 : -1));
    setWorkbu(rsWorkbu.data.data.records);

    if (keycloak.tokenParsed) {
      let rsUser = await axios.get(
        config.get('apiUrl') +
          '/api/employee/user-login/work-bu1',
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsUser.data.status === 'success') {
        let empOrgId = rsUser.data.data || '';
        setOrgId(empOrgId);

        initDataTable(empOrgId);
      }
    }
  };

  const manageUser = (userId) =>{ 
    dispatch(setUserId(userId));
    let path = `/admin-personnel-infomation`; 
    history.push(path);
  }

  const initDataTable = (empOrgId = null) => {
    $.fn.dataTable.ext.errMode = 'none';

    empOrgId = empOrgId ? empOrgId : orgId;

    setDataTable(
      $('#data-table').DataTable({
        order: [0, 'asc'],
        ajax: {
          contentType: 'application/json',
          url: config.get('apiUrl') + '/api/v2/employee/datatable',
          type: 'POST',
          data: function (d) {
            return JSON.stringify(d);
          },
          headers: {
            Authorization: `bearer ${keycloak.token}`,
          },
        },
        processing: true,
        serverSide: true,
        columns: [
          {
            width: '100px',
            orderable: true,
            data: 'employeeId',
            className: 'text-center',
            responsivePriority: 1,
          },
          { 
            width: '100px',
            orderable: true,
            data: 'idCard',
            className: 'text-center',
          },
          { 
            width: '150px',
            orderable: true,
            data: 'fname',
            className: 'text-left',
          },
          { 
            width: '150px',
            orderable: true,
            data: 'lname',
            className: 'text-left',
          },
          { 
            width: '300px',
            orderable: true,
            data: 'orgName',
            className: 'text-left',
          },
          { 
            width: '100px',
            orderable: false,
            data: '',
            className: 'text-center',
            render: function (data, type, row) {
              return `<button class="btn bg-color text-light btn-manage-user" type="button""><i class="fas fa-user-cog mr-2"></i>แก้ไข</button>`;
            }  
          },
        ],
        rowCallback: function (row, data) {
          $('td', row)
          .find('.btn-manage-user')
          .on('click', function (e) {
            manageUser(data.employeeId);
          });

        },  
        lengthChange: true,
        ordering: true,
        info: true,
        autoWidth: false,
        responsive: true,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'ค้นหา...',
          lengthMenu: '_MENU_ รายการ/หน้า',
          sLengthMenu: '_MENU_ รายการ/หน้า',
          sProcessing: 'กำลังดำเนินการ...',
          sZeroRecords: 'ไม่พบข้อมูล',
          sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
          sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
          sInfoFiltered: '',
          sSearch: '',
          sUrl: '',
          oPaginate: {
            sFirst: 'หน้าแรก',
            sPrevious: 'ก่อนหน้า',
            sNext: 'ถัดไป',
            sLast: 'หน้าสุดท้าย',
          },
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
        },
      })
    );

    // Datable Component CSS.
    document
      .querySelector('div.dataTables_length select')
      .classList.add('datatable-length');
    document
      .querySelector('div.dataTables_filter input')
      .classList.add('datatable-search');
  };

  const searchDataTable = () => {
    if (dataTable) {
      dataTable.ajax
        .url(config.get('apiUrl') + '/api/v2/employee/datatable')
        .load();
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">ผู้ดูแลระบบ</li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ข้อมูลบุคลากร
                  </li>
                </ol>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                          ข้อมูลบุคลากร
                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              หน่วยงาน
                            </span>
                          </div>
                          <div className="col-12 col-md-4 pt-1">
                            <select
                              className="form-control shadow-none"
                              name="workbu"
                              value={orgId}
                              onChange={(e) => setOrgId(e.target.value)}
                              disabled={isRole(['admin', 'boss']) ? false : true}
                            >
                              <option value="">กรุณาเลือก</option>
                              {workbu.map((item) => (
                                <option key={item.org_id} value={item.org_id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-no-border btn-color-search"
                            onClick={searchDataTable}
                          >
                            <i className="fas fa-search pr-2"></i>
                            ค้นหา
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <span className="text-header-x2 ddc-color">
                    ข้อมูลบุคลากร
                  </span>
                  <br/><br/>
                  <div className="row">
                    <div className="col-12">
                      <button 
                        onClick={addUser}
                        style={{ color:'white' }} 
                        className="btn bg-color mb-2">
                          <i className="fas fa-user-plus"></i>&nbsp;&nbsp;เพิ่มข้อมูลบุคลากร
                      </button>
                    </div>
                  </div>
                  <br/>
                  <table
                    id="data-table"
                    className="table table-striped table-bordered display w-100 margin-bottom-30"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">บัญชี</th>
                        <th className="text-center">รหัสบัตร</th>
                        <th className="text-center">ชื่อ</th>
                        <th className="text-center">นามสกุล</th>
                        <th className="text-center">หน่วยงาน</th>
                        <th className="text-center">ดำเนินการ</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default PersonnelInfoDataTable;
