/* eslint-disable */
import React, { useState, useEffect } from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import axios from "axios";
import config from "react-global-configuration";
import {useKeycloak} from "@react-keycloak/web";
import NumberFormat from 'react-number-format';

const AdminDashboard = () => {
    const [keycloak] = useKeycloak();
    const [options, setOption] = useState([]);
    const [work, setWork] = useState([]);
    const [title, setTitle] = useState([]);

    const fetchData = async () => {
        const now = await axios.get(config.get("apiUrl") + "/api/admindashboard/now",
            {headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});
        setTitle(now.data)
        const work = await axios.get(config.get("apiUrl") + "/api/admindashboard/work",
            {headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});
        setWork(work.data)
        const leaveHistory = await axios.get(config.get("apiUrl") + "/api/admindashboard/leaveHistory",
            {headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});

        const tmp = {
            chart: {
                type: 'line'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: leaveHistory.data.categories
            },
            yAxis: {
                title: {
                    text: 'จำนวนบุคลากร (คน)'
                }

            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: [{
                name: '',
                data: leaveHistory.data.data
            }]
        }

        setOption(tmp)
    };

    useEffect(() => {
        fetchData()
    }, []);

    return (
      <div>
          <div className="content-wrapper">
              <section className="content">
                  <Container fluid>
                      <div className="row">
                          <div className="col-12 content-top-padding"></div>
                      </div>
                      <div className="row mb-3 border-bottom">
                          <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                              <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                  <li className="breadcrumb-item text-breadcrumb">
                                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1"/>
                                      <Link to="./" className="text-breadcrumb">
                                          หน้าหลัก
                                      </Link>
                                  </li>
                                  <li className="breadcrumb-item text-breadcrumb">
                                      รายงาน
                                  </li>
                                  <li className="breadcrumb-item text-breadcrumb-active">
                                      ภาพรวบระบบ
                                  </li>
                              </ol>
                          </div>
                      </div>
                      <h4 className="mt-4 mb-4">ภาพรวบระบบ {title}</h4>

                      <div className="row">
                          <div className="col-md-6">

                              <div className="card">
                                  <div className="small-box bg-success">
                                      <div className="inner">
                                          <p>มาปฏิบัตงาน</p>
                                          <h2>{work.workPercent}%</h2>
                                          <p>(<NumberFormat displayType={'text'} thousandSeparator={true} value={work.work} /> คน)</p>
                                      </div>
                                      <div className="icon">
                                          <i className="fas fa-smile"></i>
                                      </div>
                                  </div>

                                  <div className="card-footer p-0">
                                      <ul className="nav flex-column">
                                          <li className="nav-item">
                                              <a href="#" className="nav-link">
                                                  ปฏิบัตงานในสถานที่ตั้ง <span className="float-right badge bg-primary"><NumberFormat displayType={'text'} thousandSeparator={true} value={work.indoor} /> คน</span>
                                              </a>
                                          </li>
                                          <li className="nav-item">
                                              <a href="#" className="nav-link">
                                                  ปฏิบัตงานนอกสถานที่ตั้ง <span className="float-right badge bg-info"><NumberFormat displayType={'text'} thousandSeparator={true} value={work.outdoor} /> คน</span>
                                              </a>
                                          </li>

                                      </ul>
                                  </div>
                              </div>

                          </div>

                          <div className="col-md-6">

                              <div className="card">
                                  <div className="small-box bg-danger">
                                      <div className="inner">
                                          <p>ไม่มาปฏิบัตงาน</p>
                                          <h2>{work.leavePercent}%</h2>
                                          <p>(<NumberFormat displayType={'text'} thousandSeparator={true} value={work.leave} /> คน)</p>
                                      </div>
                                      <div className="icon">
                                          <i className="fas fa-level-down-alt"></i>
                                      </div>
                                  </div>

                                  <div className="card-footer p-0">
                                      <ul className="nav flex-column">
                                          <li className="nav-item">
                                              <a href="#" className="nav-link">
                                                  ลาป่วย <span className="float-right badge bg-primary"><NumberFormat displayType={'text'} thousandSeparator={true} value={work.sick} /> คน</span>
                                              </a>
                                          </li>
                                          <li className="nav-item">
                                              <a href="#" className="nav-link">
                                                  ลากิจ <span className="float-right badge bg-info"><NumberFormat displayType={'text'} thousandSeparator={true} value={work.personal} /> คน</span>
                                              </a>
                                          </li>
                                          <li className="nav-item">
                                              <a href="#" className="nav-link">
                                                  ลาพักผ่อน <span className="float-right badge bg-info"><NumberFormat displayType={'text'} thousandSeparator={true} value={work.vacation} /> คน</span>
                                              </a>
                                          </li>
                                          <li className="nav-item">
                                              <a href="#" className="nav-link">
                                                  อื่นๆ (ยังไม่ทราบสาเหตุ,วันหยุด) <span className="float-right badge bg-info"><NumberFormat displayType={'text'} thousandSeparator={true} value={work.other} /> คน</span>
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                              </div>

                          </div>


                      </div>

                      <div className="card card-primary">
                          <div className="card-header">
                              <h3 className="card-title">สถิติย้อนหลัง จำนวนบคุคลากรที่ไม่มาปฏิบัตงาน</h3>
                          </div>
                          <div className="card-body">
                              <div className="chart">
                                  <HighchartsReact
                                      highcharts={Highcharts}
                                      options={options}
                                  />
                              </div>
                          </div>
                      </div>

                  </Container>
              </section>
          </div>
      </div>
  );
};

export default AdminDashboard;
