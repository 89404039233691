import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import config from "react-global-configuration";
// import axios from "axios";
import Keycloak from "keycloak-js";
import { KeycloakProvider } from "@react-keycloak/web";
import Navigation from "./components/Navigation";
import Topnav from "./components/Topnav";
import { IndexRoute, PrivateRoute } from "./PrivateRoute";


//import Login from "./components/Login";
import Dashboard from "./components/Dashboard";

/* ระบบบันทึกเวลา */
import RecoredOuting from "./components/Recored/Outing";
import RecoredList from "./components/Recored/List";
import Checkin from "./components/Recored/Checkin";
import Checkout from "./components/Recored/Checkout";
import RecoredSummary from "./components/Recored/Summary";

/* ระบบลา */
import LeaveRight from "./components/Leave/Right";
import LeaveForm from "./components/Leave/Form";
import LeaveStatus from "./components/Leave/Status";
import LeaveCancel from "./components/Leave/Cancel";

/* ผู้บังคับบัญชา */
import SupervisorList from "./components/Supervisor/List";
import SupervisorRight from "./components/Supervisor/Right";
import SupervisorApprove from "./components/Supervisor/Approve";
import SupervisorApproveLeave from "./components/Supervisor/ApproveLeave";

/* รายงาน */
import ReportTimeAttendance from "./components/Report/TimeAttendance";
import AdminDashboard from "./components/Report/AdminDashboard";
import ReportSummaryTimeAttendance from "./components/Report/SummaryTimeAttendance";
import ReportLeave from "./components/Report/Leave";
import ReportLeaveDetail from "./components/Report/LeaveDetail";
import ReportWorkingInOut from "./components/Report/WorkingInOut";

/* ผู้ดูแลระบบ */
import AdminMain from "./components/Admin/Admin";
import AdminPersonnelInfoDataTable from "./components/Admin/PersonnelInfoDataTable";
import AdminPersonnelInfo from "./components/Admin/PersonnelInfo";
import AdminPersonnelType from "./components/Admin/PersonnelType";
import AdminWorkSchedule from "./components/Admin/WorkSchedule";
import AdminLeaveType from "./components/Admin/LeaveType";
import AdminAnnualHoliday from "./components/Admin/AnnualHoliday";
import AdminCreateUser from "./components/Admin/CreateUser";
import AdminChangePassword from "./components/Admin/ChangePassword";
import AdminUserManagement from "./components/Admin/UserManagement";
import AdminManagement from "./components/Admin/Management";
import AdminCardProcess from "./components/Admin/CardProcess";
import MainMasOrganization from "./components/Admin/MainMasOrganization";

/* แผนผังองค์กร */
import OrgDataTable from "./components/Develop/OrgDataTable";

config.set({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PATH,
  apiUrl: process.env.REACT_APP_API_URL,
  apiFaceReg: process.env.REACT_APP_API_FACE_REG,
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      information: {},
    };
  }

  render() {

    const keycloak = new Keycloak({
      url: `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/auth`,
      realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
      clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
    });
    const initConfig = { pkceMethod: "S256" };
    const handleOnEvent = async (event, error) => {
      if (event === "onReady" && !keycloak.authenticated) {
        if (!keycloak.authenticated) {
          keycloak.login();
        }
      } else if (event === "onAuthSuccess") {
        // let getInfo = await axios.get(
        //   config.get("apiUrl") + "/api/employee/" + keycloak.tokenParsed.preferred_username,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `bearer ${keycloak.token}`,
        //     },
        //   }
        // );

      }
    };


    // const checkAuthenticated = () => {
    //   if (!keycloak.authenticated) {
    //     keycloak.login();
    //   }
    // };


    const loadingComponent = (
      <div className="loading-container">
        <div className="loading-wrap"></div>
        <div className="branding">
          <img src="img/logo-small.png" alt="logo" />
          <div className="spinner"></div>
        </div>
      </div>
    )

    return (
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={initConfig}
        LoadingComponent={loadingComponent}
        onEvent={(event, error) => handleOnEvent(event, error)}
      >
        <BrowserRouter basename={process.env.REACT_APP_PATH}>
          <div className="wrapper">
            <Topnav />
            <Navigation />
            <Switch>
              {/*<Route  path="/" component={Login} />*/}

              {/* หน้าแรก */}
              <IndexRoute exact path="/"
                // roles={["admin-dep", "admin", "user"]}
                validcomponent={AdminPersonnelInfoDataTable}
                invalidcomponent={AdminPersonnelInfo}
              />

              {/* <Route exact path="/" component={AdminPersonnelInfo} /> */}

              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/dashboard"
                component={Dashboard}
              />
              {/* ระบบบันทึกเวลา */}
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/recored-outing"
                component={RecoredOuting}
              />

              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/recored-list"
                component={RecoredList}
              />
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/recored-checkin"
                component={Checkin}
              />
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/recored-checkout"
                component={Checkout}
              />
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/recored-summary"
                component={RecoredSummary}
              />

              {/* ระบบลา */}
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/leave-rights"
                component={LeaveRight}
              />
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/leave-form"
                component={LeaveForm}
              />
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/leave-status"
                component={LeaveStatus}
              />
              <PrivateRoute
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/leave-cancel"
                component={LeaveCancel}
              />

              {/* ผู้บังคับบัญชา */}
              <PrivateRoute
                roles={["boss", "supervisor"]}
                path="/supervisor-list"
                component={SupervisorList}
              />
              <PrivateRoute
                roles={["boss", "supervisor"]}
                path="/supervisor-right"
                component={SupervisorRight}
              />
              <PrivateRoute
                roles={["boss", "supervisor"]}
                path="/supervisor-approve"
                component={SupervisorApprove}
              />
              <PrivateRoute
                roles={["boss", "supervisor"]}
                path="/supervisor-approve-leave"
                component={SupervisorApproveLeave}
              />

              {/* รายงาน */}
              <PrivateRoute
                roles={["admin-dep", "admin", "boss", "supervisor"]}
                path="/report-time-attendance"
                component={ReportTimeAttendance}
              />
              <PrivateRoute
                roles={["admin"]}
                  path="/admin-dashboard"
                  component={AdminDashboard}
              />
              <PrivateRoute
                roles={["admin-dep", "admin", "boss", "supervisor"]}
                path="/report-time-attendance-summary"
                component={ReportSummaryTimeAttendance}
              />
              <PrivateRoute
                roles={["admin-dep", "admin", "boss", "supervisor"]}
                path="/report-leave-summary"
                component={ReportLeave}
              />
              <PrivateRoute
                roles={["admin-dep", "admin", "boss", "supervisor"]}
                path="/report-leave"
                component={ReportLeaveDetail}
              />
              <PrivateRoute
                roles={["admin-dep", "admin", "boss", "supervisor"]}
                path="/report-working-in-out"
                component={ReportWorkingInOut}
              />

              {/* ผู้ดูแลระบบ */}

              <PrivateRoute
                roles={["admin"]}
                path="/admin"
                component={AdminMain}
              />

              <PrivateRoute exact
                roles={["admin-dep", "admin"]}
                path="/admin-personnel-infomation-datatable"
                component={AdminPersonnelInfoDataTable}
              />

              <PrivateRoute exact
                roles={["user", "admin-dep", "admin", "boss", "center", "supervisor"]}
                path="/admin-personnel-infomation"
                component={AdminPersonnelInfo}
              />

              <PrivateRoute
                roles={["admin"]}
                path="/admin-personnel-type"
                component={AdminPersonnelType}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-work-schedule"
                component={AdminWorkSchedule}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-leave-type"
                component={AdminLeaveType}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-annual-holiday"
                component={AdminAnnualHoliday}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-create-user"
                component={AdminCreateUser}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-change-password"
                component={AdminChangePassword}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-user-management"
                component={AdminUserManagement}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-management"
                component={AdminManagement}
              />
              <PrivateRoute
                  roles={["admin"]}
                  path="/admin-main-mas-organization"
                  component={MainMasOrganization}
              />
              <PrivateRoute
                roles={["admin"]}
                path="/admin-card-process"
                component={AdminCardProcess}
              />
              <PrivateRoute
                  roles={["develop"]}
                  path="/develop-org-data-table"
                  component={OrgDataTable}
              />
            </Switch>
          </div>
        </BrowserRouter>
      </KeycloakProvider>
    );
  }
}
export default App;
