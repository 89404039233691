/* eslint-disable */
import React, { useState, useEffect } from "react";
import UploadService from "../../services/FileUploadService";
import { Container, Modal } from "react-bootstrap";
import { Button, TextField } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import config from "react-global-configuration";
import moment from "moment";
import { WatDatePicker } from 'thaidatepicker-react'
import th from "date-fns/locale/th";
registerLocale("th", th);


import "react-datepicker/dist/react-datepicker.css";

const RecoredOuting = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit } = useForm();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState([]);
  const [boss, setBoss] = useState([]);
  const [approve, setApprove] = useState([]);
  const [userFullname, setUserFullname] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const history = useHistory();

  const [textMsg, setTextMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);

  const fetchData = async () => {
    let rsUser = await axios.get(config.get("apiUrl") +"/api/employee/" +keycloak.tokenParsed.preferred_username,{headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});
    setUserFullname(rsUser.data.data.fname + " " + rsUser.data.data.lname);
    setUser(rsUser.data.data);

    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        like_employee_id: "",
        like_fname: "",
        like_lname: "",
      },
    };
    let rsBoss = await axios.get(config.get("apiUrl") + "/api/employee/"+keycloak.tokenParsed.preferred_username+"/boss/1",{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    let rsApprove = await axios.get(config.get("apiUrl") + "/api/employee/"+keycloak.tokenParsed.preferred_username+"/boss/2",{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    if(rsBoss.data.data !== null){setBoss(rsBoss.data.data.records);}
    if(rsBoss.data.data !== null){setApprove(rsApprove.data.data.records);}
  };

  const onSubmit = async (data) => {
    let params = {
      employee_id: user.employee_id,
      reference_doc_no: data.reference_doc_no,
      description: data.description,
      approval_1: data.approval_1,
      approval_2: "no",
      remark: data.remark,
      from_date: data.start_date===""?"":moment(data.start_date,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      to_date: data.end_date===""?"":moment(data.end_date,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      attachment:fileUpload,
    };
    
    let rsSave = await axios.post(config.get("apiUrl") + "/api/check-time/create",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    if (rsSave.data.status === "success") {
      handleShow();
      setTextMsg("บันทึกข้อมูลเรียบร้อย");
      history.push("/recored-list");
    } else {
      handleShow();
      setTextMsg("บันทึกไม่สำเร็จ");
    }
  };

  const hiddenImgInput = React.useRef(null);
  const handleUploadImg = (pic, event) => {
    if(pic==="pic1"){hiddenImgInput.current.click();}
  };
  const handleUploadProcess = (pic, event) => {
    const fileUploaded = event.target.files[0];
    uploadImgTemp(pic, fileUploaded);
  };
  const [fileUpload, setFileUpload] = useState("");
  const uploadImgTemp = async (pic, data) => {
    let formData = new FormData();
        formData.append("file", data);
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {headers: {"Content-Type": "multipart/form-data",Authorization: `bearer ${keycloak.token}`,},});
    if(pic==="pic1"){setFileUpload(rsImg.data.data.file_id);}
  };

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
    $('.datepicker').datepicker({
      format: 'dd/mm/yyyy',
      todayBtn: false,
      language: 'th',
      thaiyear: true 
    }).datepicker("setDate", "0").bind('keydown', function(e) {
      e.preventDefault(); 
    });
    dateChange();   
    fetchData();
  }, [keycloak.token]);
  const dateChange = async () => {
    $(".start_date").datepicker({
        todayBtn:  false,
        autoclose: true,
        format: 'dd/mm/yyyy',
        language: 'th',
        thaiyear: true 
    }).datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(), 
          minDate.getDate()
        );
        var eDate = new Date($('.end_date').datepicker('getDate'));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(), 
          eDate.getDate()
        );
        $('.end_date').datepicker('setStartDate', minDate);
        $('.end_date').datepicker('setDate', eDate<minDate?minDate:eDate);
    });
  
    $(".end_date").datepicker({
        todayBtn:  false,
        autoclose: true,
        format: 'dd/mm/yyyy',
        language: 'th',
        thaiyear: true ,
    }).datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).on('changeDate', function (selected) {


    });
  }
  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb"><i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" /><Link to="./" className="text-breadcrumb">หน้าหลัก</Link></li>
                  <li className="breadcrumb-item text-breadcrumb">บันทึกเวลาทำงาน</li>
                  <li className="breadcrumb-item text-breadcrumb-active">ขออนุมัติทำงานนอกสถานที่</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius elevation-3">
                  <div className="card-body">
                      <span className="text-header-x2 ddc-color">ขออนุมัติทำงานนอกสถานที่</span>
                      <br /> <br />
                      <span className="text-color-caption">รายละเอียดผู้ขออนุญาต</span>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle">รหัสพนักงาน</span></div>
                        <div className="col-12 col-md-2 pt-1"><input className="form-control shadow-none" type="text" defaultValue={user.employee_id}/></div>
                        <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">ชื่อ - สกุล</span></div>
                        <div className="col-12 col-md-7 pt-1"><input className="form-control shadow-none" type="text" defaultValue={userFullname}/></div>
                      </div>
                      <br /><br /><hr style={{ margin: "6px" }} /><br />
                      <span className="text-color-caption">รายละเอียดการทำงานนอกสถานที่</span>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle">เลขที่หนังสือ</span></div>
                        <div className="col-12 col-md-2 pt-1"><input className="form-control shadow-none" type="text" name="reference_doc_no" ref={register} /></div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle">วัตถุประสงค์</span></div>
                        <div className="col-12 col-md-10 pt-1"><textarea className="form-control shadow-none" rows="8" name="description" ref={register}></textarea></div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle">วันที่ทำงานนอกสถานที่</span></div>
                        <div className="col-12 col-md-4 pt-1">
                        <div className="row">
                            <div className="col-5">
                              <div className="form-control-wrapper form-control-icon-right">
                                <input type="text" defaultValue={moment(startDate).format("DD/MM/YYYY")} name="start_date" ref={register} data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none" />
                                <i className="far fa-calendar-alt"></i>
                              </div>
                            </div>
                            <div className="col-2 text-center mt-2">ถึง</div>
                              <div className="col-5">
                                <div className="form-control-wrapper form-control-icon-right">
                                  <input type="text" defaultValue={moment(endDate).add(543,'year').format("DD/MM/YYYY")} name="end_date" ref={register} data-date-format="dd/MM/yyyy" className="datepicker end_date form-control shadow-none" />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle">ผู้บังคับบัญชาชั้นต้น</span></div>
                        <div className="col-12 col-md-2 pt-1">
                          <select className="form-control shadow-none" name="approval_1" ref={register} >
                            <option value="">กรุณาเลือก</option>
                            {boss.map((item) => (
                              <option key={item.employee_id} value={item.employee_id}>
                                {item.fname+ ' '+item.lname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3" style={{display:'none'}}>
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle">ผู้มีอำนาจอนุมัติ</span></div>
                        <div className="col-12 col-md-2 pt-1">
                        <select className="form-control shadow-none" name="approval_2" ref={register}>
                            <option value="">กรุณาเลือก</option>
                            {approve.map((item) => (
                              <option key={item.employee_id} value={item.employee_id}>
                                {item.fname+ ' '+item.lname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle">หมายเหตุ</span></div>
                        <div className="col-12 col-md-10 pt-1"><textarea className="form-control shadow-none" rows="4" name="remark" ref={register}></textarea></div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle"></span></div>
                        <div className="col-12 col-sm-1 col-md-2 pt-3 mt-1">
                          <label>
                            <input type="file" ref={hiddenImgInput} onChange={(e) =>handleUploadProcess('pic1', e)} style={{ display: "none" }} />
                            <Button variant="contained" onClick={(e) =>handleUploadImg('pic1',e)} className="btn-no-border btn-color-search"><i className="fas fa-paperclip pr-2"></i> แนบไฟล์</Button>
                          </label>
                        </div>
                        <div className="col-12 col-sm-1 col-md-2 pt-3 mt-3">{fileUpload !== "" && (<a href={config.get("apiUrl") + "/api/file/" + fileUpload} target="_blank">ดูไฟล์แนบ</a>)}</div>
                        <div className="col-12 col-sm-2 pt-3 mt-2 text-center"><Button type="button" onClick={handleSubmit(onSubmit)} variant="contained" className="btn-no-border btn-color-submit"><i className="far fa-save pr-2"></i>บันทึก</Button></div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>
    </div>
  );
};

export default RecoredOuting;
