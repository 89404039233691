/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import DataTable from "react-data-table-component";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; 
import moment from "moment";

const LeaveForm = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit, reset } = useForm();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortCol, setSortCol] = useState("fname");
  const [sortDir, setSortDir] = useState("asc");
  const [showMsg, setShowMsg] = useState(false);
  const [showLeaveMsg, setShowLeaveMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");
  const handleLeaveClose = () => setShowLeaveMsg(false);
  const handleLeaveShow = () => setShowLeaveMsg(true);
  const [dtLeave, setDtLeave] = useState([]);
  var nextThreeMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 3, 
    new Date().getDate()
  );
  var nextThreeMonthYear = new Date(
    new Date().getFullYear()+543,
    new Date().getMonth() + 3, 
    new Date().getDate()
  );
  var currnentDate = moment(new Date()).format("MM")==="10" || moment(new Date()).format("MM")==="11" || moment(new Date()).format("MM")==="12" ? nextThreeMonth : new Date();
  const [yearDate, setYearDate] = useState(moment(currnentDate).format("YYYY"));
  const [dataCurr, setDataCurr] = useState([]);
  const [dataR1, setDataR1] = useState('');
  const [dataR2, setDataR2] = useState('');
  const [dataR3, setDataR3] = useState('');
  const [dataC1, setDataC1] = useState('');
  const [dataC2, setDataC2] = useState('');
  const [dataC3, setDataC3] = useState('');
  const [dataL1, setDataL1] = useState('');
  const [dataL2, setDataL2] = useState('');
  const [dataL3, setDataL3] = useState('');
  const [dataRe1, setDataRe1] = useState('');
  const [dataRe2, setDataRe2] = useState('');
  const [dataRe3, setDataRe3] = useState('');
  const [empName, setEmpName] = useState('');
  
  const onSubmit = async (data) => {
    setLoading(true);
    setEmpName('');

    let params = {page_size: perPage,current_page: 1,criteria: { like_employee_id:data.employee_id,
      like_fname:data.employee_name,
      like_lname:""},order_by:[{column_name:sortCol,direction:sortDir}]};
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setDtLeave(rsDT.data.data.records);
    setTotalRows(rsDT.data.data.total_records);
    setLoading(false);
    //reset({});
    /*setEmpName('');
    setDataCurr([]);
    if(empSelect[0])
    {
      let params = {
            criteria : {
              year: yearDate,
              employee_id: [empSelect[0].employee_id]
            }
          };
      try {
        let rsData = await axios.post(config.get("apiUrl") + "/api/leave/boss/get-info",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
        if (rsData.data.status === "success") {
          setEmpName(rsData.data.data.records[0].fname+' '+rsData.data.data.records[0].lname);
          let result = rsData.data.data.records.reduce(function (r, a) {
              let key = a.year || 'year';
              r[key] = r[key] || [];
              r[key].push(a);
              return r;
          }, Object.create(null));
          let yNow=yearDate;
          setDataCurr(result[yNow]);
          setDataC1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].collect);
          setDataC2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].collect);
          setDataC3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].collect);
          setDataR1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].right);
          setDataR2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].right);
          setDataR3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].right);
          setDataL1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].leave);
          setDataL2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].leave);
          setDataL3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].leave);
          setDataRe1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].remain);
          setDataRe2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].remain);
          setDataRe3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].remain);
        } else {
          handleShow();
          setTextMsg(rsData.data.message_th);
        }
      }
      catch {
        handleShow();
        setTextMsg("ไม่พบข้อมูล");
      }
    } */ 
  };

  useEffect(() => {
    $('.datepicker').datepicker({
      format: "yyyy",
      orientation: "bottom",
      todayBtn: false,
      language: 'th',
      thaiyear: true,
      keyboardNavigation: false,
      viewMode: "years",
      minViewMode: "years"
    }).datepicker("setDate", yearDate);
    $( ".start_date" ).change(function() {
      setYearDate(moment($('.start_date').val(),'YYYY').add(-543, 'year').format("YYYY"));
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const filterBy = () => true;
  const [empList, setEmpList] = useState([]);
  const [empSelect, setEmpSelect] = useState('');
  const [empTemp, setEmpTemp] = useState('');
  const handleSearch = async (query) => {
    setLoading(true);
    let params = {
      page_size: 10,
      current_page: 1,
      criteria: {
        like_employee_id: "",
        like_fname:query,
        like_lname:"",
        self_org:"1",
      },order_by:[{column_name:"fname",direction:"asc"}]
    };
    setEmpTemp(query);
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",JSON.stringify(params),{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setEmpList(rsDT.data.data.records);
    setLoading(false);
  }

  const columns = [
    {
      name: "รหัสพนักงาน",
      selector: "employee_id",
      sortable: true,
     
    },
    {
      name: "ชื่อ - นามสกุล",
      selector: "fname",
      sortable: true,
      cell:(row) => <Link onClick={(e) =>getLeaveInfo(row.employee_id)} style={{textDecoration:'none',color:"#000000"}}>{row.fname} {row.lname}</Link>,
    },
    {
      name: "ตำแหน่ง",
      selector: "emp_position",
      sortable: true,
    }
  ];
  const getLeaveInfo = async (empolyee_id) => {
    let params = {
      criteria : {
        year: yearDate,
        employee_id: [empolyee_id]
      }
    };
    try {
      let rsData = await axios.post(config.get("apiUrl") + "/api/leave/boss/get-info",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
      if (rsData.data.status === "success") {
        let result = rsData.data.data.records.reduce(function (r, a) {
            let key = a.year || 'year';
            r[key] = r[key] || [];
            r[key].push(a);
            return r;
        }, Object.create(null));
        let yNow=yearDate;
        setDataCurr(result[yNow]);
        setDataC1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].collect);
        setDataC2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].collect);
        setDataC3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].collect);
        setDataR1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].right);
        setDataR2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].right);
        setDataR3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].right);
        setDataL1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].leave);
        setDataL2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].leave);
        setDataL3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].leave);
        setDataRe1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].remain);
        setDataRe2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].remain);
        setDataRe3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].remain);
        console.log(rsData.data.data.records.length);
        if(rsData.data.data.records.length>0)
        {
          handleLeaveShow();
        }
        else
        {
          handleShow();
          setTextMsg("ไม่พบข้อมูล เนื่องจากประเภทบุคคลากร ไม่ถูกต้อง");
        }
      }
      else
        {
          handleShow();
          setTextMsg("ไม่พบข้อมูล เนื่องจากประเภทบุคคลากร ไม่ถูกต้อง");
        } 
    }
    catch (error) {
      handleShow();
      setTextMsg("ไม่พบข้อมูล เนื่องจากประเภทบุคคลากร ไม่ถูกต้อง");
      //console.log(error.response);
    }
  };

  const fetchTable = async (page) => {
    setLoading(true);
    let params = {page_size: perPage,current_page: page,criteria: {like_employee_id: "",like_fname: "",like_lname: "",},order_by:[{column_name:sortCol,direction:sortDir}]};
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setDtLeave(rsDT.data.data.records);
    setTotalRows(rsDT.data.data.total_records);
    setLoading(false);
  };

  const handleSort = async (column, sortDirection) => {
    setLoading(true);
    let params = {page_size: perPage,current_page: 1,criteria: {like_employee_id: "",like_fname: "",like_lname: "",},order_by:[{column_name:column.selector,direction:sortDirection}]};
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setDtLeave(rsDT.data.data.records);
    setTotalRows(rsDT.data.data.total_records);
    setSortCol(column.selector);
    setSortDir(sortDirection);
    setLoading(false);
  };

  const handlePageChange = (page) => {fetchTable(page);};

  const handleFilter = async (filterLocal) => {
    setLoading(true);
    let params = {page_size: perPage,current_page: page,criteria: {like_employee_id: "",like_fname: "",like_lname: "",},order_by:[{column_name:sortCol,direction:sortDir}]};
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setDtLeave(rsDT.data.data.records);
    setTotalRows(rsDT.data.data.total_records);
    setFilter(filterLocal);
    setLoading(false);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    let params = {page_size: perPage,current_page: page,criteria: {like_employee_id: "",like_fname: "",like_lname: "",},order_by:[{column_name:sortCol,direction:sortDir}]};
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setDtLeave(rsDT.data.data.records);
    setTotalRows(rsDT.data.data.total_records);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const onClickSearch = async (e) => {
    setLoading(true);
    let params = {page_size: perPage,current_page: page,criteria: {like_employee_id: "",like_fname: "",like_lname: "",},order_by:[{column_name:sortCol,direction:sortDir}]};
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setDtLeave(rsDT.data.data.records);
    setTotalRows(rsDT.data.data.total_records);
    setLoading(false);
  };

  useEffect(() => {fetchTable(1);}, []);

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb"><i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" /><Link to="./" className="text-breadcrumb">หน้าหลัก</Link></li>
                  <li className="breadcrumb-item text-breadcrumb">ผู้บังคับบัญชา </li>
                  <li className="breadcrumb-item text-breadcrumb-active">สิทธิการลาผู้ใต้บังคับบัญชา</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">สิทธิการลาผู้ใต้บังคับบัญชา</span>
                        <br /> <br />                       
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">รหัสพนักงาน </span></div>
                          <div className="col-12 col-md-2 pt-1">   <input
                              className="form-control shadow-none"
                              type="text"
                              name="employee_id"
                              ref={register}
                            /></div>
                          <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">ชื่อ - สกุล </span></div>
                          <div className="col-12 col-md-2 pt-1"><input className="form-control shadow-none" type="text"  name="employee_name"
                              ref={register} defaultValue={empName} /></div>
                          <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">ปีงบประมาณ</span></div>
                          <div className="col-12 col-md-2 pt-1">
                            <div className="form-control-wrapper form-control-icon-right">
                                <input type="text" defaultValue={yearDate} name="start_date"  data-date-format="yyyy" className="datepicker start_date form-control shadow-none" />
                                <i className="far fa-calendar-alt"></i>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <Button
                            type="submit"
                            variant="contained"
                            className="btn-no-border btn-color-search"
                          >
                            <i className="fas fa-search pr-2"></i>
                            ค้นหา
                          </Button>
                        </div>
                      </div>
                    </div>
                    </form>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <div className="row">
                      <div className="col-12">
                        <DataTable
                          columns={columns}
                          data={dtLeave}
                          onSort={handleSort}
                          sortServer
                          defaultSortField="fname"
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          persistTableHead
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>
      <Modal show={showLeaveMsg} onHide={handleLeaveClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>สิทธิการลา</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form ">
          <div className="row">
            <div className="col-12">
              <table className="table table-head-fixed">
                <thead>
                  <tr className="table-ddc">
                    <th className="table-ddc text-center" rowSpan="2" style={{width:"10%"}}>ประเภทการลา</th>
                    <th className="d-none d-md-table-cell table-ddc text-center" colSpan="4" style={{width:"40%"}}>ปีก่อนหน้า</th>
                    <th className="table-ddc text-center" colSpan="4" style={{width:"50%"}}>{moment(nextThreeMonthYear).format("YYYY")===$('.start_date').val()?"ปัจจุบัน":"ปี "+ $('.start_date').val()}</th>
                  </tr>
                  <tr>
                    <th className="d-none d-md-table-cell table-ddc text-center">สะสมจากปีก่อน</th>
                    <th className="d-none d-md-table-cell table-ddc text-center">สิทธิที่ได้</th>
                    <th className="d-none d-md-table-cell table-ddc text-center">สิทธิที่ใช้ไป</th>
                    <th className="d-none d-md-table-cell table-ddc text-center">สิทธิคงเหลือ</th>
                    <th className="table-ddc text-center">สะสมจากปีก่อน</th>
                    <th className="table-ddc text-center">สิทธิที่ได้</th>
                    <th className="table-ddc text-center">สิทธิที่ใช้ไป</th>
                    <th className="table-ddc text-center">สิทธิคงเหลือ</th>
                  </tr>
                </thead>
                <tbody>
                  {dataCurr && (dataCurr.map((item) => (
                  <tr key={item.leave_id}>
                    <td>{item.leave_text}</td>
                    <td className="d-none d-md-table-cell">
                      {item.leave_text === 'ลาป่วย' ? dataC1 === 365 ? "มีสิทธิลา" : dataC1 === 0 ? "-" : dataC1 : ""}
                      {item.leave_text === 'ลากิจ' ? dataC2 === 365 ? "มีสิทธิลา" : dataC2 === 0 ? "-" : dataC2 : ""}
                      {item.leave_text === 'ลาพักผ่อน' ? dataC3 === 365 ? "มีสิทธิลา" : dataC3 === null ? "-" : dataC3 : ""}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {item.leave_text === 'ลาป่วย' ? dataR1 === 365 ? "มีสิทธิลา":dataR1:""}
                      {item.leave_text === 'ลากิจ' ? dataR2 === 365 ? "มีสิทธิลา":dataR2:""}
                      {item.leave_text === 'ลาพักผ่อน' ? dataR3 === 365 ? "มีสิทธิลา" : dataR3 === null ? "-" : dataR3 : ""}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {item.leave_text === 'ลาป่วย' ? dataL1 === 365 ? "มีสิทธิลา":dataL1:""}
                      {item.leave_text === 'ลากิจ' ? dataL2 === 365 ? "มีสิทธิลา":dataL2:""}
                      {item.leave_text === 'ลาพักผ่อน' ? dataL3 === 365 ? "มีสิทธิลา" : dataL3 === null ? "-" : dataL3 : ""}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {item.leave_text === 'ลาป่วย' ? dataRe1 === 365 ? "มีสิทธิลา":dataRe1:""}
                      {item.leave_text === 'ลากิจ' ? dataRe2 === 365 ? "มีสิทธิลา":dataRe2:""}
                      {item.leave_text === 'ลาพักผ่อน' ? dataRe3 === 365 ? "มีสิทธิลา" : dataRe3 === null ? "-" : dataRe3 : ""}
                    </td>
                    <td>{item.collect === 0 ? "-" : item.collect === null ? "-" : item.collect}</td>
                    <td>{item.right === 365 ? "มีสิทธิลา" : item.right === null ? "-" : item.right}</td>
                    <td>{item.leave === 365 ? "มีสิทธิลา" : item.leave === null ? "-" : item.leave}</td>
                    <td>{item.right === 365 ? "มีสิทธิลา" : item.remain === null ? "-" : item.remain}</td>
                  </tr>
                )))}
                <tr>
                  <td ></td>
                  <td className="d-none d-md-table-cell" colSpan="4"></td>
                  <td colSpan="4"><span className="text-danger"> หมายเหตุ : คุณมีสิทธิลาป่วยตามระเบียบของการพิจารณาเงินเดือน {" "} {dataCurr && (dataCurr.map((data) => (data.leave_text==="ลาป่วย"?data.right_salary_effect!==""?data.right_salary_effect:"-":"")))} {" "} วัน{" "}/{" "}ลากิจ {" "} {dataCurr && (dataCurr.map((data) => (data.leave_text==="ลากิจ"?data.right_salary_effect!==""?data.right_salary_effect:"-":"")))} {" "} วัน{" "}/{" "}ลาพักผ่อน {" "} {dataCurr && (dataCurr.map((data) => (data.leave_text==="ลาพักผ่อน"?data.right_salary_effect!==""?data.right_salary_effect:"-":"")))} {" "} วัน หากลาเกินกำหนด จะไม่ได้รับการพิจารณาปรับเงินเดือน</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeaveForm;
