/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import { WatDatePicker } from 'thaidatepicker-react'
import moment from "moment";
import th from "date-fns/locale/th";
registerLocale("th", th);

const RecordSummary = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit } = useForm();
  const [useBrake, setUseBrake] = useState(0);

  const [summary, setSummary] = useState([]);
  const [summaryTable, setSummaryTable] = useState([]);
  const [countLate, setCountLate] = useState(0);
  const [countWork, setCountWork] = useState('');
  const [employee, setEmployee] = useState({});

  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(), 
    new Date().getDate()
  );
  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const fetchData = async () => {
    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
    };
    let rsSummary = await axios.post(
      config.get("apiUrl") + "/api/emp-time/get-stats",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setSummaryTable(rsSummary.data.data.records);
    setSummary(rsSummary.data.data.summary);
  };

  const getInfo = async () => {
      let info = await axios.get(
        config.get("apiUrl") + "/api/employee/" + keycloak.tokenParsed.preferred_username,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (info.data.status === "success") {
        let employeeData = {
          ...info.data.data,
          fullName: info.data.data.fname + ' ' + info.data.data.lname
        }
        setEmployee(employeeData);
      }
  };

  useEffect(() => {
    dateChange();   
    fetchData();
    getInfo();
  }, [keycloak.token]);

  const dateChange = async () => {
    $(".start_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true })
      .datepicker('setDate', new Date()).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(), 
          minDate.getDate()
        );
        var eDate = new Date($('.end_date').datepicker('getDate'));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(), 
          eDate.getDate()
        );
        $('.end_date').datepicker('setStartDate', minDate);
        $('.end_date').datepicker('setDate', eDate<minDate?minDate:eDate);
        setStartDate($('.start_date').val());
      }
    );
    $(".end_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true ,}).datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).on('changeDate', function (selected) {setEndDate($('.end_date').val());});
  }

  const onClickSearch = async (data) => {
    setSummary([]);
    setSummaryTable([]);
    setCountLate(0);
    setCountWork('');
    let params = {
      page_size: 100,
      current_page: 1,
      criteria: {
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
      order_by: [
        {
            "column_name": "work_date",
            "direction": "asc"
        }
      ]
    };
    let rsSummary = await axios.post(
      config.get("apiUrl") + "/api/emp-time/get-stats",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    
    var lst=rsSummary.data.data.records;
    let count = Object.keys(lst).reduce(function (r, k) {
      //console.log(lst[k].late_minute>0?lst[k].late_minute:"");
      return r + !!(lst[k].late_minute>0);
    }, 0);
    const convertHHMM = (n) => `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2)
    var dttime = [];
    for(var i=0;i<rsSummary.data.data.records.length;i++)
    {
      if(rsSummary.data.data.records[i].work_hours)
      {
        var timeset=rsSummary.data.data.records[i].work_hours.split(":");
        dttime.push([timeset[0],timeset[1],timeset[2]]);

        rsSummary.data.data.records[i].late_minute=convertHHMM(rsSummary.data.data.records[i].late_minute);
      }
    }
    setCountLate(count);
    setCountWork(totalTimeAsString(dttime));
    setSummaryTable(rsSummary.data.data.records);
    setSummary(rsSummary.data.data.summary);
  };

  const totalTimeAsString = (timeArray) => {
    var result = ['00', '00', '00'];
  
    var reducer = function(acc, current) {
      return [
        Number(acc[0]) + Number(current[0]),
        Number(acc[1]) + Number(current[1]),
        Number(acc[2]) + Number(current[2])
      ];
    };
  
    var [hh, mm, ss] = timeArray.reduce(reducer, result);
  
    // seconds
    if (ss > 59) {
      mm += Math.floor(ss / 60);
    }
    ss = ss % 60;
    result[2] = ((ss < 10) ? '0' : '') + ss;
  
    // minutes
    if (mm > 59) {
      hh += Math.floor(mm / 60);
    }
    mm = mm % 60;
    result[1] = ((mm < 10) ? '0' : '') + mm;
  
    // hours
    result[0] = ((hh < 10) ? '0' : '') + hh;
  
    return result.join(':');
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    บันทึกเวลาทำงาน
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    สถิติ ขาด ลา มาสาย
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius elevation-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                          สถิติ ขาด ลา มาสาย
                        </span>
                        <br /> <br />
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            รหัสพนักงาน
                          </span>
                        </div>
                        <div className="col-12 col-md-2 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            defaultValue={employee.employee_id}
                            disabled
                          />
                        </div>
                        <div className="col-12 col-md-1 pt-2">
                          <span className="text-color-form align-middle">
                            ชื่อ - สกุล
                          </span>
                        </div>
                        <div className="col-12 col-md-7 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            defaultValue={employee.fullName}
                            disabled
                          />
                        </div>
                      </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              ช่วงวันที่
                            </span>
                          </div>
                          <div className="col-12 col-md-4 pt-1">
                            <div className="row">
                              <div className="col-5">
                                <div className="form-control-wrapper form-control-icon-right">
                                  <input type="text" defaultValue={startDate} name="start_date"  data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none" />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                              </div>
                              <div className="col-2 text-center mt-2">ถึง</div>
                              <div className="col-5">
                                <div className="form-control-wrapper form-control-icon-right">
                                  <input type="text" defaultValue={endDate} name="end_date" data-date-format="dd/MM/yyyy" className="datepicker end_date form-control shadow-none" />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <Button
                            type="button"
                            onClick={handleSubmit(onClickSearch)}
                            variant="contained"
                            className="btn-no-border btn-color-search"
                          >
                            <i className="fas fa-search pr-2"></i>
                            ค้นหา
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-head-fixed">
                          <thead>
                            <tr className="table-ddc">
                              <th className="table-ddc text-center" rowSpan="2">
                                วันที่
                              </th>
                              <th
                                className="d-none d-md-table-cell table-ddc text-center"
                                rowSpan="2"
                              >
                                ประเภทวัน
                              </th>
                              <th
                                className="d-none d-md-table-cell table-ddc text-center"
                                colSpan="2"
                              >
                                แผนการทำงาน
                              </th>
                              <th className="table-ddc text-center" colSpan="2">
                                บันทึกเวลา
                              </th>
                              <th className="table-ddc text-center" rowSpan="2">
                                เวลาสาย (ชั่วโมง:นาที)
                              </th>
                              <th
                                className="d-none d-md-table-cell table-ddc text-center"
                                rowSpan="2"
                              >
                                รวมชั่วโมงการทำงาน
                              </th>
                            </tr>
                            <tr>
                              <th className="d-none d-md-table-cell table-ddc text-center">
                                เข้า
                              </th>
                              <th className="d-none d-md-table-cell table-ddc text-center">
                                ออก
                              </th>
                              <th className="table-ddc text-center">เข้า</th>
                              <th className="table-ddc text-center">ออก</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryTable.map((item) => (
                             <tr key={item.daytype}>
                               <td className="text-center">{item.work_date}</td>
                               <td className="d-none d-md-table-cell text-center">{item.daytype_text}</td>
                               <td className="d-none d-md-table-cell text-center">{item.sh_time_in}</td>
                               <td className="d-none d-md-table-cell text-center">{item.sh_time_out}</td>
                               <td className="text-normal text-center">{(item.daytype_text==="ทำงาน"?item.emp_time_in:"")}</td>
                               <td className="text-normal text-center">{(item.daytype_text==="ทำงาน"?item.emp_time_out:"")}</td>
                               <td className="text-center">{(item.daytype_text==="ทำงาน"?item.late_minute:"")}</td>
                               <td className="d-none d-md-table-cell text-center">{(item.daytype_text==="ทำงาน"?item.work_hours:"")}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="info-box elevation-1">
                          <span className="elevation-2"></span>
                          <div className="info-box-content">
                            <div className="row">
                              <div className="col-6 text-left">
                                <span className="text-header-x2 ddc-color p-1">
                                  สรุปสถิติ
                                </span>
                              </div>
                              <div className="col-6 col-md-6 text-normal pl-1"></div>
                              <div className="col-12">
                                <hr style={{ margin: "6px -10px 10px" }} />
                              </div>
                              <div className="col-6 col-md-2 text-normal pl-4">
                                ขาดงาน
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                                {summary.total_absent_day}{" "}
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                                วัน
                              </div>
                              <div className="col-12 col-md-8 text-normal pt-1"></div>
                              <div className="col-6 col-md-2 text-normal pl-4">
                                ลา
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                                {summary.total_leave_day}{" "}
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                                วัน
                              </div>
                              <div className="col-12 col-md-8 text-normal pt-1"></div>
                              <div className="col-6 col-md-2 text-normal pl-4">
                                มาสาย
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                                {countLate}
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                                ครั้ง
                              </div>
                              <div className="col-12 col-md-8 text-normal pt-1"></div>
                              <div className="col-6 col-md-2 text-normal pl-4">
                                รวมชั่วโมงการทำงาน
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                                {countWork}
                              </div>
                              <div className="col-3 col-md-1 text-normal pt-1">
                              
                              </div>
                              <div className="col-12 col-md-8 text-normal pt-1"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default RecordSummary;
