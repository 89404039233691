/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import { WatDatePicker } from 'thaidatepicker-react'
import moment from "moment";
import th from "date-fns/locale/th";
registerLocale("th", th);

const LeaveCancel = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit, reset } = useForm();
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");
  const [useBrake, setUseBrake] = useState(0);
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(), 
    new Date().getDate()
  );
  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);
  const [empType, setEmpType] = useState([]);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [dt, setDt] = useState([]);

  const fetchData = async () => {
    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        like_document_id: "",
        eq_leave_id: 1,
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
      order_by: [
        {
          "column_name": "from_date",
          "direction": "desc"
        }
      ],
    };
    let rsData = await axios.post(
      config.get("apiUrl") + "/api/leave-form/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setDt(rsData.data.data.records);

    let rsEmpType = await axios.get(
      config.get("apiUrl") + "/api/master/leave/user",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setEmpType(rsEmpType.data.data.records);
  };

  useEffect(() => {
    dateChange();
    fetchData();
  }, [keycloak.token]);

  const dateChange = async () => {
    $(".start_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true })
      .datepicker('setDate', new Date()).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(), 
          minDate.getDate()
        );
        var eDate = new Date($('.end_date').datepicker('getDate'));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(), 
          eDate.getDate()
        );
        $('.end_date').datepicker('setStartDate', minDate);
        $('.end_date').datepicker('setDate', eDate<minDate?minDate:eDate);
        setStartDate($('.start_date').val());
      }
    );
    $(".end_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true ,}).datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).on('changeDate', function (selected) {setEndDate($('.end_date').val());});
  }

  const onSubmit = async (data) => {
    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        like_document_id: data.like_document_id,
        eq_leave_id: data.leave_id,
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
      order_by: [
        {
          "column_name": "from_date",
          "direction": "desc"
        }
      ],
    };
    let rsData = await axios.post(
      config.get("apiUrl") + "/api/leave-form/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setDt(rsData.data.data.records);

  };

  const process = async (doc) => {
    let params = {
      
    };
    try {await axios.post(config.get("apiUrl") + "/api/leave-form/cancel/"+doc,params, { headers: { "Content-Type": "application/json", Authorization: `bearer ${keycloak.token}`,},})} catch (error) {}
    handleShow();
    setDt([]);
    setTextMsg("บันทึกสำเร็จ");
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">ระบบลา</li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                  ขอยกเลิกการลา
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">ขอยกเลิกการลา</span>
                        <br /> <br />
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                เลขที่การลา
                              </span>
                            </div>
                            <div className="col-12 col-md-2 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="like_document_id"
                                ref={register}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ประเภทการลา
                              </span>
                            </div>
                            <div className="col-12 col-md-2 pt-1">
                              <select
                                className="form-control shadow-none"
                                name="leave_id"
                                ref={register}
                              >
                                <option value="">กรุณาเลือก</option>
                                {empType.map((item) => (
                                  <option
                                    key={item.leave_id}
                                    value={item.leave_id}
                                  >
                                    {item.leave_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                วันที่ลา
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                                <div className="row">
                                <div className="col-4">
                                  <div className="form-control-wrapper form-control-icon-right">
                                    <input type="text" defaultValue={startDate} name="start_date"  data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none" />
                                    <i className="far fa-calendar-alt"></i>
                                  </div>
                                  </div>
                                  <div className="col-1 pt-2">ถึง</div>
                                  <div className="col-4">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <input type="text" defaultValue={endDate} name="end_date" data-date-format="dd/MM/yyyy" className="datepicker end_date form-control shadow-none" />
                                      <i className="far fa-calendar-alt"></i>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-3 ml-3">
                              <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                className="btn-no-border btn-color-search"
                              >
                                <i className="fas fa-search pr-2"></i>
                                ค้นหา
                              </Button>
                            </div>
                          </div>
                      </div>
                    </div>                
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-head-fixed">
                          <thead>
                            <tr className="table-ddc">
                              <th className="table-ddc text-center">
                                เลขที่การลา
                              </th>
                              <th className="table-ddc text-center">
                                ประเภทการลา
                              </th>
                              <th className="table-ddc text-center">
                                วันที่ลา
                              </th>
                              <th className="table-ddc text-center">สถานะ</th>
                              <th className="table-ddc text-center">ยกเลิก</th>
                            </tr>
                          </thead>
                          <tbody>
                          {dt.map((item) => (
                              <tr key={item.document_id}>
                                <td className="text-center">{item.document_id}</td>
                                <td className="text-center">{item.leave_text}</td>
                                <td className="text-center">{item.from_date+ ' - '+item.to_date}</td>
                                <td className="text-center">{item.status_text}</td>
                                <td className="text-center"><Button
          type="button"
          variant="contained"
          className="btn-no-border btn-color-delete-small"
          onClick={(e)=>process(item.document_id)}
        >
          <i className="fas fa-trash-alt pr-2"></i>
          ยกเลิก
        </Button></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>
    </div>
  );
};

export default LeaveCancel;
