/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@material-ui/core";

const ChangePassword = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">ผู้ดูแลระบบ</li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    การจัดการผู้ใช้งาน
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius elevation-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                          เปลี่ยนรหัสผ่าน
                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              Username
                            </span>
                          </div>
                          <div className="col-8 col-md-2 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                            />
                          </div>
                          <div className="col-4 col-md-">
                            <Button
                              type="submit"
                              variant="contained"
                              size="large"
                              className="btn-no-border btn-color-search"
                            >
                              <i className="fas fa-search pr-2"></i>
                              ค้นหา
                            </Button>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              รหัสผ่านเดิม
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              รหัสผ่านใหม่
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              ยืนยันรหัสผ่านใหม่
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default ChangePassword;
