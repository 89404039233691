import React, { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Container, Image } from "react-bootstrap";
import config from "react-global-configuration";
import moment from "moment";
import axios from "axios";


const Dashboard = () => {
    const [keycloak] = useKeycloak();
    // const [useBrake, setUseBrake] = useState(0);
    const [summaryTable, setSummaryTable] = useState([]);
    // const [dt, setDt] = useState([]);
    const [dataCurr, setDataCurr] = useState([]);
    // const [dataR1, setDataR1] = useState('');
    // const [dataR2, setDataR2] = useState('');
    // const [dataR3, setDataR3] = useState('');
    // const [dataC1, setDataC1] = useState('');
    // const [dataC2, setDataC2] = useState('');
    // const [dataC3, setDataC3] = useState('');
    // const [dataL1, setDataL1] = useState('');
    // const [dataL2, setDataL2] = useState('');
    // const [dataL3, setDataL3] = useState('');
    // const [dataRe1, setDataRe1] = useState('');
    // const [dataRe2, setDataRe2] = useState('');
    // const [dataRe3, setDataRe3] = useState('');

    const fetchData = useCallback(async () => {
        let oneMonthAgo = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 15
        );
        let params = {
            page_size: 20,
            current_page: 1,
            criteria: {
                from_date: moment(oneMonthAgo).format("YYYY-MM-DD"),
                to_date: moment(new Date()).format("YYYY-MM-DD"),
            },
        };
        let rsSummary = await axios.post(config.get("apiUrl") + "/api/emp-time/get-stats", params, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        if (rsSummary.data.data.records) {
            rsSummary.data.data.records.sort((a, b) => (a.work_date > b.work_date ? -1 : 1));
        }
        setSummaryTable(rsSummary.data.data.records);
    }, [keycloak.token]);

    const fetchLeave = useCallback(async () => {
        let yearPeriod = moment(new Date()).format("MM") === "10" || moment(new Date()).format("MM") === "11" || moment(new Date()).format("MM") === "12" ? moment().add(1, 'years').format("YYYY") : moment().format("YYYY");
        let params = {criteria: {year: yearPeriod,},};
        let rsDT = await axios.post(config.get("apiUrl") + "/api/leave/user/get-info", params, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        // setDt(rsDT.data.data.records);
        let result = rsDT.data.data.records.reduce(function (r, a) {
            let key = a.year || 'year';
            r[key] = r[key] || [];
            r[key].push(a);
            return r;
        }, Object.create(null));
        let yNow = yearPeriod;
        setDataCurr(result[yNow]);
        if (result[yNow] && result[yNow].length) {
            // setDataC1(result[(yNow-1)].filter(f => f.leave_text ==='ลาป่วย')[0].collect);
            // setDataC2(result[(yNow-1)].filter(f => f.leave_text ==='ลากิจ')[0].collect);
            // setDataC3(result[(yNow-1)].filter(f => f.leave_text ==='ลาพักผ่อน')[0].collect);
            // setDataR1(result[(yNow-1)].filter(f => f.leave_text ==='ลาป่วย')[0].right);
            // setDataR2(result[(yNow-1)].filter(f => f.leave_text ==='ลากิจ')[0].right);
            // setDataR3(result[(yNow-1)].filter(f => f.leave_text ==='ลาพักผ่อน')[0].right);
            // setDataL1(result[(yNow-1)].filter(f => f.leave_text ==='ลาป่วย')[0].leave);
            // setDataL2(result[(yNow-1)].filter(f => f.leave_text ==='ลากิจ')[0].leave);
            // setDataL3(result[(yNow-1)].filter(f => f.leave_text ==='ลาพักผ่อน')[0].leave);
            // setDataRe1(result[(yNow-1)].filter(f => f.leave_text ==='ลาป่วย')[0].remain);
            // setDataRe2(result[(yNow-1)].filter(f => f.leave_text ==='ลากิจ')[0].remain);
            // setDataRe3(result[(yNow-1)].filter(f => f.leave_text ==='ลาพักผ่อน')[0].remain);
        }
    }, [keycloak.token]);

    useEffect(() => {
        const trees = window.$('[data-widget="treeview"]');
        trees.Treeview('init');
        fetchData();
        fetchLeave();
    }, [fetchData, fetchLeave]);
    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                                        <div className="info-box elevation-3">
                                            <span className="elevation-2"></span>
                                            <div className="info-box-content">
                                                <div className="row">
                                                    <div className="col-6 text-left"><span className="text-header-x2 ddc-color p-1">ลากิจ</span></div>
                                                    <div className="col-6 text-right"><Image src="img/decoration/lev1.png"/></div>
                                                    <div className="col-12">
                                                        <hr style={{margin: "6px -10px 10px",}}/>
                                                    </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่ได้</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.right === 365 ? "มีสิทธิลา" : data.right === null ? "-" : data.right : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.right === 365 ? "" : "วัน" : "")) : "วัน"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่มีผลเลื่อนเงินเดือน</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.right_salary_effect === 365 ? "มีสิทธิลา" : data.right_salary_effect === null ? "-" : data.right_salary_effect : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.right_salary_effect === 365 ? "" : "วัน" : "")) : "วัน"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่ใช้ไป</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.leave === 365 ? "มีสิทธิลา" : data.leave === null ? "-" : data.leave : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.leave === 365 ? "" : "วัน" : "")) : "วัน"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิคงเหลือ</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.right === 365 ? "มีสิทธิลา" : data.remain === null ? "-" : data.remain : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลากิจ" ? data.right === 365 ? "" : "วัน" : "")) : "วัน"} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                                        <div className="info-box elevation-3">
                                            <span className="elevation-2"></span>
                                            <div className="info-box-content">
                                                <div className="row">
                                                    <div className="col-6 text-left"><span className="text-header-x2 ddc-color p-1">ลาป่วย</span></div>
                                                    <div className="col-6 text-right"><Image src="img/decoration/lev2.png"/></div>
                                                    <div className="col-12">
                                                        <hr style={{margin: "6px -10px 10px",}}/>
                                                    </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่ได้</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.right === 365 ? "มีสิทธิลา" : data.right === null ? "-" : data.right : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.right === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่มีผลเลื่อนเงินเดือน</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.right_salary_effect === 365 ? "มีสิทธิลา" : data.right_salary_effect === null ? "-" : data.right_salary_effect : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.right_salary_effect === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่ใช้ไป</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.leave === 365 ? "มีสิทธิลา" : data.leave === null ? "-" : data.leave : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.leave === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิคงเหลือ</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.right === 365 ? "มีสิทธิลา" : data.remain === null ? "-" : data.remain : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาป่วย" ? data.right === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                                        <div className="info-box elevation-3">
                                            <span className="elevation-2"></span>
                                            <div className="info-box-content">
                                                <div className="row">
                                                    <div className="col-6 text-left"><span className="text-header-x2 ddc-color p-1">ลาพักผ่อน</span></div>
                                                    <div className="col-6 text-right"><Image src="img/decoration/lev3.png"/></div>
                                                    <div className="col-12">
                                                        <hr style={{margin: "6px -10px 10px",}}/>
                                                    </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่ได้</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.right === 365 ? "มีสิทธิลา" : data.right === null ? "-" : data.right : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.right === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่มีผลเลื่อนเงินเดือน</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.right_salary_effect === 365 ? "มีสิทธิลา" : data.right_salary_effect === null ? "-" : data.right_salary_effect : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.right_salary_effect === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิที่ใช้ไป</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.leave === 365 ? "มีสิทธิลา" : data.leave === null ? "-" : data.leave : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.leave === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                    <div className="col-7 text-normal pl-4">สิทธิคงเหลือ</div>
                                                    <div
                                                        className="col-3 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.right === 365 ? "มีสิทธิลา" : data.remain === null ? "-" : data.remain : "")) : "-"} </div>
                                                    <div
                                                        className="col-2 text-normal pt-1"> {dataCurr && dataCurr.length > 0 ? dataCurr.map((data) => (data.leave_text === "ลาพักผ่อน" ? data.right === 365 ? "" : "วัน" : "")) : "-"} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-3">
                                        <table className="table table-head-fixed">
                                            <thead>
                                            <tr>
                                                <th className="table-ddc text-center" rowSpan="2">วันที่</th>
                                                <th className="table-ddc text-center" rowSpan="2">ประเภทวัน</th>
                                                <th className="table-ddc text-center" colSpan="2">แผนการทำงาน</th>
                                                <th className="table-ddc text-center" colSpan="2">บันทึกเวลา</th>
                                            </tr>
                                            <tr>
                                                <th className="th-ddc-next text-center">เข้า</th>
                                                <th className="th-ddc-next text-center">ออก</th>
                                                <th className="th-ddc-next text-center">เข้า</th>
                                                <th className="th-ddc-next text-center">ออก</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {summaryTable.map((item) => (
                                                <tr key={item.daytype}>
                                                    <td className="text-normal text-center">{item.work_date}</td>
                                                    <td className="text-normal text-center">{item.daytype_text}</td>
                                                    <td className="text-normal text-center">{item.sh_time_in}</td>
                                                    <td className="text-normal text-center">{item.sh_time_out}</td>
                                                    <td className="text-normal text-center">{(item.daytype_text === "ทำงาน" ? item.emp_time_in : "")}</td>
                                                    <td className="text-normal text-center">{(item.daytype_text === "ทำงาน" ? item.emp_time_out : "")}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default Dashboard;
