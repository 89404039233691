/* eslint-disable */
import React from "react";
import { Container } from "react-bootstrap";
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const ReportTimeAttendance = () => {
  const { register } = useForm();
  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    รายงาน
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                  รายการบันทึกเวลาทำงาน
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                        รายการบันทึกเวลาทำงาน
                        </span>
                        <br /> <br />
                        
                      </div>
                      
                    </div>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default ReportTimeAttendance;
