/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import config from "react-global-configuration";
import { WatDatePicker } from 'thaidatepicker-react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import moment from "moment";
import th from "date-fns/locale/th";
registerLocale("th", th);

const LeaveForm = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit } = useForm();
  const [user, setUser] = useState([]);
  const [userFullname, setUserFullname] = useState("");
  const [defaultAddress, setDefaultAddress] = useState("");
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(), 
    new Date().getDate()
  );
  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);
  const [boss, setBoss] = useState([]);
  const [approve, setApprove] = useState([]);
  const [empType, setEmpType] = useState([]);
  const [fromDateType, setFromDateType] = useState(1);
  const [toDateType, setToDateType] = useState(1);
  const history = useHistory();


  const [textMsg, setTextMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);

  const fetchData = async () => {
    let rsUser = await axios.get(config.get("apiUrl") +"/api/employee/" +keycloak.tokenParsed.preferred_username,{headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});
    
    let province="-";
    let district="-";
    let subdistrict="-";
    try{let reProvince = await axios.get(config.get("apiUrl") + "/api/master/province",{headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});province = rsUser.data.data.emp_address.province===""?"":reProvince.data.data.records.find(x => x.province_id === rsUser.data.data.emp_address.province).province_tname;}catch{}
    try{let rsDistrict = await axios.get(config.get("apiUrl") + "/api/master/district/" + rsUser.data.data.emp_address.province,{headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});district = rsUser.data.data.emp_address.district===""?"":rsDistrict.data.data.records.find(x => x.district_id === rsUser.data.data.emp_address.district).district_tname;}catch{}
    try{let rsSubdistrict = await axios.get(config.get("apiUrl") + "/api/master/sub-district/" + rsUser.data.data.emp_address.district,{headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});subdistrict = rsUser.data.data.emp_address.sub_district===""?"":rsSubdistrict.data.data.records.find(x => x.sub_district_id === rsUser.data.data.emp_address.sub_district).sub_district_tname;}catch{}
    let address_no = rsUser.data.data.emp_address.address_no===""?"-":rsUser.data.data.emp_address.address_no;
    let village = rsUser.data.data.emp_address.village===""?"-":rsUser.data.data.emp_address.village;
    let moo = rsUser.data.data.emp_address.moo===""?"-":rsUser.data.data.emp_address.moo;
    let road = rsUser.data.data.emp_address.road===""?"-":rsUser.data.data.emp_address.road;
    setDefaultAddress("เลขที่ "+address_no + " หมู่บ้าน " + village + " หมู่ " + moo + " ถนน " + road + " "+(province==="กรุงเทพมหานคร"?"แขวง ":"ตำบล ") + subdistrict + " "+(province==="กรุงเทพมหานคร"?"เขต ":"อำเภอ ") + district + " จังวัด" + province + " " + rsUser.data.data.emp_address.postcode + " (เบอร์ติดต่อ : " + (rsUser.data.data.mobile===""?"-":rsUser.data.data.mobile) + ")");
    setUserFullname(rsUser.data.data.fname + " " + rsUser.data.data.lname);
    setUser(rsUser.data.data);

    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        like_employee_id: "",
        like_fname: "",
        like_lname: "",
      },
    };
    let rsBoss = await axios.get(
      config.get("apiUrl") + "/api/employee/"+keycloak.tokenParsed.preferred_username+"/boss/1",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    let rsApprove = await axios.get(
      config.get("apiUrl") + "/api/employee/"+keycloak.tokenParsed.preferred_username+"/boss/2",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if(rsBoss.data.data !== null){setBoss(rsBoss.data.data.records);}
    if(rsBoss.data.data !== null){setApprove(rsApprove.data.data.records);}

    let rsEmpType = await axios.get(
      config.get("apiUrl") + "/api/master/leave/user",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setEmpType(rsEmpType.data.data.records);
  };

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
    dateChange();
    leaveSection();
    fetchData();

  }, [keycloak.token]);

  const dateChange = async () => {
    $(".start_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true })
      .datepicker('setDate', new Date()).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(), 
          minDate.getDate()
        );
        var eDate = new Date($('.end_date').datepicker('getDate'));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(), 
          eDate.getDate()
        );
        $('.end_date').datepicker('setStartDate', minDate);
        $('.end_date').datepicker('setDate', eDate<minDate?minDate:eDate);
        setStartDate($('.start_date').val());
        leaveSection();
      }
    );
    $(".end_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true ,}).datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).on('changeDate', function (selected) {setEndDate($('.end_date').val());leaveSection();});
  }

  const leaveSection = async () => {
    
    var sDate = new Date($('.start_date').datepicker('getDate'));
      sDate = new Date(
      sDate.getFullYear(),
      sDate.getMonth(), 
      sDate.getDate()
    );
    var eDate = new Date($('.end_date').datepicker('getDate'));
      eDate = new Date(
      eDate.getFullYear(),
      eDate.getMonth(), 
      eDate.getDate()
    );
    if(sDate.getTime()===eDate.getTime()){
      $('.to_date_type').prop( "disabled", true);
      $('.from_date_type option[value="2"]').attr("disabled", false);
      $('.to_date_type option[value="1"]').attr("disabled", false);   
      $('.to_date_type option[value="2"]').attr("disabled", false);   
      $('.to_date_type option[value="3"]').attr("disabled", false);   
      setFromDateType($(".from_date_type").val());
      setToDateType($(".from_date_type").val());
    }
    else{
      $('.to_date_type').prop( "disabled", false);
      if($(".from_date_type").val()==="2"){ setFromDateType(1);setToDateType(1);}
      if($(".from_date_type").val()==="3" && $(".to_date_type").val()==="3"){ setToDateType(1);}
      $('.to_date_type option[value="3"]').attr("disabled", true);  
      $('.from_date_type option[value="2"]').attr("disabled", true);
      
    }
    var sDate1 = new Date($('.start_date').datepicker('getDate'));
      sDate1 = new Date(
      sDate1.getFullYear()+543,
      sDate1.getMonth(), 
      sDate1.getDate()
    );
    var eDate1 = new Date($('.end_date').datepicker('getDate'));
      eDate1 = new Date(
      eDate1.getFullYear()+543,
      eDate1.getMonth(), 
      eDate1.getDate()
    );
    calAmountLeave2(moment(sDate1).format("DD/MM/YYYY"), moment(eDate1).format("DD/MM/YYYY"),$(".from_date_type").val(),$(".to_date_type").val());
  }

  const onSubmit = async (data) => {
    let params = {
      employee_id: user.employee_id,
      leave_id: data.leave_id,
      from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      contact_address: data.contact_address,
      contact_mobile: "",
      approval_1: data.approval_1,
      approval_2: "no",
      remark: data.remark,
      from_date_type: fromDateType=="1"?"1":"2",
      to_date_type: toDateType=="1"?"1":"2",
      attachment:fileUpload,
      act_for:singleSelections[0]?singleSelections[0].fname+" "+singleSelections[0].lname:"",
    };
    try {
      let rsSave = await axios.post(
        config.get("apiUrl") + "/api/leave-form/create",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsSave.data.status === "success") {
        handleShow();
        setTextMsg("บันทึกข้อมูลเรียบร้อย");
        history.push("/leave-status");
      } else {
        handleShow();
        setTextMsg("ไม่สามารถลาได้ เนื่องจากไม่มีสิทธิลา");
      }
    }
    catch{
      handleShow();
      setTextMsg("บันทึกไม่สำเร็จ");
    }
  };

  const hiddenImgInput = React.useRef(null);
  const handleUploadImg = (pic, event) => {
    if(pic==="pic1"){hiddenImgInput.current.click();}
  };
  const handleUploadProcess = (pic, event) => {
    const fileUploaded = event.target.files[0];
    uploadImgTemp(pic, fileUploaded);
  };
  const [fileUpload, setFileUpload] = useState("");
  const uploadImgTemp = async (pic, data) => {
    let formData = new FormData();
    formData.append("file", data);
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    if(pic==="pic1"){
      setFileUpload(rsImg.data.data.file_id);
    }

  };


  const [amountLeave, setAmountLeave] = useState("");
  const calAmountLeave = async (sdate, edate, dfrom, eend) => {

    let params = {
      employee_id: keycloak.tokenParsed.preferred_username,
      from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      from_date_type: dfrom=="1"?"1":"2",
      to_date_type: eend=="1"?"1":"2",
    };
    //console.log(params);
    try {
      let rs = await axios.post(config.get("apiUrl") + "/api/leave-form/cal-leave",params,{ headers: { "Content-Type": "application/json", Authorization: `bearer ${keycloak.token}`,},});
      setAmountLeave(rs.data.data.leaveAmount);
    } catch (error) {}
  };

  const calAmountLeave2 = async (sdate, edate, dfrom, eend) => {

    let params = {
      employee_id: keycloak.tokenParsed.preferred_username,
      from_date: moment(sdate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      to_date: moment(edate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      from_date_type: dfrom=="1"?"1":"2",
      to_date_type: eend=="1"?"1":"2",
    };
    try {
      let rs = await axios.post(config.get("apiUrl") + "/api/leave-form/cal-leave",params,{ headers: { "Content-Type": "application/json", Authorization: `bearer ${keycloak.token}`,},});
      //console.log(rs.data.data.leaveAmount);
      setAmountLeave(rs.data.data.leaveAmount);
    } catch (error) {}
  };

  const [userAutoList, setUserAutoList] = useState([]);
  const [singleSelections, setSingleSelections] = useState('');
  const [empSearch, setEmpSearch] = useState("");
  const filterBy = () => true;

  const handleSearch = async (query) => {
    let params = {
      page_size: 10,
      current_page: 1,
      criteria: {
        like_employee_id: query,
        like_fname:query,
        like_lname:query,
        self_org:1
      },
    };
    setEmpSearch(query);
    let rs = await axios.post(config.get("apiUrl") + "/api/employee/search",JSON.stringify(params),{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    if (rs.data.status === "success") {setUserAutoList(rs.data.data.records);}
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">ระบบลา </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    สร้างหนังสือลา
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                      <span className="text-header-x2 ddc-color">
                        สร้างหนังสือลา
                      </span>
                      <br /> <br />
                      <span className="text-color-caption">
                        รายละเอียดผู้ขออนุญาต
                      </span>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            รหัสพนักงาน
                          </span>
                        </div>
                        <div className="col-12 col-md-2 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            defaultValue={user.employee_id}
                          />
                        </div>
                        <div className="col-12 col-md-1 pt-2">
                          <span className="text-color-form align-middle">
                            ชื่อ - สกุล
                          </span>
                        </div>
                        <div className="col-12 col-md-7 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            defaultValue={userFullname}
                          />
                        </div>
                      </div>

                      <br />
                      <br />
                      <hr style={{ margin: "6px" }} />
                      <br />
                      <span className="text-color-caption">
                        รายละเอียดการลา
                      </span>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            ประเภทการลา
                          </span>
                        </div>
                        <div className="col-12 col-md-3 pt-1">
                          <select
                            className="form-control shadow-none"
                            name="leave_id"
                            ref={register}
                          >
                            <option value="">กรุณาเลือก</option>
                            {empType.map((item) => (
                              <option
                                key={item.leave_id}
                                value={item.leave_id}
                              >
                                {item.leave_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            ตั้งแต่วันที่
                          </span>
                        </div>
                        <div className="col-8 col-md-2 pt-1">
                          <div className="form-control-wrapper form-control-icon-right">
                            <input type="text" defaultValue={startDate} name="start_date" ref={register} data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none" />
                            <i className="far fa-calendar-alt"></i>
                          </div>
                        </div>
                        <div className="col-4 col-md-1 pt-1">
                          <select className="form-control shadow-none from_date_type" id="from_date_type" name="from_date_type" ref={register} value={fromDateType} onChange={(e) => {setFromDateType(e.target.value);leaveSection();calAmountLeave(startDate, endDate,e.target.value,toDateType);}} >
                            <option value="1">ทั้งวัน</option>
                            <option value="2">ลาครึ่งวันเช้า</option>
                            <option value="3">ลาครึ่งวันบ่าย</option>
                          </select>
                        </div>
                        <div className="col-12 col-md-1 pt-2">
                          <span className="text-color-form align-middle">
                            ถึงวันที่
                          </span>
                        </div>
                        <div className="col-8 col-md-2 pt-1">
                          <div className="form-control-wrapper form-control-icon-right">
                            <input type="text" defaultValue={endDate} name="end_date" id="end_date" ref={register} data-date-format="dd/MM/yyyy" className="datepicker end_date form-control shadow-none" />
                            <i className="far fa-calendar-alt"></i>
                          </div>
                        </div>
                        <div className="col-4 col-md-1 pt-1">
                          <select className="form-control shadow-none to_date_type" name="to_date_type" ref={register} value={toDateType} onChange={(e) => {setToDateType(e.target.value);leaveSection();calAmountLeave(startDate, endDate,fromDateType,e.target.value);}} >
                            <option value="1">ทั้งวัน</option>
                            <option value="2">ลาครึ่งวันเช้า</option>
                            <option value="3">ลาครึ่งวันบ่าย</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            ผู้บังคับบัญชาชั้นต้น
                          </span>
                        </div>
                        <div className="col-12 col-md-3 pt-1">
                        <select
                            className="form-control shadow-none"
                            name="approval_1"
                            ref={register}
                          >
                            <option value="">กรุณาเลือก</option>
                            {boss.map((item) => (
                              <option
                                key={item.employee_id}
                                value={item.employee_id}
                              >
                                {item.fname+ ' '+item.lname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3" style={{display:'none'}}>
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            ผู้บังคับบัญชาชั้นต้นเหนือขึ้นไป
                          </span>
                        </div>
                        <div className="col-12 col-md-3 pt-1">
                        <select
                            className="form-control shadow-none"
                            name="approval_2"
                            ref={register}
                          >
                            <option value="">กรุณาเลือก</option>
                            {approve.map((item) => (
                              <option
                                key={item.employee_id}
                                value={item.employee_id}
                              >
                                {item.fname+ ' '+item.lname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            จำนวนวันลา
                          </span>
                        </div>
                        <div className="col-12 col-md-2 pt-1">
                          <input className="form-control shadow-none" readOnly type="text" value={amountLeave}  />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            ที่อยู่ที่ติดต่อได้ระหว่างลา
                          </span>
                        </div>
                        <div className="col-12 col-md-10 pt-1">
                          <textarea
                            className="form-control shadow-none"
                            rows="8" name="contact_address"
                            ref={register}
                            defaultValue={defaultAddress}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            ผู้ทำงานแทน
                          </span>
                        </div>
                        <div className="col-12 col-md-10 pt-1">
                            <AsyncTypeahead
                              filterBy={filterBy}
                              id="basic-typeahead-single"
                              labelKey={option => `${option.fname} ${option.lname}`}
                              onChange={setSingleSelections}
                              options={userAutoList}
                              selected={singleSelections}
                              minLength={3}
                              onSearch={handleSearch}
                              renderMenuItemChildren={(option) => (
                                <div>
                                  {option.fname} {option.lname}
                                </div>
                              )}
                            />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            เหตุผลการลา
                          </span>
                        </div>
                        <div className="col-12 col-md-10 pt-1">
                          <textarea
                            className="form-control shadow-none"
                            rows="4"
                            name="remark"
                            ref={register}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-2 pt-2"><span className="text-color-form align-middle"></span></div>
                        <div className="col-12 col-sm-1 col-md-2 pt-3 mt-1">
                          <label>
                            <input type="file" ref={hiddenImgInput} onChange={(e) =>handleUploadProcess('pic1', e)} style={{ display: "none" }} />
                            <Button variant="contained" onClick={(e) =>handleUploadImg('pic1',e)} className="btn-no-border btn-color-search"><i className="fas fa-paperclip pr-2"></i> แนบไฟล์</Button>
                          </label>
                        </div>
                        <div className="col-12 col-sm-1 col-md-2 pt-3 mt-3">{fileUpload !== "" && (<a href={config.get("apiUrl") + "/api/file/" + fileUpload} target="_blank">ดูไฟล์แนบ</a>)}</div>
                        <div className="col-12 col-sm-2 pt-3">
                          <Button
                            type="button"
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            className="btn-no-border btn-color-submit"
                          >
                            <i className="far fa-save pr-2"></i>
                            บันทึก
                          </Button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>
    </div>
  );
};

export default LeaveForm;
