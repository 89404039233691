/* eslint-disable */
import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import th from "date-fns/locale/th";
import config from "react-global-configuration";
import { useKeycloak } from "@react-keycloak/web";
import $ from "jquery";
import axios from "axios";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "@material-ui/core";


const MainMasOrganization = () => {
    const [keycloak] = useKeycloak();
    const [dataTable, setDataTable] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showByType1, setShowByType1] = useState(false);
    const [showByType2, setShowByType2] = useState(false);
    const [orgDetail, setOrgDetail] = useState({});
    const {register, handleSubmit} = useForm();

    const [listWorBu, setListWorBu] = useState([]);
    const [workbu, setWorkbu] = useState([]);
    const [workbu2, setWorkbu2] = useState([]);
    const [workbu3, setWorkbu3] = useState([]);
    const [workbu4, setWorkbu4] = useState([]);
    const [workbu5, setWorkbu5] = useState([]);
    const [workbu6, setWorkbu6] = useState([]);
    const [empWorkBu1, setEmpWorkBu1] = useState("");
    const [empWorkBu2, setEmpWorkBu2] = useState("");
    const [empWorkBu3, setEmpWorkBu3] = useState("");
    const [empWorkBu4, setEmpWorkBu4] = useState("");
    const [empWorkBu5, setEmpWorkBu5] = useState("");
    const [empWorkBu6, setEmpWorkBu6] = useState("");

    const [listLawbu, setListLawbu] = useState([]);
    const [lawbu, setLawbu] = useState([]);
    const [lawbu2, setLawbu2] = useState([]);
    const [lawbu3, setLawbu3] = useState([]);
    const [lawbu4, setLawbu4] = useState([]);
    const [lawbu5, setLawbu5] = useState([]);
    const [lawbu6, setLawbu6] = useState([]);
    const [empLawBu1, setEmpLawBu1] = useState("");
    const [empLawBu2, setEmpLawBu2] = useState("");
    const [empLawBu3, setEmpLawBu3] = useState("");
    const [empLawBu4, setEmpLawBu4] = useState("");
    const [empLawBu5, setEmpLawBu5] = useState("");
    const [empLawBu6, setEmpLawBu6] = useState("");

    const [name, setName] = useState("");
    const [nickname, setNickname] = useState("");
    const [orgTypeId, setOrgTypeId] = useState("");
    const [empBoss, setEmpBoss] = useState("");
    const [parentId, setParentId] = useState("");
    const [level, setLevel] = useState("");
    const [orgId, setOrgId] = useState("");
    const [boss, setBoss] = useState({});

    const handleShow = () => setShowModal(true);

    const [showMsg, setShowMsg] = useState(false);
    const alertModalCls = () => setShowMsg(false);
    const alertModalOn = () => setShowMsg(true);
    const [textMsg, setTextMsg] = useState("");

    const [activeSarabun, setActiveSarabun] = useState(true);

    const handleChangeActive = nextActive => {
        setActiveSarabun(nextActive);
    };

    useEffect(() => {
        fetchData();
    }, [keycloak.token]);

    const fetchData = async () => {
        initDataTable();
    }

    //add
    const addUser = async () => {
        handleShow();
        let listData = await axios.get(config.get("apiUrl") + "/api/master/org/detail/list/1", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        listData.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setListWorBu(listData.data);
        setWorkbu(listData.data.filter(item => item.orgTypeId === 1 && item.level === 1));

        let listData2 = await axios.get(config.get("apiUrl") + "/api/master/org/detail/list/2", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        listData2.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setListLawbu(listData2.data);

        let rsBoss = await axios.get(config.get("apiUrl") + "/api/master/org/boss", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        setBoss(rsBoss.data.data.records);

        setOrgTypeId("1");
        setShowByType1(true);
    }

    //showEdit
    const getOrgDetail = async (data, typeId) => {
        handleShow();

        let listData = await axios.get(config.get("apiUrl") + "/api/master/org/detail/list/1", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        listData.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setListWorBu(listData.data);

        let listData2 = await axios.get(config.get("apiUrl") + "/api/master/org/detail/list/2", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        listData2.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setListLawbu(listData2.data);

        if (data !== "") {
            let rsData = await axios.get(config.get("apiUrl") + "/api/master/org/detail/" + data, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `bearer ${keycloak.token}`,
                },
            });
            setOrgDetail(rsData.data);

            let rsBoss = await axios.get(config.get("apiUrl") + "/api/master/org/boss", {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `bearer ${keycloak.token}`,
                },
            });
            setBoss(rsBoss.data.data.records);

            let bossId = rsData.data.boss;
            let bossSelected = rsBoss.data.data.records.find(item => item.value === bossId);
            if (bossSelected) {
                setEmpBoss({value: bossSelected.value, label: bossSelected.label});
            }
            setOrgId(data);
            setOrgTypeId(rsData.data.orgTypeId);
            setName(rsData.data.name);
            setNickname(rsData.data.nickname);
            setLevel(rsData.data.level);
            setParentId(rsData.data.parentId);
            if (rsData.data.activeSarabun === "1") {
                setActiveSarabun(true);
            } else {
                setActiveSarabun(false);
            }
            let rsAncestor = await axios.get(config.get("apiUrl") + "/api/master/org/detail/ancestor/" + data, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `bearer ${keycloak.token}`,
                },
            });
            let data1 = (rsAncestor.data.filter(i => i.level === 1).map(x => x.orgId)).toString();
            let data2 = (rsAncestor.data.filter(i => i.level === 2).map(x => x.orgId)).toString();
            let data3 = (rsAncestor.data.filter(i => i.level === 3).map(x => x.orgId)).toString();
            let data4 = (rsAncestor.data.filter(i => i.level === 4).map(x => x.orgId)).toString();
            let data5 = (rsAncestor.data.filter(i => i.level === 5).map(x => x.orgId)).toString();
            let data6 = (rsAncestor.data.filter(i => i.level === 6).map(x => x.orgId)).toString();

            if (typeId === "1") {
                setShowByType1(true);
                setShowByType2(false);
                let empListLevel1 = listData.data.filter(item => item.orgTypeId === 1 && item.level === 1);
                setWorkbu(empListLevel1);
                setEmpWorkBu1(data1);

                let empListLevel2 = listData.data.filter(item => item.orgTypeId === 1 && item.level === 2 && item.parentId === parseInt(data1));
                setWorkbu2(empListLevel2);
                setEmpWorkBu2(data2);

                let empListLevel3 = listData.data.filter(item => item.orgTypeId === 1 && item.level === 3 && item.parentId === parseInt(data2));
                setWorkbu3(empListLevel3);
                setEmpWorkBu3(data3);


                let empListLevel4 = listData.data.filter(item => item.orgTypeId === 1 && item.level === 4 && item.parentId === parseInt(data3));
                setWorkbu4(empListLevel4);
                setEmpWorkBu4(data4);

                let empListLevel5 = listData.data.filter(item => item.orgTypeId === 1 && item.level === 5 && item.parentId === parseInt(data4));
                setWorkbu5(empListLevel5);
                setEmpWorkBu5(data5);

                let empListLevel6 = listData.data.filter(item => item.orgTypeId === 1 && item.level === 6 && item.parentId === parseInt(data5));
                setWorkbu6(empListLevel6);
                setEmpWorkBu6(data6);
            }

            if (typeId === "2") {
                setShowByType1(false);
                setShowByType2(true);
                let empListLevel1 = listData2.data.filter(item => item.orgTypeId === 2 && item.level === 1);
                setLawbu(empListLevel1);
                setEmpLawBu1(data1);

                let empListLevel2 = listData2.data.filter(item => item.orgTypeId === 2 && item.level === 2 && item.parentId === parseInt(data1));
                setLawbu2(empListLevel2);
                setEmpLawBu2(data2);

                let empListLevel3 = listData2.data.filter(item => item.orgTypeId === 2 && item.level === 3 && item.parentId === parseInt(data2));
                setLawbu3(empListLevel3);
                setEmpLawBu3(data3);


                let empListLevel4 = listData2.data.filter(item => item.orgTypeId === 2 && item.level === 4 && item.parentId === parseInt(data3));
                setLawbu4(empListLevel4);
                setEmpLawBu4(data4);

                let empListLevel5 = listData2.data.filter(item => item.orgTypeId === 2 && item.level === 5 && item.parentId === parseInt(data4));
                setLawbu5(empListLevel5);
                setEmpLawBu5(data5);

                let empListLevel6 = listData2.data.filter(item => item.orgTypeId === 2 && item.level === 6 && item.parentId === parseInt(data5));
                setLawbu6(empListLevel6);
                setEmpLawBu6(data6);
            }
        }
    }

    const onChangeHandler = (level, e) => {
        let orgId = e.target.value;
        let workbuList = listWorBu.filter(item => item.level === (level + 1) && item.parentId === parseInt(orgId));
        switch (level) {
            case 1 :
                setWorkbu2([]);
                setEmpWorkBu2("");
                setLevel(level);
            case 2 :
                setWorkbu3([]);
                setEmpWorkBu3("");
                setLevel(level);
            case 3 :
                setWorkbu4([]);
                setEmpWorkBu4("");
                setLevel(level);
            case 4 :
                setWorkbu5([]);
                setEmpWorkBu5("");
                setLevel(level);
            case 5 :
                setWorkbu6([]);
                setEmpWorkBu6("");
                setLevel(level);
        }

        if (level === 1) {
            setEmpWorkBu1(orgId);
            setWorkbu2(workbuList);
        }
        if (level === 2) {
            setParentId(empWorkBu1);
            setEmpWorkBu2(orgId);
            setWorkbu3(workbuList);
        }
        if (level === 3) {
            setParentId(empWorkBu2);
            setEmpWorkBu3(orgId);
            setWorkbu4(workbuList);
        }
        if (level === 4) {
            setParentId(empWorkBu3);
            setEmpWorkBu4(orgId);
            setWorkbu5(workbuList);
        }
        if (level === 5) {
            setParentId(empWorkBu4);
            setEmpWorkBu5(orgId);
            setWorkbu6(workbuList);
        }
        if (level === 6) {
            setParentId(empWorkBu5);
            setEmpWorkBu6(orgId);
        }
        if (orgId !== "") {
            setParentId(orgId);
            setLevel(level + 1);
        }
    }
    const onChangeHandlerLaw = (level, e) => {
        let orgId = e.target.value;
        let lawbuList = listLawbu.filter(item => item.level === (level + 1) && item.parentId === parseInt(orgId));

        switch (level) {
            case 1 :
                setLawbu2([]);
                setEmpLawBu2("");
                setLevel(level);
            case 2 :
                setLawbu3([]);
                setEmpLawBu3("");
                setLevel(level);
            case 3 :
                setLawbu4([]);
                setEmpLawBu3("");
                setLevel(level);
            case 4 :
                setLawbu5([]);
                setEmpLawBu5("");
                setLevel(level);
            case 5 :
                setLawbu6([]);
                setEmpLawBu6("");
                setLevel(level);
        }

        if (level === 1) {
            setEmpLawBu1(orgId);
            setLawbu2(lawbuList);
        }
        if (level === 2) {
            setParentId(empLawBu1);
            setEmpLawBu2(orgId);
            setLawbu3(lawbuList);
        }
        if (level === 3) {
            setParentId(empLawBu2);
            setEmpLawBu3(orgId);
            setLawbu4(lawbuList);
        }
        if (level === 4) {
            setParentId(empLawBu3);
            setEmpLawBu4(orgId);
            setLawbu5(lawbuList);
        }
        if (level === 5) {
            setParentId(empLawBu4);
            setEmpLawBu5(orgId);
            setLawbu6(lawbuList);
        }
        if (level === 6) {
            setParentId(empLawBu5);
            setEmpLawBu6(orgId);
        }
        if (orgId !== "") {
            setParentId(orgId);
            setLevel(level + 1);
        }

    }
    const handleChangeOrgTypeId = (e) => {
        let orgTypeId = e.target.value;
        if (orgTypeId === "1") {
            setOrgTypeId(orgTypeId);
            setWorkbu(listWorBu.filter(item => item.orgTypeId === 1 && item.level === 1))
            setShowByType1(true);
            setShowByType2(false);
        }
        if (orgTypeId === "2") {
            setOrgTypeId(orgTypeId);
            setLawbu(listLawbu.filter(item => item.orgTypeId === 2 && item.level === 1));
            setShowByType1(false);
            setShowByType2(true);
        }
    }
    const handleClose = () => {
        setShowModal(false);
        setShowByType1(false);
        setShowByType2(false);
        setName("");
        setOrgId("");
        setNickname("");
        setEmpBoss("");
        setActiveSarabun(true);
        setOrgTypeId("");
        setOrgDetail("");
        setEmpWorkBu1("");
        setEmpWorkBu2("");
        setEmpWorkBu3("");
        setEmpWorkBu4("");
        setEmpWorkBu5("");
        setEmpWorkBu6("");

        setEmpLawBu1("");
        setEmpLawBu2("");
        setEmpLawBu3("");
        setEmpLawBu4("");
        setEmpLawBu5("");
        setEmpLawBu6("");
    }
    const onSubmit = async () => {
        let params = {
            org_id: parseInt(orgId),
            name: name,
            nickname: nickname,
            level: level,
            parent_id: parentId,
            org_type: orgTypeId,
            boss: empBoss ? empBoss.value : null,
            active_sarabun: activeSarabun,
        };
        // if (params.boss === null || params.parent_id === '' || params.name === '') {
        if (params.parent_id === '' || params.name === '') {
            alertModalOn();
            setTextMsg("กรอกข้อมูลให้ครบ");
        } else {
            let rsSave = await axios.post(
                config.get("apiUrl") + "/api/master/org/save",
                params,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${keycloak.token}`,
                    },
                }
            );
            if (rsSave.data.status === "success") {
                alertModalOn();
                setTextMsg("บันทึกข้อมูลเรียบร้อย");
                alertModalCls();
                handleClose();
                dataTable.ajax.reload();
            }
        }
    }
    const initDataTable = () => {
        $.fn.dataTable.ext.errMode = 'none';
        setDataTable(
            $('#data-table').DataTable({
                order: [0, 'asc'],
                ajax: {
                    contentType: 'application/json',
                    url: config.get('apiUrl') + '/api/master/org/data/table',
                    type: 'POST',
                    data: function (d) {
                        return JSON.stringify(d);
                    },
                    headers: {
                        Authorization: `bearer ${keycloak.token}`,
                    },
                },
                processing: true,
                serverSide: true,
                columns: [
                    {
                        width: '100px',
                        orderable: true,
                        data: 'orgId',
                        className: 'text-center',
                        responsivePriority: 1,
                    },
                    {
                        width: '100px',
                        orderable: true,
                        data: 'typeName',
                        className: 'text-center',
                    },
                    {
                        width: '150px',
                        orderable: true,
                        data: 'name',
                        className: 'text-left',
                    },
                    {
                        width: '100px',
                        orderable: false,
                        data: '',
                        className: 'text-center',
                        render: function (data, type, row) {
                            return `<button class="btn bg-color text-light btn-manage-user" type="button"><i class="fa fa-building mr-2"></i>แก้ไข</button>`;
                        }
                    },
                ],
                rowCallback: function (row, data) {
                    $('td', row).find('.btn-manage-user').on('click', function (e) {
                        getOrgDetail(data.orgId, data.orgTypeId);
                    });

                },
                lengthChange: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'ค้นหา...',
                    lengthMenu: '_MENU_ รายการ/หน้า',
                    sLengthMenu: '_MENU_ รายการ/หน้า',
                    sProcessing: 'กำลังดำเนินการ...',
                    sZeroRecords: 'ไม่พบข้อมูล',
                    sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                    sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                    sInfoFiltered: '',
                    sSearch: '',
                    sUrl: '',
                    oPaginate: {
                        sFirst: 'หน้าแรก',
                        sPrevious: 'ก่อนหน้า',
                        sNext: 'ถัดไป',
                        sLast: 'หน้าสุดท้าย',
                    },
                    processing:
                        '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
                },
            })
        );

        // Datable Component CSS.
        document
            .querySelector('div.dataTables_length select')
            .classList.add('datatable-length');
        document
            .querySelector('div.dataTables_filter input')
            .classList.add('datatable-search');
    };
    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"/>
                        </div>
                        <div className="row mb-3 border-bottom">
                            <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                    <li className="breadcrumb-item text-breadcrumb">
                                        <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1"/>
                                        <Link to="./" className="text-breadcrumb">
                                            หน้าหลัก
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item text-breadcrumb">ผู้ดูแลระบบ</li>
                                    <li className="breadcrumb-item text-breadcrumb-active">
                                        ข้อมูลองค์กร
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                  <span className="text-header-x2 ddc-color">
                    ข้อมูลองค์กร
                  </span>
                                    <br/><br/>
                                    <div className="row">
                                        <div className="col-12">
                                            <button
                                                onClick={addUser}
                                                style={{color: 'white'}}
                                                className="btn bg-color mb-2">
                                                <i className="fa fa-plus"/>&nbsp;&nbsp;เพิ่มองค์กร
                                            </button>
                                        </div>
                                    </div>
                                    <br/>
                                    <table
                                        id="data-table"
                                        className="table table-striped table-bordered display w-100 margin-bottom-30"
                                    >
                                        <thead>
                                        <tr>
                                            <th className="text-center">รหัสหน่วยงาน</th>
                                            <th className="text-center">ผังปฏิบัติงาน</th>
                                            <th className="text-center">ชื่อหน่วยงาน</th>
                                            <th className="text-center">ดำเนินการ</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>

            </div>
            <Modal show={showModal} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>จัดการข้อมูลหน่วยงาน</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body className="text-color-form text-center">
                        {showByType1 && (
                            <Container fluid>
                                <div className="form-group row">
                                    <div className="col-12 col-md-4 pt-2">
                              <span className="text-color-form align-middle">
                                ชื่อ / สำนัก กอง
                              </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="name"
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            ref={register}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                              <span className="text-color-form align-middle">
                                ตัวย่อหน่วยงาน
                              </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="nickname"
                                            onChange={(e) => setNickname(e.target.value)}
                                            value={nickname}
                                            ref={register}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                              <span className="text-color-form align-middle">
                               ผังปฏิบัติงาน
                              </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select className="form-control shadow-none" value={orgTypeId}
                                                onChange={(e) => handleChangeOrgTypeId(e)}>
                                            <option value="1">ผังปฏิบัติงานจริง</option>
                                            <option value="2">ผังตามกฎหมาย</option>
                                        </select>
                                    </div>
                                    < div className="col-12 col-md-4 pt-2">
                                <span className="text-color-form align-middle">
                                    สำนัก/กอง
                                </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none work_bu1"
                                            name="work_bu1"
                                            ref={register}
                                            value={empWorkBu1}
                                            onChange={(e) =>
                                                onChangeHandler(1, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {workbu.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-12 col-md-4 pt-2">
                                      <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 1 ระดับ
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none work_bu1"
                                            name="work_bu2"
                                            ref={register}
                                            value={empWorkBu2}
                                            onChange={(e) =>
                                                onChangeHandler(2, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {workbu2.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                                      <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 2 ระดับ
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none work_bu1"
                                            name="work_bu3"
                                            ref={register}
                                            value={empWorkBu3}
                                            onChange={(e) =>
                                                onChangeHandler(3, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {workbu3.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                                      <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 3 ระดับ
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none work_bu1"
                                            name="work_bu4"
                                            ref={register}
                                            value={empWorkBu4}
                                            onChange={(e) =>
                                                onChangeHandler(4, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {workbu4.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                                      <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 4 ระดับ
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none work_bu1"
                                            name="work_bu5"
                                            ref={register}
                                            value={empWorkBu5}
                                            onChange={(e) =>
                                                onChangeHandler(5, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {workbu5.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                                      <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 5 ระดับ
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none work_bu1"
                                            name="work_bu6"
                                            ref={register}
                                            value={empWorkBu6}
                                            onChange={(e) =>
                                                onChangeHandler(6, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {workbu6.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                              <span className="text-color-form align-middle">
                                 ผู้บริหาร
                              </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <Select value={empBoss}
                                                options={boss}
                                                onChange={(ValueType) => setEmpBoss(ValueType)}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                                      <span className="text-color-form align-middle">
                                        สถานะการใช่้งาน (ระบบสารบัญ)
                                      </span>
                                    </div>
                                    <div className="col-12 col-sm-1 pt-1">
                                        <Switch
                                            onChange={handleChangeActive}
                                            checked={activeSarabun}
                                            className="react-switch"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                        />
                                    </div>
                                </div>
                            </Container>
                        )}

                        {showByType2 && (
                            <Container fluid>
                                <div className="form-group row">
                                    <div className="col-12 col-md-4 pt-2">
                              <span className="text-color-form align-middle">
                                ชื่อ / สำนัก กอง
                              </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="name"
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            ref={register}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                              <span className="text-color-form align-middle">
                                ตัวย่อหน่วยงาน
                              </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="nickname"
                                            onChange={(e) => setNickname(e.target.value)}
                                            value={nickname}
                                            ref={register}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                   ผังปฏิบัติงาน
                                  </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select className="form-control shadow-none" value={orgTypeId}
                                                onChange={(e) => handleChangeOrgTypeId(e)}>
                                            <option value="1">ผังปฏิบัติงานจริง</option>
                                            <option value="2">ผังตามกฎหมาย</option>
                                        </select>
                                    </div>
                                    < div className="col-12 col-md-4 pt-2">
                                <span className="text-color-form align-middle">
                                    สำนัก/กอง
                                </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none law_bu1"
                                            name="law_bu1"
                                            ref={register}
                                            value={empLawBu1}
                                            onChange={(e) =>
                                                onChangeHandlerLaw(1, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {lawbu.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    < div className="col-12 col-md-4 pt-2">
                                    <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 1 ระดับ
                                    </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none law_bu1"
                                            name="law_bu1"
                                            ref={register}
                                            value={empLawBu2}
                                            onChange={(e) =>
                                                onChangeHandlerLaw(2, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {lawbu2.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    < div className="col-12 col-md-4 pt-2">
                                    <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 2 ระดับ
                                    </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none law_bu3"
                                            name="law_bu3"
                                            ref={register}
                                            value={empLawBu3}
                                            onChange={(e) =>
                                                onChangeHandlerLaw(3, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {lawbu3.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    < div className="col-12 col-md-4 pt-2">
                                    <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 3 ระดับ
                                    </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none law_bu4"
                                            name="law_bu4"
                                            ref={register}
                                            value={empLawBu4}
                                            onChange={(e) =>
                                                onChangeHandlerLaw(4, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {lawbu4.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    < div className="col-12 col-md-4 pt-2">
                                    <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 4 ระดับ
                                    </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none law_bu5"
                                            name="law_bu5"
                                            ref={register}
                                            value={empLawBu5}
                                            onChange={(e) =>
                                                onChangeHandlerLaw(5, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {lawbu5.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    < div className="col-12 col-md-4 pt-2">
                                    <span className="text-color-form align-middle">
                                        ต่ำกว่าสำนัก/กอง 5 ระดับ
                                    </span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <select
                                            className="form-control shadow-none law_bu6"
                                            name="law_bu6"
                                            ref={register}
                                            value={empLawBu6}
                                            onChange={(e) =>
                                                onChangeHandlerLaw(6, e)
                                            }
                                        >
                                            <option value="">กรุณาเลือก</option>
                                            {lawbu6.map((item) => (
                                                <option
                                                    key={item.orgId}
                                                    value={item.orgId}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                              <span className="text-color-form align-middle">
                                 ผู้บริหาร
                              </span>
                                        <span className="text-danger"> *</span>
                                    </div>
                                    <div className="col-12 col-md-8 pt-1">
                                        <Select value={empBoss}
                                                options={boss}
                                                onChange={(ValueType) => setEmpBoss(ValueType)}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 pt-2">
                                      <span className="text-color-form align-middle">
                                        สถานะการใช่้งาน (ระบบสารบัญ)
                                      </span>
                                    </div>
                                    <div className="col-12 col-sm-1 pt-1">
                                        <Switch
                                            onChange={handleChangeActive}
                                            checked={activeSarabun}
                                            className="react-switch"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                        />
                                    </div>
                                </div>
                            </Container>
                        )}

                        <div className="col-12 col-md-3 pt-1">
                            <Button
                                type="submit"
                                variant="contained"
                                id="btn-submit"
                                className="btn-no-border btn-color-submit-small btn-block"
                            >
                                <i className="far fa-save pr-2"/>
                                บันทึก
                            </Button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
            <Modal show={showMsg} onHide={alertModalCls}>
                <Modal.Header closeButton>
                    <Modal.Title/>
                </Modal.Header>
                <Modal.Body className="text-color-form text-center">
                    {textMsg}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default MainMasOrganization;
