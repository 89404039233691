import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const adminmanagement = () => {
  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">ผู้ดูแลระบบ</li>
                  <li className="breadcrumb-item text-breadcrumb-active">การจัดการผู้ใช้งาน</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 offset-md-4 col-md-4 text-center mt-2">
                        <Button
                          type="submit"
                          variant="contained"
                          className="btn-no-border btn-color-menu btn-block"
                          component={Link} to="./admin-create-user"
                        >
                          สร้างผู้ใช้งาน
                        </Button>
                      </div>
                      <div className="col-12 offset-md-4 col-md-4 text-center mt-2">
                        <Button
                          type="submit"
                          variant="contained"
                          className="btn-no-border btn-color-menu btn-block"
                          component={Link} to="./admin-change-password"
                        >
                          เปลี่ยนรหัสผ่าน
                        </Button>
                      </div>
                      <div className="col-12 offset-md-4 col-md-4 text-center mt-2">
                        <Button
                          type="submit"
                          variant="contained"
                          className="btn-no-border btn-color-menu btn-block"
                          component={Link} to="./admin-user-management"
                        >
                          จัดการสิทธิการเข้าใช้งานระบบ
                        </Button>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default adminmanagement;
