/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import moment from "moment";
import { WatDatePicker } from 'thaidatepicker-react'
import Webcam from "react-webcam";
import th from "date-fns/locale/th";
registerLocale("th", th);

const Checkin = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit } = useForm();
  const [boss, setBoss] = useState([]);
  const [approve, setApprove] = useState([]);
  const [user, setUser] = useState([]);
  const [userFullname, setUserFullname] = useState("");
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(), 
    new Date().getDate()
  );
  const [startDate, setStartDate] = useState(calendarThai);
  const [startTime, setStartTime] = useState(moment(new Date()).format("HH:mm"));

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [textMsg, setTextMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);

  const fetchData = async () => {
    let rsUser = await axios.get(
      config.get("apiUrl") +
        "/api/employee/" +
        keycloak.tokenParsed.preferred_username,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setUserFullname(rsUser.data.data.fname + " " + rsUser.data.data.lname);
    setUser(rsUser.data.data);

    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        like_employee_id: "",
        like_fname: "",
        like_lname: "",
      },
    };
    let rsBoss = await axios.post(
      config.get("apiUrl") + "/api/employee/search",
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setBoss(rsBoss.data.data.records);
    setApprove(rsBoss.data.data.records);
  };

  const getLocation = async () => {
    let location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          //document.getElementById("place_latlong").innerHTML = position.coords.latitude + "," + position.coords.longitude;
        },
        (error) => {
          //document.getElementById("place_latlong").innerHTML ="กรุณาเปิด localtion";
        }
      );
    } else {
      //document.getElementById("place_latlong").innerHTML = "กรุณาเปิด localtion";
    }
  }

  useEffect(() => {
    fetchData();
    getLocation();
    dateChange();
  }, []);

  const dateChange = async () => {
    $(".start_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true })
    .datepicker('setStartDate', new Date()).datepicker('setDate', new Date()); 
  }

  const onSubmit = async (data) => {
    if(empPic1 != "")
    {
      let params = {
        work_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        date_time: moment(startTime,' HH:mm').format("YYYY-MM-DD HH:mm:ss"),
        latitude: data.latitude,
        longitude: data.longitude,
        pic_check_in:empPic1
      };
      let rsSave = await axios.post(
        config.get("apiUrl") + "/api/emp-time/check-in",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );

      if (rsSave.data.status === "success") {
        handleShow();
        setTextMsg("บันทึกข้อมูลเรียบร้อย");
      } else {
        handleShow();
        setTextMsg("บันทึกไม่สำเร็จ");
      }
    }
    else
    {
      alert("กรุณาถ่ายภาพ");
    }
    
  };

  const [showCamera1, setShowCamera1] = useState(false);
  const camera1Close = () => setShowCamera1(false);
  const camera1Show = () => setShowCamera1(true);

  const [empPic1, setEmpPic1] = useState("");
  const uploadPic1 = async (data) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(data));
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    setEmpPic1(rsImg.data.data.file_id);
  };

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    
    return new Blob([ia], {type:mimeString});
    }

  const [imgPreview, setImgPreview] = useState("img/noimage.png");
  const viewImage = (data) => {setEmpImgView(config.get("apiUrl") + "/api/file/" + data);};
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {hiddenFileInput.current.click();};
  const handleChange = (event) => {const fileUploaded = event.target.files[0];uploadImg(fileUploaded);};

  const videoConstraints = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: 0.6666666667
  };

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({width:720,height:1280});
    uploadPic1(imageSrc);
    setImgSrc(imageSrc);
    camera1Close();
  }, [webcamRef, setImgSrc]);

  const hiddenImgInput = React.useRef(null);
  const handleUploadImg = (pic, event) => {
    if(pic==="pic1"){hiddenImgInput.current.click();}
  };

  const handleUploadProcess = (pic, event) => {
    const fileUploaded = event.target.files[0];
    uploadImgTemp(pic, fileUploaded);
  };

  const uploadImgTemp = async (pic, data) => {
    let formData = new FormData();
    formData.append("file", data);
    //console.log(formData);
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    if(pic==="pic1"){
      setEmpPic1(rsImg.data.data.file_id);
      setImgSrc(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id)
    }

  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    บันทึกเวลาทำงาน
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    บันทึกเวลาเข้า
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius elevation-3">
                  <div className="card-body">
                      <span className="text-header-x2 ddc-color">
                        บันทึกเวลาเข้า
                      </span>
                      <br /> <br />
                      <span className="text-color-caption">
                        รายละเอียดผู้ขออนุญาต
                      </span>
                      <div className="row mt-3">
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            รหัสพนักงาน
                          </span>
                        </div>
                        <div className="col-12 col-md-2 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            defaultValue={user.employee_id}
                          />
                        </div>
                        <div className="col-12 col-md-1 pt-2">
                          <span className="text-color-form align-middle">
                            ชื่อ - สกุล
                          </span>
                        </div>
                        <div className="col-12 col-md-7 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            defaultValue={userFullname}
                          />
                        </div>
                      </div>
                      
                      <br />
                      <br />
                      <hr style={{ margin: "6px" }} />
                      <br />
                      <span className="text-color-caption">
                        รายละเอียดการทำงานนอกสถานที่
                      </span>
                      <div className="row mt-3">
                        <div className="col-12 col-md-8">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                วันที่
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                              <div className="form-control-wrapper form-control-icon-right">
                                <input type="text" defaultValue={startDate} name="start_date"  data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none"  />
                                <i className="far fa-calendar-alt"></i>
                              </div>
                            </div>
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                เวลา
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="date_time"
                                ref={register}
                                onChange={(e) => setLatitude(e.target.value)}
                                value={startTime}
                                readOnly
                              />
                              
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                ละติจูด
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="latitude"
                                ref={register}
                                onChange={(e) => setLatitude(e.target.value)}
                                value={latitude}
                                readOnly
                              />
                            </div>
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                ลองจิจูด
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="longitude"
                                ref={register}
                                onChange={(e) => setLongitude(e.target.value)}
                                value={longitude}
                                readOnly
                              />
                            </div>
                          </div>
                         
                        </div>
                        <div className="col-12 col-md-4 pt-2 text-center">
                        <span className="text-color-form align-middle">รูปสถานที่</span><br />
                          {!imgSrc && (
                            <>
                              <Image src={imgPreview} style={{width: "200px", }}/> <br /><Button type="button" variant="contained" size="large" className="btn-no-border btn-color-submit-small" style={{width: "90px"}} onClick={camera1Show}><i className="fas fa-camera-retro"></i></Button>  
                            </>
                          )}
                          {imgSrc && (<Image src={imgSrc} style={{width: "200px", paddingBottom: 10, }}/>)}
                          <br />
                          {imgSrc && (<Button type="button" variant="contained" className="btn-no-border btn-color-delete" onClick={(e) => setImgSrc(null)}><i className="fas fa-trash-alt"></i></Button>)}                 
                        </div>
                      </div>
                      <div className="row">
                            <div className="col-12 mt-12 text-center">
                              <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                className="btn-no-border btn-color-submit"
                              >
                                <i className="fas fa-user-clock pr-2"></i>
                                บันทึกเวลาเข้า
                              </Button>
                            </div>
                          </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal show={showCamera1} onHide={camera1Close} size="xl">
        <Modal.Body className="text-color-form text-center">
          {!imgSrc && (<Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={360} height={640} videoConstraints={videoConstraints} screenshotQuality={1} />)}
          <br />
          {!imgSrc && (<Button type="button" variant="contained" size="large" className="btn-no-border btn-color-submit-small" onClick={capture} ><i className="fas fa-camera-retro"></i></Button>)}
          <br />
        </Modal.Body>
      </Modal>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>
    </div>
  );
};

export default Checkin;
