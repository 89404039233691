/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Image, Row, Col, Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import config from "react-global-configuration";
import moment from "moment";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; 
import "react-datepicker/dist/react-datepicker.css";


const WorkSchedule = () => {
  const [keycloak] = useKeycloak();
  const navigate = useHistory();
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");
  const [showDelMsg, setShowDelMsg] = useState(false);
  const handleDelClose = () => setShowDelMsg(false);
  const handleDelShow = () => setShowDelMsg(true);

  const [timeDate, setTimeDate] = useState(new Date());
  const [time2Date, setTime2Date] = useState(new Date());
  const [time3Date, setTime3Date] = useState(new Date());
  const [time4Date, setTime4Date] = useState(new Date());
  const [time5Date, setTime5Date] = useState(new Date());

  const [timeBTDate, setTimeBTDate] = useState(new Date());
  const [timeBLTDate, setTimeBLTDate] = useState(new Date());
  const [timeLTDate, setTimeLTDate] = useState(new Date());

  const [D1time1Date, setD1time1Date] = useState(new Date());
  const [D1time2Date, setD1time2Date] = useState(new Date());
  const [D1time3Date, setD1time3Date] = useState(new Date());
  const [D1time4Date, setD1time4Date] = useState(new Date());
  const [D1time5Date, setD1time5Date] = useState(new Date());

  const [D2time1Date, setD2time1Date] = useState(new Date());
  const [D2time2Date, setD2time2Date] = useState(new Date());
  const [D2time3Date, setD2time3Date] = useState(new Date());
  const [D2time4Date, setD2time4Date] = useState(new Date());
  const [D2time5Date, setD2time5Date] = useState(new Date());

  const [D3time1Date, setD3time1Date] = useState(new Date());
  const [D3time2Date, setD3time2Date] = useState(new Date());
  const [D3time3Date, setD3time3Date] = useState(new Date());
  const [D3time4Date, setD3time4Date] = useState(new Date());
  const [D3time5Date, setD3time5Date] = useState(new Date());

  const [D4time1Date, setD4time1Date] = useState(new Date());
  const [D4time2Date, setD4time2Date] = useState(new Date());
  const [D4time3Date, setD4time3Date] = useState(new Date());
  const [D4time4Date, setD4time4Date] = useState(new Date());
  const [D4time5Date, setD4time5Date] = useState(new Date());

  const [D5time1Date, setD5time1Date] = useState(new Date());
  const [D5time2Date, setD5time2Date] = useState(new Date());
  const [D5time3Date, setD5time3Date] = useState(new Date());
  const [D5time4Date, setD5time4Date] = useState(new Date());
  const [D5time5Date, setD5time5Date] = useState(new Date());

  const [D6time1Date, setD6time1Date] = useState(new Date());
  const [D6time2Date, setD6time2Date] = useState(new Date());
  const [D6time3Date, setD6time3Date] = useState(new Date());
  const [D6time4Date, setD6time4Date] = useState(new Date());
  const [D6time5Date, setD6time5Date] = useState(new Date());

  const [D7time1Date, setD7time1Date] = useState(new Date());
  const [D7time2Date, setD7time2Date] = useState(new Date());
  const [D7time3Date, setD7time3Date] = useState(new Date());
  const [D7time4Date, setD7time4Date] = useState(new Date());
  const [D7time5Date, setD7time5Date] = useState(new Date());

  const [work1, setWork1] = useState(0);
  const [work2, setWork2] = useState(0);
  const [work3, setWork3] = useState(0);
  const [work4, setWork4] = useState(0);
  const [work5, setWork5] = useState(0);
  const [work6, setWork6] = useState(0);
  const [work7, setWork7] = useState(0);

  const [shift, setShift] = useState([]);
  const [shiftDays, setShiftDays] = useState([]);
  const { register, handleSubmit } = useForm();

  const [shiftId, setShiftId] = useState('');
  const [shiftName, setShiftName] = useState('');

  const onSubmit = (data) => {create(data);};

  const create = async (data) => {
    let q=shiftTemp;
    if(shiftId !== ""){q=shiftId;}
    let params = {
      shift_id: q,
      shift_name: data.shift_name,
      shift_days: [
        {
          day: 1,
          sh_time_in: moment(D1time1Date).format("HH:mm"),
          sh_brake_out: moment(D1time2Date).format("HH:mm"),
          sh_brake_in: moment(D1time3Date).format("HH:mm"),
          sh_time_out: moment(D1time4Date).format("HH:mm"),
          bf_time_in: moment(timeBTDate).format("HH:mm"),
          lt_before: moment(timeBLTDate).format("HH:mm"),
          lt_time: moment(timeLTDate).format("HH:mm"),
          work_day: work1,
          work_hours: moment(D1time5Date).format("HH:mm"),
        },
        {
          day: 2,
          sh_time_in: moment(D2time1Date).format("HH:mm"),
          sh_brake_out: moment(D2time2Date).format("HH:mm"),
          sh_brake_in: moment(D2time3Date).format("HH:mm"),
          sh_time_out: moment(D2time4Date).format("HH:mm"),
          bf_time_in: moment(timeBTDate).format("HH:mm"),
          lt_before: moment(timeBLTDate).format("HH:mm"),
          lt_time: moment(timeLTDate).format("HH:mm"),
          work_day: work2,
          work_hours: moment(D2time5Date).format("HH:mm"),
        },
        {
          day: 3,
          sh_time_in: moment(D3time1Date).format("HH:mm"),
          sh_brake_out: moment(D3time2Date).format("HH:mm"),
          sh_brake_in: moment(D3time3Date).format("HH:mm"),
          sh_time_out: moment(D3time4Date).format("HH:mm"),
          bf_time_in: moment(timeBTDate).format("HH:mm"),
          lt_before: moment(timeBLTDate).format("HH:mm"),
          lt_time: moment(timeLTDate).format("HH:mm"),
          work_day: work3,
          work_hours: moment(D3time5Date).format("HH:mm"),
        },
        {
          day: 4,
          sh_time_in: moment(D4time1Date).format("HH:mm"),
          sh_brake_out: moment(D4time2Date).format("HH:mm"),
          sh_brake_in: moment(D4time3Date).format("HH:mm"),
          sh_time_out: moment(D4time4Date).format("HH:mm"),
          bf_time_in: moment(timeBTDate).format("HH:mm"),
          lt_before: moment(timeBLTDate).format("HH:mm"),
          lt_time: moment(timeLTDate).format("HH:mm"),
          work_day: work4,
          work_hours: moment(D4time5Date).format("HH:mm"),
        },
        {
          day: 5,
          sh_time_in: moment(D5time1Date).format("HH:mm"),
          sh_brake_out: moment(D5time2Date).format("HH:mm"),
          sh_brake_in: moment(D5time3Date).format("HH:mm"),
          sh_time_out: moment(D5time4Date).format("HH:mm"),
          bf_time_in: moment(timeBTDate).format("HH:mm"),
          lt_before: moment(timeBLTDate).format("HH:mm"),
          lt_time: moment(timeLTDate).format("HH:mm"),
          work_day: work5,
          work_hours: moment(D5time5Date).format("HH:mm"),
        },
        {
          day: 6,
          sh_time_in: moment(D6time1Date).format("HH:mm"),
          sh_brake_out: moment(D6time2Date).format("HH:mm"),
          sh_brake_in: moment(D6time3Date).format("HH:mm"),
          sh_time_out: moment(D6time4Date).format("HH:mm"),
          bf_time_in: moment(timeBTDate).format("HH:mm"),
          lt_before: moment(timeBLTDate).format("HH:mm"),
          lt_time: moment(timeLTDate).format("HH:mm"),
          work_day: work6,
          work_hours: moment(D6time5Date).format("HH:mm"),
        },
        {
          day: 7,
          sh_time_in: moment(D7time1Date).format("HH:mm"),
          sh_brake_out: moment(D7time2Date).format("HH:mm"),
          sh_brake_in: moment(D7time3Date).format("HH:mm"),
          sh_time_out: moment(D7time4Date).format("HH:mm"),
          bf_time_in: moment(timeBTDate).format("HH:mm"),
          lt_before: moment(timeBLTDate).format("HH:mm"),
          lt_time: moment(timeLTDate).format("HH:mm"),
          work_day: work7,
          work_hours: moment(D7time5Date).format("HH:mm"),
        },
      ],
    };
    let rsCreateUser = await axios.post(
      config.get("apiUrl") + "/api/master/shift/save",
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsCreateUser.data.status === "success") {
      handleShow();
      setTextMsg("บันทึกข้อมูลเรียบร้อย");
    } else {
      handleShow();
      setTextMsg("บันทึกข้อมูลไม่สำเร็จ");
    }
  };
  

  const onClickPaste1 = (e) => {
    setD1time1Date(timeDate);
    setD2time1Date(timeDate);
    setD3time1Date(timeDate);
    setD4time1Date(timeDate);
    setD5time1Date(timeDate);
    setD6time1Date(timeDate);
    setD7time1Date(timeDate);
  };

  const onClickPaste2 = (e) => {
    setD1time2Date(time2Date);
    setD2time2Date(time2Date);
    setD3time2Date(time2Date);
    setD4time2Date(time2Date);
    setD5time2Date(time2Date);
    setD6time2Date(time2Date);
    setD7time2Date(time2Date);
  };

  const onClickPaste3 = (e) => {
    setD1time3Date(time3Date);
    setD2time3Date(time3Date);
    setD3time3Date(time3Date);
    setD4time3Date(time3Date);
    setD5time3Date(time3Date);
    setD6time3Date(time3Date);
    setD7time3Date(time3Date);
  };

  const onClickPaste4 = (e) => {
    setD1time4Date(time4Date);
    setD2time4Date(time4Date);
    setD3time4Date(time4Date);
    setD4time4Date(time4Date);
    setD5time4Date(time4Date);
    setD6time4Date(time4Date);
    setD7time4Date(time4Date);
  };

  const onClickPaste5 = (e) => {
    setD1time5Date(time5Date);
    setD2time5Date(time5Date);
    setD3time5Date(time5Date);
    setD4time5Date(time5Date);
    setD5time5Date(time5Date);
    setD6time5Date(time5Date);
    setD7time5Date(time5Date);
  };

  const onClickSearch = async (e) => {
    setShiftId('');
    let q = shiftTemp;
    if(shiftSelect[0]){q=shiftSelect[0].shift_id;}
    let params = {
      criteria: {
        eq_shift_id : q,
      }
    };
    let rsSearch = await axios.post(
      config.get("apiUrl") + "/api/master/shift/find",params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsSearch.data.status === "success") {
      setShiftId(rsSearch.data.data.shift_id);
      setShiftName(rsSearch.data.data.shift_name);
      rsSearch.data.data.shift_days.map((item) => (
        defalutValueDisplay(item)
      ));
    } else {
      handleShow();
      setTextMsg("ไม่พบข้อมูล");
    }
  };

  const defalutValueDisplay = (data) => {
    if(data.day==="1")
    {
      setD1time1Date(new Date(moment("2020-01-01 "+data.sh_time_in).format("YYYY-MM-DD HH:mm")));
      setD1time2Date(new Date(moment("2020-01-01 "+data.sh_brake_out).format("YYYY-MM-DD HH:mm")));
      setD1time3Date(new Date(moment("2020-01-01 "+data.sh_brake_in).format("YYYY-MM-DD HH:mm")));
      setD1time4Date(new Date(moment("2020-01-01 "+data.sh_time_out).format("YYYY-MM-DD HH:mm")));
      setD1time5Date(new Date(moment("2020-01-01 "+data.work_hours).format("YYYY-MM-DD HH:mm")));
      setWork1(data.work_day);
      if(data.lt_before){setTimeBLTDate(new Date(moment("2020-01-01 "+data.lt_before).format("YYYY-MM-DD HH:mm")));}
      if(data.lt_time){setTimeLTDate(new Date(moment("2020-01-01 "+data.lt_time).format("YYYY-MM-DD HH:mm")));}
      if(data.bf_time_in){setTimeBTDate(new Date(moment("2020-01-01 "+data.bf_time_in).format("YYYY-MM-DD HH:mm")));}
    }

    if(data.day==="2")
    {
      setD2time1Date(new Date(moment("2020-01-01 "+data.sh_time_in).format("YYYY-MM-DD HH:mm")));
      setD2time2Date(new Date(moment("2020-01-01 "+data.sh_brake_out).format("YYYY-MM-DD HH:mm")));
      setD2time3Date(new Date(moment("2020-01-01 "+data.sh_brake_in).format("YYYY-MM-DD HH:mm")));
      setD2time4Date(new Date(moment("2020-01-01 "+data.sh_time_out).format("YYYY-MM-DD HH:mm")));
      setD2time5Date(new Date(moment("2020-01-01 "+data.work_hours).format("YYYY-MM-DD HH:mm")));
      setWork2(data.work_day);
    }

    if(data.day==="3")
    {
      setD3time1Date(new Date(moment("2020-01-01 "+data.sh_time_in).format("YYYY-MM-DD HH:mm")));
      setD3time2Date(new Date(moment("2020-01-01 "+data.sh_brake_out).format("YYYY-MM-DD HH:mm")));
      setD3time3Date(new Date(moment("2020-01-01 "+data.sh_brake_in).format("YYYY-MM-DD HH:mm")));
      setD3time4Date(new Date(moment("2020-01-01 "+data.sh_time_out).format("YYYY-MM-DD HH:mm")));
      setD3time5Date(new Date(moment("2020-01-01 "+data.work_hours).format("YYYY-MM-DD HH:mm")));
      setWork3(data.work_day);
    }

    if(data.day==="4")
    {
      setD4time1Date(new Date(moment("2020-01-01 "+data.sh_time_in).format("YYYY-MM-DD HH:mm")));
      setD4time2Date(new Date(moment("2020-01-01 "+data.sh_brake_out).format("YYYY-MM-DD HH:mm")));
      setD4time3Date(new Date(moment("2020-01-01 "+data.sh_brake_in).format("YYYY-MM-DD HH:mm")));
      setD4time4Date(new Date(moment("2020-01-01 "+data.sh_time_out).format("YYYY-MM-DD HH:mm")));
      setD4time5Date(new Date(moment("2020-01-01 "+data.work_hours).format("YYYY-MM-DD HH:mm")));
      setWork4(data.work_day);
    }

    if(data.day==="5")
    {
      setD5time1Date(new Date(moment("2020-01-01 "+data.sh_time_in).format("YYYY-MM-DD HH:mm")));
      setD5time2Date(new Date(moment("2020-01-01 "+data.sh_brake_out).format("YYYY-MM-DD HH:mm")));
      setD5time3Date(new Date(moment("2020-01-01 "+data.sh_brake_in).format("YYYY-MM-DD HH:mm")));
      setD5time4Date(new Date(moment("2020-01-01 "+data.sh_time_out).format("YYYY-MM-DD HH:mm")));
      setD5time5Date(new Date(moment("2020-01-01 "+data.work_hours).format("YYYY-MM-DD HH:mm")));
      setWork5(data.work_day);
    }

    if(data.day==="6")
    {
      setD6time1Date(new Date(moment("2020-01-01 "+data.sh_time_in).format("YYYY-MM-DD HH:mm")));
      setD6time2Date(new Date(moment("2020-01-01 "+data.sh_brake_out).format("YYYY-MM-DD HH:mm")));
      setD6time3Date(new Date(moment("2020-01-01 "+data.sh_brake_in).format("YYYY-MM-DD HH:mm")));
      setD6time4Date(new Date(moment("2020-01-01 "+data.sh_time_out).format("YYYY-MM-DD HH:mm")));
      setD6time5Date(new Date(moment("2020-01-01 "+data.work_hours).format("YYYY-MM-DD HH:mm")));
      setWork6(data.work_day);
    }

    if(data.day==="7")
    {
      setD7time1Date(new Date(moment("2020-01-01 "+data.sh_time_in).format("YYYY-MM-DD HH:mm")));
      setD7time2Date(new Date(moment("2020-01-01 "+data.sh_brake_out).format("YYYY-MM-DD HH:mm")));
      setD7time3Date(new Date(moment("2020-01-01 "+data.sh_brake_in).format("YYYY-MM-DD HH:mm")));
      setD7time4Date(new Date(moment("2020-01-01 "+data.sh_time_out).format("YYYY-MM-DD HH:mm")));
      setD7time5Date(new Date(moment("2020-01-01 "+data.work_hours).format("YYYY-MM-DD HH:mm")));
      setWork7(data.work_day);
      console.log(data.work_day);
    }
  }

  const deleteShift = async () => {
    handleDelClose();
    let rsDel = await axios.delete(
      config.get("apiUrl") + "/api/master/shift/"+shiftId,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    
    if (rsDel.data.status === "success") {
      handleShow();
      setTextMsg("ลบข้อมูลเรียบร้อย");
      //navigate.push("/admin-work-schedule");
      setShiftId('');
      setShiftName('');
      setWork1(0);
      setWork2(0);
      setWork3(0);
      setWork4(0);
      setWork5(0);
      setWork6(0);
      setWork7(0);
    } else {
      handleShow();
      setTextMsg("ลบข้อมูลไม่สำเร็จ");
    }
  }

  const filterBy = () => true;
  const [shiftList, setShiftList] = useState([]);
  const [shiftSelect, setShiftSelect] = useState('');
  const [shiftTemp, setShiftTemp] = useState('');
  const handleSearch = async (query) => {
    let params = {
      page_size: 100,
      current_page: 1,
      criteria: {
        like_shift_name: query,
      },
    };
    setShiftTemp(query);
    let rs = await axios.post(config.get("apiUrl") + "/api/master/shift/search",JSON.stringify(params),{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    if (rs.data.status === "success") {setShiftList(rs.data.data.records);}
  }
  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb"><i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" /><Link to="./" className="text-breadcrumb">หน้าหลัก</Link></li>
                  <li className="breadcrumb-item text-breadcrumb">ผู้ดูแลระบบ</li>
                  <li className="breadcrumb-item text-breadcrumb-active">ตารางเวลาทำงาน</li>
                </ol>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card text-normal box-radius elevation-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <span className="text-header-x2 ddc-color">ตารางเวลาทำงาน</span>
                          <br /> <br />
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">รหัส</span><span className="text-danger"> *</span></div>
                            <div className="col-12 col-md-2 pt-1">
                              <AsyncTypeahead 
                                filterBy={filterBy} 
                                id="basic-typeahead-single" 
                                labelKey="shift_id" 
                                onChange={setShiftSelect} 
                                options={shiftList} 
                                selected={shiftSelect} 
                                minLength={1} 
                                onSearch={handleSearch} 
                                renderMenuItemChildren={(option) => (<div>{option.shift_name}</div>)}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ชื่อ
                              </span>
                            </div>
                            <div className="col-12 col-md-2 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="shift_name"
                                ref={register}
                                value={shiftName}
                                onChange={(e) => setShiftName(e.target.value)}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-1">
                              <Button
                                type="button"
                                variant="contained"
                                className="btn-no-border btn-color-search"
                                onClick={(e) => onClickSearch(e)}
                              >
                                <i className="fas fa-search pr-2"></i>
                                ค้นหา
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <hr style={{ margin: "6px -10px 10px" }} />
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                ประเภทวัน
                              </span>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-9 col-md-6">
                                  <DatePicker
                                    selected={timeDate}
                                    onChange={(date) => setTimeDate(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                                <div className="col-3 col-md-2 mt-2">
                                  <i
                                    className="far fa-copy"
                                    onClick={(e) => onClickPaste1(e)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-9 col-md-6">
                                  <DatePicker
                                    selected={time2Date}
                                    onChange={(date) => setTime2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                                <div className="col-3 col-md-2 mt-2">
                                  <i
                                    className="far fa-copy"
                                    onClick={(e) => onClickPaste2(e)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-9 col-md-6">
                                  <DatePicker
                                    selected={time3Date}
                                    onChange={(date) => setTime3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                                <div className="col-3 col-md-2 mt-2">
                                  <i
                                    className="far fa-copy"
                                    onClick={(e) => onClickPaste3(e)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-9 col-md-6">
                                  <DatePicker
                                    selected={time4Date}
                                    onChange={(date) => setTime4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                                <div className="col-3 col-md-2 mt-2">
                                  <i
                                    className="far fa-copy"
                                    onClick={(e) => onClickPaste4(e)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-9 col-md-6">
                                  <DatePicker
                                    selected={time5Date}
                                    onChange={(date) => setTime5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                                <div className="col-3 col-md-2 mt-2">
                                  <i
                                    className="far fa-copy"
                                    onClick={(e) => onClickPaste5(e)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <span className="text-color-form align-middle">
                                    จันทร์
                                  </span>
                                </div>
                                <div className="col-9 col-md-8">
                                  <input
                                    type="radio"
                                    name="work1"
                                    onClick={() => setWork1(1)}
                                    checked={work1 === 1}
                                    onChange={()=>{}}
                                  />
                                  ทำงาน &nbsp;
                                  <input
                                    type="radio"
                                    name="work1"
                                    onClick={() => setWork1(0)}
                                    checked={work1 === 0}
                                    onChange={()=>{}}
                                  />
                                  หยุดงาน
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D1time1Date}
                                    onChange={(date) => setD1time1Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D1time2Date}
                                    onChange={(date) => setD1time2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D1time3Date}
                                    onChange={(date) => setD1time3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D1time4Date}
                                    onChange={(date) => setD1time4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D1time5Date}
                                    onChange={(date) => setD1time5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <span className="text-color-form align-middle">
                                    อังคาร
                                  </span>
                                </div>
                                <div className="col-9 col-md-8">
                                  <input
                                    type="radio"
                                    name="work2"
                                    onClick={() => setWork2(1)}
                                    checked={work2 === 1}
                                    onChange={()=>{}}
                                  />{" "}
                                  ทำงาน &nbsp;
                                  <input
                                    type="radio"
                                    name="work2"
                                    onClick={() => setWork2(0)}
                                    checked={work2 === 0}
                                    onChange={()=>{}}
                                  />{" "}
                                  หยุดงาน
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D2time1Date}
                                    onChange={(date) => setD2time1Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D2time2Date}
                                    onChange={(date) => setD2time2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D2time3Date}
                                    onChange={(date) => setD2time3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D2time4Date}
                                    onChange={(date) => setD2time4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D2time5Date}
                                    onChange={(date) => setD2time5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <span className="text-color-form align-middle">
                                    พุธ
                                  </span>
                                </div>
                                <div className="col-9 col-md-8">
                                  <input
                                    type="radio"
                                    name="work3"
                                    onClick={() => setWork3(1)}
                                    checked={work3 === 1}
                                    onChange={()=>{}}
                                  />
                                  ทำงาน &nbsp;
                                  <input
                                    type="radio"
                                    name="work3"
                                    onClick={() => setWork3(0)}
                                    checked={work3 === 0}
                                    onChange={()=>{}}
                                  />
                                  หยุดงาน
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D3time1Date}
                                    onChange={(date) => setD3time1Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D3time2Date}
                                    onChange={(date) => setD3time2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D3time3Date}
                                    onChange={(date) => setD3time3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D3time4Date}
                                    onChange={(date) => setD3time4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D3time5Date}
                                    onChange={(date) => setD3time5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <span className="text-color-form align-middle">
                                    พฤหัสบดี
                                  </span>
                                </div>
                                <div className="col-9 col-md-8">
                                  <input
                                    type="radio"
                                    name="work4"
                                    onClick={() => setWork4(1)}
                                    checked={work4 === 1}
                                    onChange={()=>{}}
                                  />
                                  ทำงาน &nbsp;
                                  <input
                                    type="radio"
                                    name="work4"
                                    onClick={() => setWork4(0)}
                                    checked={work4 === 0}
                                    onChange={()=>{}}
                                  />
                                  หยุดงาน
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D4time1Date}
                                    onChange={(date) => setD4time1Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D4time2Date}
                                    onChange={(date) => setD4time2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D4time3Date}
                                    onChange={(date) => setD4time3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D4time4Date}
                                    onChange={(date) => setD4time4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D4time5Date}
                                    onChange={(date) => setD4time5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <span className="text-color-form align-middle">
                                    ศุกร์
                                  </span>
                                </div>
                                <div className="col-9 col-md-8">
                                  <input
                                    type="radio"
                                    name="work5"
                                    onClick={() => setWork5(1)}
                                    checked={work5 === 1}
                                    onChange={()=>{}}
                                  />
                                  ทำงาน &nbsp;
                                  <input
                                    type="radio"
                                    name="work5"
                                    onClick={() => setWork5(0)}
                                    checked={work5 === 0}
                                    onChange={()=>{}}
                                  />
                                  หยุดงาน
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D5time1Date}
                                    onChange={(date) => setD5time1Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D5time2Date}
                                    onChange={(date) => setD5time2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D5time3Date}
                                    onChange={(date) => setD5time3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D5time4Date}
                                    onChange={(date) => setD5time4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D5time5Date}
                                    onChange={(date) => setD5time5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <span className="text-color-form align-middle">
                                    เสาร์
                                  </span>
                                </div>
                                <div className="col-9 col-md-8">
                                  <input
                                    type="radio"
                                    name="work6"
                                    onClick={() => setWork6(1)}
                                    checked={work6 === 1}
                                    onChange={()=>{}}
                                  />
                                  ทำงาน &nbsp;
                                  <input
                                    type="radio"
                                    name="work6"
                                    onClick={() => setWork6(0)}
                                    checked={work6 === 0}
                                    onChange={()=>{}}
                                  />
                                  หยุดงาน
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D6time1Date}
                                    onChange={(date) => setD6time1Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D6time2Date}
                                    onChange={(date) => setD6time2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D6time3Date}
                                    onChange={(date) => setD6time3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D6time4Date}
                                    onChange={(date) => setD6time4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D6time5Date}
                                    onChange={(date) => setD6time5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="row mt-3">
                            <div className="col-12 col-md-2 pt-2">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <span className="text-color-form align-middle">
                                    อาทิตย์
                                  </span>
                                </div>
                                <div className="col-9 col-md-8">
                                  <input
                                    type="radio"
                                    name="work7"
                                    onClick={() => setWork7(1)}
                                    checked={work7 === 1}
                                    onChange={()=>{}}
                                  />
                                  ทำงาน &nbsp;
                                  <input
                                    type="radio"
                                    name="work7"
                                    onClick={() => setWork7(0)}
                                    checked={work7 === 0}
                                    onChange={()=>{}}
                                  />
                                  หยุดงาน
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้า
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D7time1Date}
                                    onChange={(date) => setD7time1Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D7time2Date}
                                    onChange={(date) => setD7time2Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาเข้าพัก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D7time3Date}
                                    onChange={(date) => setD7time3Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    เวลาออก
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D7time4Date}
                                    onChange={(date) => setD7time4Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-2">
                              <div className="row">
                                <div className="col-12 col-md-4 pt-2">
                                  <span className="text-color-form align-middle">
                                    ชั่วโมงทำงาน
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <DatePicker
                                    selected={D7time5Date}
                                    onChange={(date) => setD7time5Date(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className="form-control shadow-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <hr style={{ margin: "6px -10px 10px" }} />
                      </div>
                      <div className="col-12 mt-3">
                        <div className="row">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              เข้างานก่อน
                            </span>
                          </div>
                          <div className="col-12 col-md-2">
                            <DatePicker
                              selected={timeBTDate}
                              onChange={(date) => setTimeBTDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              className="form-control shadow-none"
                            />
                          </div>
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              อนุโลมสาย
                            </span>
                          </div>
                          <div className="col-12 col-md-2">
                            <DatePicker
                              selected={timeBLTDate}
                              onChange={(date) => setTimeBLTDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              className="form-control shadow-none"
                            />
                          </div>
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              สาย
                            </span>
                          </div>
                          <div className="col-12 col-md-2">
                            <DatePicker
                              selected={timeLTDate}
                              onChange={(date) => setTimeLTDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              className="form-control shadow-none"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1 p-2">
                      <div className="col-12 col-md-2 pt-3 mt-2">
                        <Button type="submit" variant="contained" className="btn-no-border btn-color-add btn-block"><i className="fas fa-plus pr-2"></i> เพิ่ม</Button>
                      </div>
                      <div className="col-12 col-md-2 pt-3 mt-2">
                        <Button type="submit" variant="contained" className="btn-no-border btn-color-submit2 btn-block"><i className="far fa-save pr-2"></i> บันทึก</Button>
                      </div>
                      <div className="col-12 col-md-6 pt-3 mt-2"></div>
                      <div className="col-12 col-md-2 pt-3 mt-2">
                        <Button type="button" variant="contained" className="btn-no-border btn-color-delete btn-block" onClick={handleDelShow}><i className="fas fa-trash-alt pr-2"></i> ลบ</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </form>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>
      <Modal show={showDelMsg} onHide={handleDelClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">คุณต้องการลบข้อมูลใช่หรือไม่</Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="contained" className="btn-no-border btn-color-submit-small mr-2" onClick={deleteShift}>ใช่</Button> 
          <Button type="button" variant="contained" className="btn-no-border btn-color-delete-small" onClick={handleDelClose}>ไม่ใช่</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WorkSchedule;
