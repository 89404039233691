/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import { WatDatePicker } from 'thaidatepicker-react'
import moment from "moment";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import th from "date-fns/locale/th";
registerLocale("th", th);

const SupervisorApproveLeave = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit, reset } = useForm();
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(), 
    new Date().getDate()
  );
  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);
  const [leaveType, setLeaveType] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [empName, setEmpName] = useState('');
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const fetchData = async () => {
    let rsLeaveType = await axios.get(config.get("apiUrl") + "/api/master/leave/user", { headers: { "Content-Type": "application/json", Authorization: `bearer ${keycloak.token}`,},});
    setLeaveType(rsLeaveType.data.data.records);
  };
  useEffect(() => {
    dateChange();
    fetchData();
  }, []);

  const dateChange = async () => {
    $(".start_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true })
      .datepicker('setDate', new Date()).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(), 
          minDate.getDate()
        );
        var eDate = new Date($('.end_date').datepicker('getDate'));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(), 
          eDate.getDate()
        );
        $('.end_date').datepicker('setStartDate', minDate);
        $('.end_date').datepicker('setDate', eDate<minDate?minDate:eDate);
        setStartDate($('.start_date').val());
      }
    );
    $(".end_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true ,}).datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).on('changeDate', function (selected) {setEndDate($('.end_date').val());});
  }

  const onSubmit = async (data) => {
    //reset({});
    setEmpName('');
    setDataList([]);
    setEmpName(empSelect[0]?empSelect[0].fname+ " "+empSelect[0].lname:"");
    
    let params = {
          page_size : 100,
          current_page : 1,
          criteria : {
            like_document_id:empSelect[0]?empSelect[0].employee_id:"",
            eq_leave_id:data.leave_id,
            like_fname:"",
            like_lname:"",
            from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
            to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
            approvable:"",
          },
          order_by: [
            {
              "column_name": "from_date",
              "direction": "desc"
            }
          ],
        };
    try {
      let rsData = await axios.post(config.get("apiUrl") + "/api/leave-form/search-by-boss",params,{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
      if (rsData.data.status === "success") {
        //setEmpName(rsData.data.data.records[0].fname+' '+rsData.data.data.records[0].lname);
        /*const result = [];
        const map = new Map();
        for (const item of rsData.data.data.records) {
            if(!map.has(item.document_id)){
                map.set(item.document_id, true);    // set any value to Map
                result.push(item);
            }
        }*/
        setDataList(rsData.data.data.records);
      } else {
        handleShow();
        setTextMsg(rsData.data.message_th);
      }
    }
    catch {
      handleShow();
      setTextMsg("ไม่พบข้อมูล");
    }
    
  };

  const process = async (doc, docStatus) => {
    let params = {
      
    };
    try {await axios.post(config.get("apiUrl") + "/api/leave-form/"+docStatus+"/"+doc,params, { headers: { "Content-Type": "application/json", Authorization: `bearer ${keycloak.token}`,},})} catch (error) {}
    handleShow();
    setDataList([]);
    setTextMsg("บันทึกสำเร็จ");
  };

  const [loading, setLoading] = useState(false);
  const filterBy = () => true;
  const [empList, setEmpList] = useState([]);
  const [empSelect, setEmpSelect] = useState('');
  const [empTemp, setEmpTemp] = useState('');
  const handleSearch = async (query) => {
    setLoading(true);
    let params = {
      page_size: 10,
      current_page: 1,
      criteria: {
        like_employee_id: "",
        like_fname:query,
        like_lname:"",
        self_org:"1",
      },order_by:[{column_name:"fname",direction:"asc"}]
    };
    setEmpTemp(query);
    let rsDT = await axios.post(config.get("apiUrl") + "/api/employee/get-by-boss",JSON.stringify(params),{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    setEmpList(rsDT.data.data.records);
    setLoading(false);
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb"><i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" /><Link to="./" className="text-breadcrumb">หน้าหลัก</Link></li>
                  <li className="breadcrumb-item text-breadcrumb">ผู้บังคับบัญชา</li>
                  <li className="breadcrumb-item text-breadcrumb-active">อนุมัติการลา</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">อนุมัติการลา</span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">รหัสพนักงาน</span></div>
                          <div className="col-12 col-md-2 pt-1"><AsyncTypeahead 
                              filterBy={filterBy} 
                              id="basic-typeahead-single" 
                              labelKey="employee_id" 
                              onChange={setEmpSelect} 
                              options={empList} 
                              selected={empSelect} 
                              minLength={3} 
                              onSearch={handleSearch} 
                              renderMenuItemChildren={(option) => (<div>{option.employee_id} <small>({option.fname} {option.lname})</small></div>)}
                            /></div>
                          <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">ชื่อ - สกุล</span></div>
                          <div className="col-12 col-md-2 pt-1"><input className="form-control shadow-none" type="text" defaultValue={empName}/></div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">ประเภทการลา</span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <select className="form-control shadow-none">
                              <option>ทั้งหมด</option>
                              <option>ลาป่วย</option>
                              <option>ลากิจ</option>
                              <option>ลาพักผ่อน</option>
                            </select>
                          </div>
                          <div className="col-12 col-md-1 pt-2"><span className="text-color-form align-middle">ช่วงวันที่</span>
                          </div>
                          <div className="col-12 col-md-4 pt-1">
                            <div className="row">
                              <div className="col-5">
                              <div className="form-control-wrapper form-control-icon-right">
                                    <input type="text" defaultValue={startDate} name="start_date"  data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none" />
                                    <i className="far fa-calendar-alt"></i>
                                  </div>      
                              </div>
                              <div className="col-2 text-center mt-2">ถึง</div>
                              <div className="col-5">
                              <div className="form-control-wrapper form-control-icon-right">
                                      <input type="text" defaultValue={endDate} name="end_date" data-date-format="dd/MM/yyyy" className="datepicker end_date form-control shadow-none" />
                                      <i className="far fa-calendar-alt"></i>
                                    </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <Button
                           type="button"
                           onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            className="btn-no-border btn-color-search"
                          >
                            <i className="fas fa-search pr-2"></i>
                            ค้นหา
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-head-fixed">
                          <thead>
                            <tr className="table-ddc">
                              <th className="table-ddc text-center">เลขที่การลา</th>
                              <th className="table-ddc text-center">ชื่อ - สกุล</th>
                              <th className="table-ddc text-center">ประเภทการลา</th>
                              <th className="table-ddc text-center">วันที่ลา</th>
                              <th className="table-ddc text-center" colSpan="2">อนุมัติ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataList.map((item) => (
                              <tr key={item.document_id}>
                                <td className="text-center">{item.document_id}</td>
                                <td>{item.fname} {item.lname}</td>
                                <td className="text-center">{item.leave_text}</td>
                                <td className="text-center">{item.from_date} - {item.to_date}</td>
                                <td className="text-center">{item.approvable === "1" ? (
                                <>
                                  <Button type="button" variant="contained" className="btn-no-border btn-color-add-small" onClick={(e)=>process(item.document_id, 'approve')}>อนุมัติ</Button>
                                </>
                                ):item.status_text}</td>
                                <td className="text-center">{item.approvable === "1" ? (
                                <>
                                  <Button type="button" variant="contained" className="btn-no-border btn-color-delete-small"  onClick={(e)=>process(item.document_id, 'reject')}>ไม่อนุมัติ</Button>
                                </>
                                ):""}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>
    </div>
  );
};

export default SupervisorApproveLeave;
