/* eslint-disable */
import React, { useState, useEffect } from "react";
import config from "react-global-configuration";
import { useForm } from "react-hook-form";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { useKeycloak } from "@react-keycloak/web";
import { WatDatePicker } from 'thaidatepicker-react'
import { Button, TextField } from "@material-ui/core";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; 
import th from "date-fns/locale/th";
registerLocale("th", th);

const ReportLeaveDetail = () => {
  const [keycloak] = useKeycloak();
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(), 
    new Date().getDate()
  );
  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);
  const { register, handleSubmit, reset } = useForm();
  const [empType, setEmpType] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const fetchData = async () => {
    let rsEmpType = await axios.get(config.get("apiUrl") + "/api/master/emp-type",{headers: {"Content-Type": "application/x-www-form-urlencoded",Authorization: `bearer ${keycloak.token}`,},});
    setEmpType(rsEmpType.data.data.records);

    let rsLeaveType = await axios.get(config.get("apiUrl") + "/api/master/leave/user", { headers: { "Content-Type": "application/json", Authorization: `bearer ${keycloak.token}`,},});
    setLeaveType(rsLeaveType.data.data.records);
  };
  useEffect(() => {
    dateChange();
    fetchData();
  }, []);

  const dateChange = async () => {
    $(".start_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true })
      .datepicker('setDate', new Date()).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(), 
          minDate.getDate()
        );
        var eDate = new Date($('.end_date').datepicker('getDate'));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(), 
          eDate.getDate()
        );
        $('.end_date').datepicker('setStartDate', minDate);
        $('.end_date').datepicker('setDate', eDate<minDate?minDate:eDate);
        setStartDate($('.start_date').val());
      }
    );
    $(".end_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true ,}).datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).on('changeDate', function (selected) {setEndDate($('.end_date').val());});
  }

  const onSubmit = async (data) => {
    let q = empTemp;
    console.log(empSelect);
    if(empSelect[0]){q=empSelect[0].employee_id;}
    let params = "?employee_id="+q+"&emp_type_id="+data.emp_type_id+"&leave_id="+data.leave_id+"&status="+data.status+"&from_date="+moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD")+"&to_date="+moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD");
    //let rsExport = await axios.get(config.get("apiUrl") + "/api/export-excel/report03"+params,{ responseType: 'blob',headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    await axios.get(config.get("apiUrl") + "/api/export-excel/report03"+params,
    {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${keycloak.token}`
        }
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
    .catch((error) => console.log(error));
  };

  const filterBy = () => true;
  const [empList, setEmpList] = useState([]);
  const [empSelect, setEmpSelect] = useState('');
  const [empTemp, setEmpTemp] = useState('');
  const handleSearch = async (query) => {
    let params = {
      page_size: 10,
      current_page: 1,
      criteria: {
        like_employee_id: query,
        like_fname:query,
        like_lname:query,
      },
    };
    setEmpTemp(query);
    let rs = await axios.post(config.get("apiUrl") + "/api/employee/search",JSON.stringify(params),{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    if (rs.data.status === "success") {setEmpList(rs.data.data.records);}
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">รายงาน</li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                  รายงานการลาของบุคลากรรายบุคคล

                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                        รายงานการลาของบุคลากรรายบุคคล

                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              รหัสพนักงาน
                            </span>
                          </div>
                          <div className="col-8 col-md-2 pt-1">
                           <AsyncTypeahead 
                              filterBy={filterBy} 
                              id="basic-typeahead-single" 
                              labelKey="employee_id" 
                              onChange={setEmpSelect} 
                              options={empList} 
                              selected={empSelect} 
                              minLength={3} 
                              onSearch={handleSearch} 
                              renderMenuItemChildren={(option) => (<div>{option.employee_id} <small>({option.fname} {option.lname})</small></div>)}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ประเภทบุคลากร
                            </span>
                          </div>
                          <div className="col-8 col-md-2 pt-1">
                            <select className="form-control shadow-none" name="emp_type_id" ref={register}>
                              <option value="">ทั้งหมด</option>
                              {empType.map((item) => (
                                <option key={item.emp_type_id} value={item.emp_type_id}>
                                  {item.emp_type_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        
                        <div className="row mt-3">
                        <div className="col-12 col-md-1 pt-2">
                          <span className="text-color-form align-middle">
                            ประเภทการลา
                          </span>
                        </div>
                        <div className="col-12 col-md-2 pt-1">
                          <select className="form-control shadow-none" name="leave_id" ref={register}>
                            <option value="">ทั้งหมด</option>
                            {leaveType.map((item) => (
                              <option key={item.leave_id} value={item.leave_id}>
                                {item.leave_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-md-2 pt-2">
                          <span className="text-color-form align-middle">
                            สถานะการอนุมัติการลา
                          </span>
                        </div>
                        <div className="col-12 col-md-2 pt-1">
                          <select className="form-control shadow-none" name="status" ref={register}>
                            <option value="">ทั้งหมด</option>
                            <option value="1">อนุมัติ</option>
                            <option value="0">ไม่อนุมัติ</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-1 pt-2">
                          <span className="text-color-form align-middle">
                            ช่วงวันที่
                          </span>
                        </div>
                        <div className="col-12 col-md-4 pt-1">
                          <div className="row">
                            <div className="col-5">
                            <div className="form-control-wrapper form-control-icon-right">
                                    <input type="text" defaultValue={startDate} name="start_date"  data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none" />
                                    <i className="far fa-calendar-alt"></i>
                                  </div>             
                            </div>
                            <div className="col-2 text-center mt-2">ถึง</div>
                            <div className="col-5">
                            <div className="form-control-wrapper form-control-icon-right">
                                      <input type="text" defaultValue={endDate} name="end_date" data-date-format="dd/MM/yyyy" className="datepicker end_date form-control shadow-none" />
                                      <i className="far fa-calendar-alt"></i>
                                    </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-no-border btn-color-search"
                            onClick={handleSubmit(onSubmit)}
                          >
                            <i className="fas fa-file-export pr-2"></i>
                            ส่งออกข้อมูล
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default ReportLeaveDetail;
