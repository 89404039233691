/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import DataTable from "react-data-table-component";
import config from "react-global-configuration";
import { useKeycloak } from "@react-keycloak/web";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; 
import moment from "moment";

const LeaveType = () => {
  const [keycloak] = useKeycloak();
  const navigate = useHistory();
  const [useBrake, setUseBrake] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortCol, setSortCol] = useState("holidayNumber");
  const [sortDir, setSortDir] = useState("asc");
  const [filter, setFilter] = useState("");
  const { register, handleSubmit } = useForm();

  const [gIdDel, setGIdDel] = useState("");
  const [idDel, setIdDel] = useState("");

  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");
  const [showDelMsg, setShowDelMsg] = useState(false);
  const handleDelClose = () => setShowDelMsg(false);
  const handleDelShow = () => { setShowDelMsg(true);}

  const [empType, setEmpType] = useState([]);
  const [empTypeID, setEmpTypeID] = useState("");
  const [leaveId, setLeaveID] = useState("");
  const [leaveName, setLeaveName] = useState("");
  const [salaryEffectAmount, setSalaryEffectAmount] = useState("");
  const [leaveAmount, setLeaveAmount] = useState("");
  const [collect, setCollect] = useState(0);
  const [dateType, setDateType] = useState("");
  const [fullDay, setFullDay] = useState(0);
  const [haftDay, setHaftDay] = useState(0);
  const [leaveBefore, setLeaveBefore] = useState("");
  const [leaveAfter, setLeaveAfter] = useState("");
  const [sex, setSex] = useState(0);
  const [credentialsReq, setCredentialsReq] = useState(0);
  const [credentialsDay, setCredentialsDay] = useState("");

  const fetchData = async () => {
    let rsEmpType = await axios.get(
      config.get("apiUrl") + "/api/master/emp-type",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setEmpType(rsEmpType.data.data.records);
  };

  const onSubmit = async (data) => {
    let q=leaveTemp;
    if(leaveId !== ""){q=leaveId;}
    let params = {
      emp_type_id: data.eq_emp_type_id,
      leave_id: q,
      leave_name: data.leave_name,
      salary_effect_amount: data.salary_effect_amount,
      leave_amount: data.leave_amount,
      collect: data.collect,
      date_type: data.date_type,
      full_day: (data.full_day === true ? 1 : 0),
      haft_day: (data.haft_day === true ? 1 : 0),
      leave_before: data.leave_before,
      leave_after: data.leave_after,
      sex: data.sex,
      credentials_req: data.credentials_req,
      credentials_day: data.credentials_day,
    };
    let rsSave = await axios.post(
      config.get("apiUrl") + "/api/master/leave/save",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsSave.data.status === "success") {
      handleShow();
      setTextMsg("บันทึกข้อมูลเรียบร้อย");
    } else {
      handleShow();
      setTextMsg("บันทึกข้อมูลไม่สำเร็จ");
    }
  };

  const onClickSearch = async (e) => {
    setLeaveID('');
    let q = leaveTemp;
    if(leaveSelect[0]){q=leaveSelect[0].leave_id;}
    let params = {
      criteria: {
        eq_emp_type_id: empTypeID,
        eq_leave_id: q,
      },
    };
    console.log(params);
    let rsSearch = await axios.post(
      config.get("apiUrl") + "/api/master/leave/find",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsSearch.data.status === "success") {
      setLeaveID(rsSearch.data.data.leave_id);
      setLeaveName(rsSearch.data.data.leave_name);
      setSalaryEffectAmount(rsSearch.data.data.salary_effect_amount);
      setLeaveAmount(rsSearch.data.data.leave_amount);
      setCollect(rsSearch.data.data.collect);
      setDateType(rsSearch.data.data.date_type);
      setFullDay(rsSearch.data.data.full_day);
      setHaftDay(rsSearch.data.data.haft_day);
      setLeaveBefore(rsSearch.data.data.leave_before);
      setLeaveAfter(rsSearch.data.data.leave_after);
      setSex(rsSearch.data.data.sex);
      setCredentialsReq(rsSearch.data.data.credentials_req);
      setCredentialsDay(rsSearch.data.data.credentials_day);
    } else {
      handleShow();
      setTextMsg("ไม่พบข้อมูล");
    }
  };

  useEffect(() => {
    fetchData();
  }, [useBrake]);

  const onClickDel = async (e) => {
    handleDelClose();
    let rs = await axios.delete(
      config.get("apiUrl") + "/api/master/leave/"+empTypeID+"/"+leaveId,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rs.data.status === "success") {
      handleShow();
      setTextMsg("ลบข้อมูลเรียบร้อย");
      navigate.push("/admin-leave-type");
    } else {
      handleShow();
      setTextMsg("ลบข้อมูลไม่สำเร็จ");
    }
  };

  const filterBy = () => true;
  const [leaveList, setLeaveList] = useState([]);
  const [leaveSelect, setLeaveSelect] = useState('');
  const [leaveTemp, setLeaveTemp] = useState('');
  const handleSearch = async (query) => {
    let params = {
      page_size: 100,
      current_page: 1,
      criteria: {
        emp_type_id: empTypeID,
        like_leave_name: query
      },
    };
    setLeaveTemp(query);
    let rs = await axios.post(config.get("apiUrl") + "/api/master/leave/search",JSON.stringify(params),{headers: {"Content-Type": "application/json",Authorization: `bearer ${keycloak.token}`,},});
    if (rs.data.status === "success") {setLeaveList(rs.data.data.records);}
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    ผู้ดูแลระบบ
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ประเภทการลา
                  </li>
                </ol>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card text-normal box-radius elevation-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <span className="text-header-x2 ddc-color">
                            ประเภทการลา
                          </span>
                          <br /> <br />
                          <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ประเภทบุคลากร
                              </span>
                              <span className="text-danger"> *</span>
                            </div>
                            <div className="col-9 col-md-2 pt-1">
                              <select
                                className="form-control shadow-none"
                                name="eq_emp_type_id"
                                ref={register}
                                onChange={(e) => setEmpTypeID(e.target.value)}
                              >
                                <option value="">กรุณาเลือก</option>
                                {empType.map((item) => (
                                  <option
                                    key={item.emp_type_id}
                                    value={item.emp_type_id}
                                  >
                                    {item.emp_type_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                รหัสการลา
                              </span>
                            </div>
                            <div className="col-9 col-md-2 pt-1">
                              <AsyncTypeahead 
                                filterBy={filterBy} 
                                id="basic-typeahead-single" 
                                labelKey="leave_id" 
                                onChange={setLeaveSelect} 
                                options={leaveList} 
                                selected={leaveSelect} 
                                minLength={2} 
                                onSearch={handleSearch} 
                                renderMenuItemChildren={(option) => (<div>{option.leave_name}</div>)}
                              />
                            </div>
                            <div className="col-3 col-md-2">
                              <Button
                                type="button"
                                variant="contained"
                                size="large"
                                className="btn-no-border btn-color-search"
                                onClick={(e) => onClickSearch(e)}
                              >
                                <i className="fas fa-search pr-2"></i>
                                ค้นหา
                              </Button>
                            </div>
                            
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ชื่อ
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="leave_name"
                                onChange={(e) => setLeaveName(e.target.value)}
                                ref={register}
                                value={leaveName}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                จำนวนวันตามสิทธิ
                              </span>
                            </div>
                            <div className="col-12 col-md-2 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="leave_amount"
                                onChange={(e) => setLeaveAmount(e.target.value)}
                                ref={register}
                                value={leaveAmount}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                วัน
                              </span>
                            </div>
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                จำนวนวันที่มีผลต่อการเลื่อนเงินเดือน
                              </span>
                            </div>
                            <div className="col-12 col-md-2 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="salary_effect_amount"
                                onChange={(e) =>
                                  setSalaryEffectAmount(e.target.value)
                                }
                                ref={register}
                                value={salaryEffectAmount}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                วัน
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                สะสมสิทธิจากปีก่อน
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-2">
                              <input
                                type="radio"
                                name="collect"
                                onClick={(e) => setCollect(1)}
                                defaultChecked={collect === 1}
                                ref={register}
                                value={1}
                              />
                              สะสม &nbsp;
                              <input
                                type="radio"
                                name="collect"
                                onClick={() => setCollect(0)}
                                defaultChecked={collect === 0}
                                ref={register}
                                value={0}
                              />
                              ไม่สะสม
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ประเภทการลา
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-2">
                              <input
                                type="checkbox"
                                name="full_day"
                                onClick={(e) => fullDay===1 ? setFullDay(0) : setFullDay(1)}
                                checked={fullDay === 1}
                                ref={register}
                              />
                              เต็มวัน &nbsp;
                              <input
                                type="checkbox"
                                name="haft_day"
                                onClick={(e) => haftDay===1 ? setHaftDay(0) : setHaftDay(1)}
                                checked={haftDay === 1}
                                ref={register}
                              />
                              ครึ่งวัน
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                การนับวันลา
                              </span>
                            </div>
                            <div className="col-9 col-md-2 pt-1">
                              <select className="form-control shadow-none" ref={register} name="date_type">
                                <option value="0">
                                  ลาหยุด (ไม่รวม เสาร์-อาทิตย์)
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ต้องลาล่วงหน้า
                              </span>
                            </div>
                            <div className="col-12 col-md-1 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="leave_before"
                                onChange={(e) => setLeaveBefore(e.target.value)}
                                ref={register}
                                value={leaveBefore}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                วัน
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ลาย้อนหลังไม่เกิน
                              </span>
                            </div>
                            <div className="col-12 col-md-1 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="leave_after"
                                onChange={(e) => setLeaveAfter(e.target.value)}
                                ref={register}
                                value={leaveAfter}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                วัน
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                เพศ
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-2">
                              <input
                                type="radio"
                                name="sex"
                                onClick={() => setSex(1)}
                                defaultChecked={collect === 1}
                                ref={register}
                                value={1}
                              />
                              ชาย &nbsp;
                              <input
                                type="radio"
                                name="sex"
                                onClick={() => setSex(2)}
                                defaultChecked={collect === 2}
                                ref={register}
                                value={2}
                              />
                              หญิง &nbsp;
                              <input
                                type="radio"
                                name="sex"
                                onClick={() => setSex(0)}
                                defaultChecked={sex === 0}
                                ref={register}
                                value={0}
                              />
                              ไม่ระบุ
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ต้องการหนังสือรับรอง
                              </span>
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <input
                                type="radio"
                                name="credentials_req"
                                onClick={() => setCredentialsReq(1)}
                                defaultChecked={credentialsReq === 1}
                                ref={register}
                                value={1}
                              />
                              ใช่ &nbsp;
                              <input
                                type="radio"
                                name="credentials_req"
                                onClick={() => setCredentialsReq(0)}
                                defaultChecked={credentialsReq === 0}
                                ref={register}
                                value={0}
                              />
                              ไม่
                            </div>
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                จำนวนวันที่ต้องการหนังสือรับรอง
                              </span>
                            </div>
                            <div className="col-12 col-md-2 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="credentials_day"
                                onChange={(e) => setCredentialsDay(e.target.value)}
                                ref={register}
                                value={credentialsDay}
                              />
                            </div>
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                วัน
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <hr style={{ margin: "6px" }} />

                      <div className="row mt-1">
                        <div className="col-12 col-md-2 pt-3 mt-2">
                          <Button
                            type="submit"
                            variant="contained"
                            className="btn-no-border btn-color-add btn-block"
                          >
                            <i className="fas fa-plus pr-2"></i>
                            เพิ่ม
                          </Button>
                        </div>
                        <div className="col-12 col-md-2 pt-3 mt-2">
                          <Button
                            type="submit"
                            variant="contained"
                            className="btn-no-border btn-color-submit2 btn-block"
                          >
                            <i className="far fa-save pr-2"></i>
                            บันทึก
                          </Button>
                        </div>
                        <div className="col-12 col-md-6 pt-3 mt-2"></div>
                        <div className="col-12 col-md-2 pt-3 mt-2">
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-no-border btn-color-delete btn-block"
                            onClick={handleDelShow}
                          >
                            <i className="fas fa-trash-alt pr-2"></i>
                            ลบ
                          </Button>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>

      <Modal show={showDelMsg} onHide={handleDelClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">คุณต้องการลบข้อมูลใช่หรือไม่</Modal.Body>
        <Modal.Footer>
        <Button
                                type="button"
                                variant="contained"
                                className="btn-no-border btn-color-submit-small mr-2"
                                onClick={(e) => onClickDel(e)}
                              >
                              
                                ใช่
                              </Button>

                              <Button
                                type="button"
                                variant="contained"
                                className="btn-no-border btn-color-delete-small"
                                onClick={handleDelClose}
                              >
                                ไม่ใช่
                              </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LeaveType;
