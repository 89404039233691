/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import DatePicker, { registerLocale } from "react-datepicker";
import axios from "axios";
import config from "react-global-configuration";
import moment from "moment";
import DataTable from "react-data-table-component";
import { WatDatePicker } from 'thaidatepicker-react'
import { Button, TextField } from "@material-ui/core";
import th from "date-fns/locale/th";
registerLocale("th", th);

const RecoredList = () => {
  const [keycloak] = useKeycloak();
  const navigate = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortCol, setSortCol] = useState("employeeNumber");
  const [sortDir, setSortDir] = useState("asc");
  const [filter, setFilter] = useState("");
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(), 
    new Date().getDate()
  );
  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);
  const [documentID, setDocumentID] = useState("");
  
  const columns = [
    {
      name: "เลขที่ขออนุญาต",
      selector: "document_id",
      sortable: true,
    },
    {
      name: "วัตถุประสงค์",
      selector: "description",
      sortable: true,
    },
    {
      name: "วันที่ทำงานนอกสถานที่",
      selector: "from_date",
      sortable: true,
    },
    {
      name: "สถานะ",
      selector: "status_text",
      sortable: true,
    },
  ];

  const fetchTable = async (page) => {
    setLoading(true);
    let params = {
      page_size: 20,
      current_page: page,
      criteria: {
        like_document_id: "",
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
      order_by: [
        {
          "column_name": "from_date",
          "direction": "desc"
        }
      ],
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/check-time/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setData(response.data.data.records);
    setTotalRows(response.data.total_records);
    setLoading(false);
  };

  const handleSort = async (column, sortDirection) => {
    setLoading(true);
    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        like_document_id: "",
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/check-time/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setData(response.data.data.records);
    setSortCol(column.selector);
    setSortDir(sortDirection);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchTable(page);
  };

  const handleFilter = async (filterLocal) => {
    setLoading(true);

    let params = {
      page_size: 20,
      current_page: page,
      criteria: {
        like_document_id: "",
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
      order_by: [
        {
          "column_name": "from_date",
          "direction": "desc"
        }
      ],
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/check-time/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );

    setData(response.data.data.records);
    setTotalRows(response.data.total_records);
    setFilter(filterLocal);
    setLoading(false);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    let params = {
      page_size: 20,
      current_page: page,
      criteria: {
        like_document_id: "",
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
      order_by: [
        {
          "column_name": "from_date",
          "direction": "desc"
        }
      ],
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/check-time/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setData(response.data.data.records);
    setTotalRows(response.data.total_records);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    $('.datepicker').datepicker({
      format: 'dd/mm/yyyy',
      todayBtn: false,
      language: 'th',
      thaiyear: true 
    }).datepicker("setDate", "0");
    $( ".start_date" ).change(function() {
      setStartDate($('.start_date').val());
    });
    $( ".end_date" ).change(function() {
      setEndDate($('.end_date').val());
    });
    dateChange();   
    fetchTable(1);
  }, []);

  const dateChange = async () => {
    $(".start_date").datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true })
      .datepicker('setDate', new Date()).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(), 
          minDate.getDate()
        );
        var eDate = new Date($('.end_date').datepicker('getDate'));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(), 
          eDate.getDate()
        );
        $('.end_date').datepicker('setStartDate', minDate);
        $('.end_date').datepicker('setDate', eDate<minDate?minDate:eDate);
      }
    );
    $(".end_date").datepicker('setStartDate', new Date()).datepicker('setDate', new Date()).datepicker({todayBtn:  false,autoclose: true,format: 'dd/mm/yyyy',language: 'th',thaiyear: true ,}).on('changeDate', function (selected) {});
  }

  const onClickSearch = async (e) => {
    setLoading(true);
    let params = {
      page_size: 20,
      current_page: 1,
      criteria: {
        like_document_id: documentID,
        from_date: moment(startDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
        to_date: moment(endDate,'DD/MM/YYYY').add(-543, 'year').format("YYYY-MM-DD"),
      },
      order_by: [
        {
          "column_name": "from_date",
          "direction": "desc"
        }
      ],
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/check-time/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setData(response.data.data.records);
    setTotalRows(response.data.total_records);
    setLoading(false);
  };

  


  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    บันทึกเวลาทำงาน
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ตรวจสอบขอทำงานนอกสถานที่
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius elevation-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                          ตรวจสอบขอทำงานนอกสถานที่
                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              เลขที่ขออนุญาต
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name="document_id"
                              onChange={(e) => setDocumentID(e.target.value)}
                            />
                          </div>
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              วันที่ทำงานนอกสถานที่
                            </span>
                          </div>
                          <div className="col-12 col-md-4 pt-1">
                                <div className="row">
                                  <div className="col-4">
                                  <div className="form-control-wrapper form-control-icon-right">
                                    <input type="text" defaultValue={startDate} name="start_date"  data-date-format="dd/MM/yyyy" className="datepicker start_date form-control shadow-none" />
                                    <i className="far fa-calendar-alt"></i>
                                  </div>
                                  </div>
                                  <div className="col-1 pt-2">ถึง</div>
                                  <div className="col-4">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <input type="text" defaultValue={endDate} name="end_date" data-date-format="dd/MM/yyyy" className="datepicker end_date form-control shadow-none" />
                                      <i className="far fa-calendar-alt"></i>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="pl-3">
                            <Button
                              type="button"
                              variant="contained"
                              size="large"
                              className="btn-no-border btn-color-search"
                              onClick={(e) => onClickSearch(e)}
                            >
                              <i className="fas fa-search pr-2"></i>
                              ค้นหา
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <DataTable
                          columns={columns}
                          data={data}
                          onSort={handleSort}
                          sortServer
                          defaultSortField="document_id"
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          persistTableHead
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default RecoredList;
