/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import moment from "moment";


const LeaveRight = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit } = useForm();
  const [useBrake, setUseBrake] = useState(0);

  const [dt, setDt] = useState([]);

  var currnentDate = moment(new Date()).format("MM")==="10" || moment(new Date()).format("MM")==="11" || moment(new Date()).format("MM")==="12" ? nextThreeMonth : new Date();
      
  const [startDate, setStartDate] = useState(moment(currnentDate).format("YYYY"));
  const [endDate, setEndDate] = useState(new Date());

  const records_default = [
                  {
                    "employee_id": "",
                    "fname": "",
                    "lname": "",
                    "year": moment(currnentDate).format("YYYY"),
                    "leave_id": 1,
                    "leave_text": "ลาป่วย",
                    "collect": 0,
                    "right": 0,
                    "right_salary_effect": 0,
                    "leave": 0,
                    "remain": 0
                },
                {
                    "employee_id": "",
                    "fname": "",
                    "lname": "",
                    "year": moment(currnentDate).format("YYYY"),
                    "leave_id": 2,
                    "leave_text": "ลากิจ",
                    "collect": 0,
                    "right": 0,
                    "right_salary_effect": 0,
                    "leave": 0,
                    "remain": 0
                },
                {
                    "employee_id": "",
                    "fname": "",
                    "lname": "",
                    "year": moment(currnentDate).format("YYYY"),
                    "leave_id": 3,
                    "leave_text": "ลาพักผ่อน",
                    "collect": 0,
                    "right": 0,
                    "right_salary_effect": 0,
                    "leave": 0,
                    "remain": 0
                },
                {
                    "employee_id": "",
                    "fname": "",
                    "lname": "",
                    "year": (startDate-1),
                    "leave_id": 1,
                    "leave_text": "ลาป่วย",
                    "collect": 0,
                    "right": 0,
                    "right_salary_effect": 0,
                    "leave": 0,
                    "remain": 0
                },
                {
                    "employee_id": "",
                    "fname": "",
                    "lname": "",
                    "year": (startDate-1),
                    "leave_id": 2,
                    "leave_text": "ลากิจ",
                    "collect": 0,
                    "right": 0,
                    "right_salary_effect": 0,
                    "leave": 0,
                    "remain": 0
                },
                {
                    "employee_id": "",
                    "fname": "",
                    "lname": "",
                    "year": (startDate-1),
                    "leave_id": 3,
                    "leave_text": "ลาพักผ่อน",
                    "collect": 0,
                    "right": 0,
                    "right_salary_effect": 0,
                    "leave": 0,
                    "remain": 0
                }
              ];
  
  var nextThreeMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 3, 
    new Date().getDate()
  );

  
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [dataCurr, setDataCurr] = useState([]);
  const [dataR1, setDataR1] = useState('');
  const [dataR2, setDataR2] = useState('');
  const [dataR3, setDataR3] = useState('');
  const [dataC1, setDataC1] = useState('');
  const [dataC2, setDataC2] = useState('');
  const [dataC3, setDataC3] = useState('');
  const [dataL1, setDataL1] = useState('');
  const [dataL2, setDataL2] = useState('');
  const [dataL3, setDataL3] = useState('');
  const [dataRe1, setDataRe1] = useState('');
  const [dataRe2, setDataRe2] = useState('');
  const [dataRe3, setDataRe3] = useState('');
  const fetchData = async () => {
    let params = {
      criteria: {
        year: startDate,
      },
    };
    let rsDT = await axios.post(
      config.get("apiUrl") + "/api/leave/user/get-info",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if(rsDT.data.data.records == 0){rsDT.data.data.records=records_default;}
    setDt(rsDT.data.data.records);

    let result = rsDT.data.data.records.reduce(function (r, a) {
     let key = a.year || 'year';
      r[key] = r[key] || [];
      r[key].push(a);
      return r;
  }, Object.create(null));
  let yNow=startDate;
  setDataCurr(result[yNow]);
  setDataC1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].collect);
  setDataC2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].collect);
  setDataC3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].collect);
  setDataR1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].right);
  setDataR2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].right);
  setDataR3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].right);
  setDataL1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].leave);
  setDataL2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].leave);
  setDataL3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].leave);
  setDataRe1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].remain);
  setDataRe2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].remain);
  setDataRe3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].remain);
  //var newArray = DataPrev.filter(f => f.leave_text =='ลาป่วย')
  //var newArray  = result[2019].filter(f => f.leave_text =='ลาป่วย');
  //console.log(result[2019].filter(f => f.leave_text =='ลาป่วย')[0].right);
  };
  
  useEffect(() => {
    $('.datepicker').datepicker({
      format: "yyyy",
      orientation: "bottom",
      todayBtn: false,
      language: 'th',
      thaiyear: true,
      keyboardNavigation: false,
      viewMode: "years",
      minViewMode: "years"
    }).datepicker("setDate", startDate);
    $( ".start_date" ).change(function() {
      
      setStartDate(moment($('.start_date').val(),'YYYY').add(-543, 'year').format("YYYY"));
    });
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    let params = {
      criteria: {
        year: startDate,
      },
    };
    let rsDT = await axios.post(
      config.get("apiUrl") + "/api/leave/user/get-info",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    let records_default = [
        {
          "employee_id": "",
          "fname": "",
          "lname": "",
          "year": startDate,
          "leave_id": 1,
          "leave_text": "ลาป่วย",
          "collect": 0,
          "right": 0,
          "right_salary_effect": 0,
          "leave": 0,
          "remain": 0
      },
      {
          "employee_id": "",
          "fname": "",
          "lname": "",
          "year": startDate,
          "leave_id": 2,
          "leave_text": "ลากิจ",
          "collect": 0,
          "right": 0,
          "right_salary_effect": 0,
          "leave": 0,
          "remain": 0
      },
      {
          "employee_id": "",
          "fname": "",
          "lname": "",
          "year": startDate,
          "leave_id": 3,
          "leave_text": "ลาพักผ่อน",
          "collect": 0,
          "right": 0,
          "right_salary_effect": 0,
          "leave": 0,
          "remain": 0
      },
      {
          "employee_id": "",
          "fname": "",
          "lname": "",
          "year": (startDate-1),
          "leave_id": 1,
          "leave_text": "ลาป่วย",
          "collect": 0,
          "right": 0,
          "right_salary_effect": 0,
          "leave": 0,
          "remain": 0
      },
      {
          "employee_id": "",
          "fname": "",
          "lname": "",
          "year": (startDate-1),
          "leave_id": 2,
          "leave_text": "ลากิจ",
          "collect": 0,
          "right": 0,
          "right_salary_effect": 0,
          "leave": 0,
          "remain": 0
      },
      {
          "employee_id": "",
          "fname": "",
          "lname": "",
          "year": (startDate-1),
          "leave_id": 3,
          "leave_text": "ลาพักผ่อน",
          "collect": 0,
          "right": 0,
          "right_salary_effect": 0,
          "leave": 0,
          "remain": 0
      }
    ];
    if(rsDT.data.data.records.length == 0){rsDT.data.data.records=records_default;}
    setDt(rsDT.data.data.records);

    let result = rsDT.data.data.records.reduce(function (r, a) {
     let key = a.year || 'year';
      r[key] = r[key] || [];
      r[key].push(a);
      return r;
  }, Object.create(null));
  let yNow=startDate
  setDataCurr(result[yNow]);
  setDataC1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].collect);
  setDataC2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].collect);
  setDataC3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].collect);
  setDataR1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].right);
  setDataR2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].right);
  setDataR3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].right);
  setDataL1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].leave);
  setDataL2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].leave);
  setDataL3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].leave);
  setDataRe1(result[(yNow-1)].filter(f => f.leave_text =='ลาป่วย')[0].remain);
  setDataRe2(result[(yNow-1)].filter(f => f.leave_text =='ลากิจ')[0].remain);
  setDataRe3(result[(yNow-1)].filter(f => f.leave_text =='ลาพักผ่อน')[0].remain);
  };
  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">ระบบลา</li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ตรวจสอบสิทธิการลา
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                          ตรวจสอบสิทธิการลา
                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ปีงบประมาณ
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                          <div className="form-control-wrapper form-control-icon-right">
                                  <input type="text" defaultValue={startDate} name="start_date"  data-date-format="yyyy" className="datepicker start_date form-control shadow-none" />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <div className="pl-3">
                            <Button
                              type="button"
                              onClick={handleSubmit(onSubmit)}
                              variant="contained"
                              size="large"
                              className="btn-no-border btn-color-search"
                            >
                              <i className="fas fa-search pr-2"></i>
                              ค้นหา
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <span className="text-color-caption">สิทธิการลา</span>
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-head-fixed">
                          <thead>
                            <tr className="table-ddc">
                              <th className="table-ddc text-center" rowSpan="2" style={{width:"10%"}}>ประเภทการลา</th>
                              <th className="d-none d-md-table-cell table-ddc text-center" colSpan="4" style={{width:"40%"}}>ปีก่อนหน้า</th>
                              <th className="table-ddc text-center" colSpan="4" style={{width:"50%"}}>ปัจจุบัน</th>
                            </tr>
                            <tr>
                              <th className="d-none d-md-table-cell table-ddc text-center">สะสมจากปีก่อน</th>
                              <th className="d-none d-md-table-cell table-ddc text-center">สิทธิที่ได้</th>
                              <th className="d-none d-md-table-cell table-ddc text-center">สิทธิที่ใช้ไป</th>
                              <th className="d-none d-md-table-cell table-ddc text-center">สิทธิคงเหลือ</th>
                              <th className="table-ddc text-center">สะสมจากปีก่อน</th>
                              <th className="table-ddc text-center">สิทธิที่ได้</th>
                              <th className="table-ddc text-center">สิทธิที่ใช้ไป</th>
                              <th className="table-ddc text-center">สิทธิคงเหลือ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataCurr.map((item) => (
                              <tr key={item.leave_id}>
                                <td>{item.leave_text}</td>
                                <td className="d-none d-md-table-cell">
                                  {item.leave_text === 'ลาป่วย' ? dataC1 >= 150 ? "มีสิทธิลา" : dataC1 === 0 ? "-" : dataC1 : ""}
                                  {item.leave_text === 'ลากิจ' ? dataC2>= 150 ? "มีสิทธิลา" : dataC2 === 0 ? "-" : dataC2 : ""}
                                  {item.leave_text === 'ลาพักผ่อน' ? dataC3 === 365 ? "มีสิทธิลา" : dataC3 === null ? "-" : dataC3 : ""}
                                </td>
                                <td className="d-none d-md-table-cell">
                                  {item.leave_text === 'ลาป่วย' ? dataR1 >= 150 ? "มีสิทธิลา":dataR1:""}
                                  {item.leave_text === 'ลากิจ' ? dataR2 >= 150 ? "มีสิทธิลา":dataR2:""}
                                  {item.leave_text === 'ลาพักผ่อน' ? dataR3 === 365 ? "มีสิทธิลา" : dataR3 === null ? "-" : dataR3 : ""}
                                </td>
                                <td className="d-none d-md-table-cell">
                                  {item.leave_text === 'ลาป่วย' ? dataL1 >= 150 ? "มีสิทธิลา":dataL1:""}
                                  {item.leave_text === 'ลากิจ' ? dataL2 >= 150 ? "มีสิทธิลา":dataL2:""}
                                  {item.leave_text === 'ลาพักผ่อน' ? dataL3 === 365 ? "มีสิทธิลา" : dataL3 === null ? "-" : dataL3 : ""}
                                </td>
                                <td className="d-none d-md-table-cell">
                                  {item.leave_text === 'ลาป่วย' ? dataRe1 >= 150 ? "มีสิทธิลา":dataRe1:""}
                                  {item.leave_text === 'ลากิจ' ? dataRe2 >= 150 ? "มีสิทธิลา":dataRe2:""}
                                  {item.leave_text === 'ลาพักผ่อน' ? dataRe3 === 365 ? "มีสิทธิลา" : dataRe3 === null ? "-" : dataRe3 : ""}
                                </td>
                                <td>{item.collect === 0 ? "-" : item.collect === null ? "-" : item.collect}</td>
                                <td>{item.right === 365 ? "มีสิทธิลา" : item.right === null ? "-" : item.right}</td>
                                <td>{item.leave === 365 ? "มีสิทธิลา" : item.leave === null ? "-" : item.leave}</td>
                                <td>{item.right === 365 ? "มีสิทธิลา" : item.remain === null ? "-" : item.remain}</td>
                              </tr>
                            ))}
                            <tr>
                              <td ></td>
                              <td className="d-none d-md-table-cell" colSpan="4"></td>
                              <td colSpan="4"><span className="text-danger"> หมายเหตุ : คุณมีสิทธิลาป่วยตามระเบียบของการพิจารณาเงินเดือน {" "} {dataCurr.map((data) => (data.leave_text==="ลาป่วย"?data.right_salary_effect!==""?data.right_salary_effect:"-":""))} {" "} วัน{" "}/{" "}ลากิจ {" "} {dataCurr.map((data) => (data.leave_text==="ลากิจ"?data.right_salary_effect!==""?data.right_salary_effect:"-":""))} {" "} วัน{" "}/{" "}ลาพักผ่อน {" "} {dataCurr.map((data) => (data.leave_text==="ลาพักผ่อน"?data.right_salary_effect!==""?data.right_salary_effect:"-":""))} {" "} วัน หากลาเกินกำหนด จะไม่ได้รับการพิจารณาปรับเงินเดือน</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default LeaveRight;
