/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import config from "react-global-configuration";
import { Container, Image } from "react-bootstrap";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Button, TextField } from "@material-ui/core";

const UserManagement = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit } = useForm();
  const [searchRole,  setSearchRole] = useState("");
  const [roleName,  setRoleName] = useState("");
  const [roleMainView,  setRoleMainView] = useState(0);
  const [roleMainInsert,  setRoleMainInsert] = useState(0);
  const [roleMainEdit,  setRoleMainEdit] = useState(0);
  const [roleMainDelete,  setRoleMainDelete] = useState(0);
  const [roleTimeView,  setRoleTimeView] = useState(0);
  const [roleTimeInsert,  setRoleTimeInsert] = useState(0);
  const [roleTimeEdit,  setRoleTimeEdit] = useState(0);
  const [roleTimeDelete,  setRoleTimeDelete] = useState(0);
  const [roleLeaveView,  setRoleLeaveView] = useState(0);
  const [roleLeaveInsert,  setRoleLeaveInsert] = useState(0);
  const [roleLeaveEdit,  setRoleLeaveEdit] = useState(0);
  const [roleLeaveDelete,  setRoleLeaveDelete] = useState(0);
  const [roleBossView,  setRoleBossView] = useState(0);
  const [roleBossInsert,  setRoleBossInsert] = useState(0);
  const [roleBossEdit,  setRoleBossEdit] = useState(0);
  const [roleBossDelete,  setRoleBossDelete] = useState(0);
  const [roleReportView,  setRoleReportView] = useState(0);
  const [roleReportInsert,  setRoleReportInsert] = useState(0);
  const [roleReportEdit,  setRoleReportEdit] = useState(0);
  const [roleReportDelete,  setRoleReportDelete] = useState(0);
  const [roleAdminView,  setRoleAdminView] = useState(0);
  const [roleAdminInsert,  setRoleAdminInsert] = useState(0);
  const [roleAdminEdit,  setRoleAdminEdit] = useState(0);
  const [roleAdminDelete,  setRoleAdminDelete] = useState(0);
  

  const getData = async (data) => {
    let params = {
      criteria: {
        eq_role_id: searchRole,
      },
    };
    let rsData = await axios.post(
      config.get("apiUrl") + "/api/master/role/find",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
  
    if(rsData.data.status==="success")
    {
      setRoleName(rsData.data.data.role_name);
      setRoleMainView(rsData.data.data.main_view);
      setRoleMainInsert(rsData.data.data.main_insert);
      setRoleMainEdit(rsData.data.data.main_edit);
      setRoleMainDelete(rsData.data.data.main_delete);
      setRoleTimeView(rsData.data.data.time_view);
      setRoleTimeInsert(rsData.data.data.time_insert);
      setRoleTimeEdit(rsData.data.data.time_edit);
      setRoleTimeDelete(rsData.data.data.time_delete);
      setRoleLeaveView(rsData.data.data.leave_view);
      setRoleLeaveInsert(rsData.data.data.leave_insert);
      setRoleLeaveEdit(rsData.data.data.leave_edit);
      setRoleLeaveDelete(rsData.data.data.leave_delete);
      setRoleBossView(rsData.data.data.boss_view);
      setRoleBossInsert(rsData.data.data.boss_insert);
      setRoleBossEdit(rsData.data.data.boss_edit);
      setRoleBossDelete(rsData.data.data.boss_delete);
      setRoleReportView(rsData.data.data.report_view);
      setRoleReportInsert(rsData.data.data.report_insert);
      setRoleReportEdit(rsData.data.data.report_edit);
      setRoleReportDelete(rsData.data.data.report_delete);
      setRoleAdminView(rsData.data.data.admin_view);
      setRoleAdminInsert(rsData.data.data.admin_insert);
      setRoleAdminEdit(rsData.data.data.admin_edit);
      setRoleAdminDelete(rsData.data.data.admin_delete);
    }
    else
    {
      setRoleName("");
      setRoleMainView(0);
      setRoleMainInsert(0);
      setRoleMainEdit(0);
      setRoleMainDelete(0);
      setRoleTimeView(0);
      setRoleTimeInsert(0);
      setRoleTimeEdit(0);
      setRoleTimeDelete(0);
      setRoleLeaveView(0);
      setRoleLeaveInsert(0);
      setRoleLeaveEdit(0);
      setRoleLeaveDelete(0);
      setRoleBossView(0);
      setRoleBossInsert(0);
      setRoleBossEdit(0);
      setRoleBossDelete(0);
      setRoleReportView(0);
      setRoleReportInsert(0);
      setRoleReportEdit(0);
      setRoleReportDelete(0);
      setRoleAdminView(0);
      setRoleAdminInsert(0);
      setRoleAdminEdit(0);
      setRoleAdminDelete(0);
    }
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">ผู้ดูแลระบบ</li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                  การจัดการสิทธิการเข้าใช้งานระบบ

                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius elevation-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                        การจัดการสิทธิการเข้าใช้งานระบบ

                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              รหัส
                            </span>
                          </div>
                          <div className="col-8 col-md-2 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              ref={register}
                              value={searchRole}
                              onChange={(e) =>
                                setSearchRole(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-4 col-md-">
                            <Button
                              type="button"
                              variant="contained"
                              size="large"
                              className="btn-no-border btn-color-search"
                              onClick={(e) => getData(e)}
                            >
                              <i className="fas fa-search pr-2"></i>
                              ค้นหา
                            </Button>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ชื่อ
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              defaultValue={roleName}
                            />
                          </div>
                        </div>
                        <br />
                        <hr style={{ margin: "6px" }} />
                        <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              หน้าหลัก
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input type="checkbox" checked={roleMainView === 1} /> เรียกดู &nbsp;
                            <input type="checkbox" checked={roleMainInsert === 1} /> เพิ่ม &nbsp;
                            <input type="checkbox" checked={roleMainEdit === 1} /> แก้ไข &nbsp;
                            <input type="checkbox" checked={roleMainDelete === 1} /> ลบ
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              บันทึกการทำงาน
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input type="checkbox" checked={roleTimeView === 1} /> เรียกดู &nbsp;
                            <input type="checkbox" checked={roleTimeInsert === 1} /> เพิ่ม &nbsp;
                            <input type="checkbox" checked={roleTimeEdit === 1} /> แก้ไข &nbsp;
                            <input type="checkbox" checked={roleTimeDelete === 1} /> ลบ
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ระบบลา
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input type="checkbox" checked={roleLeaveView === 1} /> เรียกดู &nbsp;
                            <input type="checkbox" checked={roleLeaveInsert === 1} /> เพิ่ม &nbsp;
                            <input type="checkbox" checked={roleLeaveEdit === 1} /> แก้ไข &nbsp;
                            <input type="checkbox" checked={roleLeaveDelete === 1} /> ลบ
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ผู้บังคับบัญชา
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input type="checkbox" checked={roleBossView === 1} /> เรียกดู &nbsp;
                            <input type="checkbox" checked={roleBossInsert === 1} /> เพิ่ม &nbsp;
                            <input type="checkbox" checked={roleBossEdit === 1} /> แก้ไข &nbsp;
                            <input type="checkbox" checked={roleBossDelete === 1} /> ลบ
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              รายงาน
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input type="checkbox" checked={roleReportView === 1} /> เรียกดู &nbsp;
                            <input type="checkbox" checked={roleReportInsert === 1} /> เพิ่ม &nbsp;
                            <input type="checkbox" checked={roleReportEdit === 1} /> แก้ไข &nbsp;
                            <input type="checkbox" checked={roleReportDelete === 1} /> ลบ
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ผู้ดูแลระบบ
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input type="checkbox" checked={roleAdminView === 1}  /> เรียกดู &nbsp;
                            <input type="checkbox" checked={roleAdminInsert === 1}  /> เพิ่ม &nbsp;
                            <input type="checkbox" checked={roleAdminEdit === 1}  /> แก้ไข &nbsp;
                            <input type="checkbox" checked={roleAdminDelete === 1}  /> ลบ
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default UserManagement;
