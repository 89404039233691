/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import DataTable from "react-data-table-component";
import config from "react-global-configuration";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";

const AnnualHoliday = () => {
  const [keycloak] = useKeycloak();
  var calendarThai = new Date(
    new Date().getFullYear(),
    new Date().getMonth(), 
    new Date().getDate()
  );
  const navigate = useHistory();
  const [useBrake, setUseBrake] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [sortCol, setSortCol] = useState("holidayNumber");
  const [sortDir, setSortDir] = useState("asc");
  const [filter, setFilter] = useState("");
  const { register, handleSubmit } = useForm();
  const [holiday, setHoliday] = useState([]);
  const [groupId, setGroupID] = useState("");
  const [holidatId, setHolidayID] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupYear, setGroupYear] = useState(moment(calendarThai).format("YYYY"));
  const [groupDate, setGroupDate] = useState(calendarThai);
  const [holidayGroup, setHolidayGroup] = useState([]);

  const [gIdDel, setGIdDel] = useState("");
  const [idDel, setIdDel] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");
  const [showDelMsg, setShowDelMsg] = useState(false);
  const handleDelClose = () => setShowDelMsg(false);
  const handleDelShow = (g, h, e) => { setGIdDel(g);setIdDel(h);setShowDelMsg(true);}

  const fetchData = async () => {
    let reHolidayGroup = await axios.get(
      config.get("apiUrl") + "/api/master/holiday-group",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setHolidayGroup(reHolidayGroup.data.data.records);
  };

  const onClickSearch = async (e) => {
    let params = {
      criteria: {
        eq_holiday_group_id: groupId,
        eq_holiday_id: holidatId,
      },
    };
    let rsSearch = await axios.post(
      config.get("apiUrl") + "/api/master/holiday/find",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsSearch.data.status === "success") {
      setGroupID(rsSearch.data.data.holiday_group_id);
      setHolidayID(rsSearch.data.data.holiday_id);
      setGroupYear(new Date(rsSearch.data.data.year));
      setGroupDate(new Date(rsSearch.data.data.date));
      setGroupName(rsSearch.data.data.description);
    } else {
      handleShow();
      setTextMsg("ไม่พบข้อมูล");
      //alert(rsSearch.data.message_th);
    }
  };

  const onSubmit = async (data) => {
    let params = {
      holiday_group_id: data.holiday_group_id,
      holiday_id: data.eq_holiday_group_id,
      year: moment(groupYear).format("YYYY"),
      date: moment(groupDate,'DD/MM/YYYY').format("YYYY-MM-DD"),
      description: data.holiday_group_name,
    };
    let rsSave = await axios.post(
      config.get("apiUrl") + "/api/master/holiday/save",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsSave.data.status === "success") {
      handleShow();
      setTextMsg("บันทึกข้อมูลเรียบร้อย");
      fetchHoliday(1);
    } else {
      handleShow();
      setTextMsg("บันทึกข้อมูลไม่สำเร็จ");
    }
  };
  
  useEffect(() => {
    $('.start_date').datepicker({
      format: "yyyy",
      orientation: "bottom",
      todayBtn: false,
      language: 'th',
      thaiyear: true,
      keyboardNavigation: false,
      viewMode: "years",
      minViewMode: "years"
    }).datepicker("setDate", groupYear);
    $( ".start_date" ).change(function() {    
      setGroupYear(moment($('.start_date').val(),'YYYY').add(-543, 'year').format("YYYY"));
    });

    $('.group_date').datepicker({
      format: 'dd/mm/yyyy',
      todayBtn: false,
      language: 'th',
      thaiyear: true 
    }).datepicker("setDate", groupDate);
    $( ".group_date" ).change(function() {    
      setGroupDate(moment($('.group_date').val(),'DD/MM/YYYY').add(-543, 'year').format("DD/MM/YYYY"));
    });
    fetchHoliday();
    fetchData();
  }, [useBrake]);


  const columns = [

    {
      name: "รหัสวันหยุด",
      selector: "holiday_id",
      sortable: true,
    },
    {
      name: "ปี",
      selector: "year",
      sortable: true,
    },
    {
      name: "วันที่",
      selector: "date",
      sortable: true,
    },
    {
      name: "ชื่อ",
      selector: "description",
      sortable: true,
    },
    {
      name: "หน่วยงานที่ใช้",
      selector: "holiday_group_text",
      sortable: true,
    },
    {
      cell: (row) => (
       
        <Button
          type="button"
          variant="contained"
          className="btn-no-border btn-color-delete-small"
          onClick={(e) => handleDelShow(row.holiday_group_id,row.holiday_id, e)} id={row.holiday_id}
        >
          <i className="fas fa-trash-alt pr-2"></i>
          ลบ
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const fetchHoliday = async (page) => {
    setLoading(true);
    let params = {
      page_size: perPage,
      current_page: page,
      criteria: {
        like_holiday_group_id: "",
        like_holiday_id:"",
        like_year:"",
        from_date:"",
        to_date:"",
        like_description:"",
        like_holiday_group_text:"",
      },
      order_by: [
        {
        column_name: "date",
        direction:"",
        }
      ],
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/master/holiday/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setData(response.data.data.records);
    setTotalRows(response.data.data.total_records);
    setLoading(false);
  };

  const handleSort = async (column, sortDirection) => {
    //console.log(sortDirection);
    setLoading(true);
    let params = {
      page_size: perPage,
      current_page: 1,
      criteria: {
        like_holiday_group_id: "",
        like_holiday_id:"",
        like_year:"",
        from_date:"",
        to_date:"",
        like_description:"",
        like_holiday_group_text:"",
      },
      order_by: [
        {
        column_name: column.selector,
        direction:sortDirection,
        }
      ],
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/master/holiday/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setData(response.data.data.records);
    setTotalRows(response.data.data.total_records);
    setSortCol(column.selector);
    setSortDir(sortDirection);
    setPerPage(perPage);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchHoliday(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    let params = {
      page_size: newPerPage,
      current_page: page,
      criteria: {
        like_holiday_group_id: "",
        like_holiday_id:"",
        like_year:"",
        from_date:"",
        to_date:"",
        like_description:"",
        like_holiday_group_text:"",
      },
      order_by: [
        {
        column_name: "date",
        direction:"",
        }
      ],
    };
    const response = await axios.post(
      config.get("apiUrl") + "/api/master/holiday/search",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setData(response.data.data.records);
    setTotalRows(response.data.data.total_records);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const onClickDel = async (groupID, holidayID, e) => {
    handleDelClose();
    let rs = await axios.delete(
      config.get("apiUrl") + "/api/master/holiday/"+groupID+"/"+holidayID,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rs.data.status === "success") {
      handleShow();
      setTextMsg("ลบข้อมูลเรียบร้อย");
      fetchHoliday(1);
    } else {
      handleShow();
      setTextMsg("ลบข้อมูลไม่สำเร็จ");
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    ผู้ดูแลระบบ
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    วันหยุดประจำปี
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius elevation-3">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-12">
                          <span className="text-header-x2 ddc-color">
                            วันหยุดประจำปี
                          </span>
                          <br /> <br />
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                รหัสวันหยุด
                              </span>
                              <span className="text-danger"> *</span>
                            </div>
                            <div className="col-9 col-md-2 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="eq_holiday_group_id"
                                onChange={(e) =>
                                  setHolidayID(e.target.value)
                                }
                                ref={register}
                              />
                            </div>
                            <div className="col-3 col-md-2">
                              <Button
                                type="button"
                                variant="contained"
                                size="large"
                                className="btn-no-border btn-color-search"
                                onClick={(e) => onClickSearch(e)}
                              >
                                <i className="fas fa-search pr-2"></i>
                                ค้นหา
                              </Button>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ปี
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                            <div className="form-control-wrapper form-control-icon-right">
                                  <input type="text" defaultValue={groupYear} name="start_date"  data-date-format="yyyy" className="datepicker start_date form-control shadow-none" />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                วันที่
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                            <div className="form-control-wrapper form-control-icon-right">
                                  <input type="text" defaultValue={groupDate} name="group_date"  data-date-format="dd/MM/yyyy" className="datepicker group_date form-control shadow-none" />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                             
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-2">
                              <span className="text-color-form align-middle">
                                ชื่อ
                              </span>
                            </div>
                            <div className="col-12 col-md-4 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="holiday_group_name"
                                onChange={(e) => setGroupName(e.target.value)}
                                ref={register}
                                value={groupName}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-1 pt-4">
                              <span className="text-color-form align-middle">
                                หน่วยงานที่ใช้
                              </span>
                              <span className="text-danger"> *</span>
                            </div>
                            <div className="col-12 col-md-2 pt-3">
                              <select
                                className="form-control shadow-none"
                                name="holiday_group_id"
                                ref={register}
                                onChange={(e) =>
                                  setGroupID(e.target.value)
                                }
                              >
                                <option value="">กรุณาเลือก</option>
                                {holidayGroup.map((item) => (
                                  <option
                                    key={item.holiday_group_id}
                                    value={item.holiday_group_id}
                                    
                                  >
                                    {item.holiday_group_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row p-2">
                          <div className="col-md-6 mt-3 mt-3">
                          <Button
                              type="submit"
                              variant="contained"
                              className="btn-no-border btn-color-add-small btn-block"
                            >
                              <i className="fas fa-plus pr-2"></i>
                              เพิ่ม
                            </Button>
                          </div>
                          <div className="col-6 col-md-3 mt-3">
                          <Button
                              type="submit"
                              variant="contained"
                              className="btn-no-border btn-color-submit-small btn-block"
                            >
                              <i className="far fa-save pr-2"></i>
                              บันทึก
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <DataTable
                          columns={columns}
                          data={data}
                          onSort={handleSort}
                          sortServer
                          defaultSortField="holiday_id"
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationPerPage={20}
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          persistTableHead
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">{textMsg}</Modal.Body>
      </Modal>

      <Modal show={showDelMsg} onHide={handleDelClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">คุณต้องการลบข้อมูลใช่หรือไม่</Modal.Body>
        <Modal.Footer>
        <Button
                                type="button"
                                variant="contained"
                                className="btn-no-border btn-color-submit-small mr-2"
                                onClick={(e) => onClickDel(gIdDel,idDel)}
                              >
                              
                                ใช่
                              </Button>

                              <Button
                                type="button"
                                variant="contained"
                                className="btn-no-border btn-color-delete-small"
                                onClick={handleDelClose}
                              >
                                ไม่ใช่
                              </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AnnualHoliday;
