import {createSlice} from '@reduxjs/toolkit'

export const personnelInfoSlice = createSlice({
    name: 'personnelInfo',
    initialState: {
        // sMonth: "",
        // sYear: "",
        // sListSummary: [],
        userId: '',
    },
    reducers: {
        // setSelectMonth: (state, action) => {
        //     state.sMonth = action.payload
        // },
        // setSelectYear: (state, action) => {
        //     state.sYear = action.payload
        // },
        // setListSummary: (state, action) => {
        //     state.sListSummary = action.payload
        // },
        setUserId: (state, action) => {
              state.userId = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const {setUserId} = personnelInfoSlice.actions
// export const sMonth = state => state.counter.sMonth;
// export const sYear = state => state.counter.sYear;
// export const sListSummary = state => state.counter.sListSummary;
export const userId = state => state.counter.userId;
export default personnelInfoSlice.reducer