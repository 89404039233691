import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Image, Modal } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import packageJson from "../../package.json";


const Topnav = () => {
    const [keycloak] = useKeycloak();
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const [infoModal, setInfoModal] = useState(false);
    const infoClose = () => setInfoModal(false);
    const infoShow = () => setInfoModal(true);
    const [employee, setEmployee] = useState([]);
    const [empRole, setEmpRole] = useState("User");
    // const [empImgView, setEmpImgView] = useState(
    //   "img/avatar.png"
    // );

    const getInfo = async () => {

        let info = await axios.get(
            config.get("apiUrl") + "/api/employee/" + keycloak.tokenParsed.preferred_username,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${keycloak.token}`,
                },
            }
        );
        //console.log(info);
        if (info.data.status === "success") {
            setEmployee(info.data.data);
            // if(info.data.data.picture !== null){setEmpImgView(config.get("apiUrl") + "/api/file/" + info.data.data.picture);}
            if (info.data.data.role_id[0]) {
                if (info.data.data.role_id[0] === "1") {
                    setEmpRole("Admin");
                }
                if (info.data.data.role_id[0] === "2") {
                    setEmpRole("Boss");
                }
                if (info.data.data.role_id[0] === "3") {
                    setEmpRole("User");
                }
            }
        }
        infoShow();

    };

    const empRoleText = () => {
        switch (empRole) {
            case "user":
                return "ผู้ใช้งาน";
            case "admin":
                return "ผู้ดูแลระบบ";
            case "boss":
                return "หัวหน้างาน";
            default:
                return "ผู้ใช้งาน";
        }
    };


    return (
        <nav className="navbar navbar-expand-xl bg-color fixed-top hk-navbar box-main-shadow">
            <Link
                to="#"
                className="navbar-brand navbar-link-color"
                data-widget="pushmenu"
                role="button"
            >
        <span className="nav-text">
          <i className="fas fa-bars navbar-fa-size"></i>
          <Image
              className="ml-3 d-xl-inline-block"
              src="img/logo-small.png"
              style={{width: "40px"}}
          />
          <span
              className="ml-2 d-xl-none nav-text text-shadow-header"
          >
            ระบบข้อมูลบุคลากร {process.env.REACT_APP_TITLE || ''}
          </span>
        </span>
            </Link>

            <div className="d-none d-xl-block w-100">
                <div className="d-flex justify-content-center">
                    <span className="nav-text text-shadow-header">ระบบข้อมูลบุคลากร {process.env.REACT_APP_TITLE || ''}</span>
                </div>
            </div>

            <ul className="navbar-nav ml-auto">
                <Dropdown>
                    <Dropdown.Toggle className="btn-profile btn-transparent" size="sm" id="profile-menu">
                        <i className="fas fa-user-circle fa-lg mr-2"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className="dropdown-transparent" href="#">
                            <div className="d-flex" onClick={(e) => getInfo()}>
                                <div className="mr-2"><i className="fas fa-user dropdown-icon"></i></div>
                                <div className="font-16">ข้อมูลส่วนตัว</div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <div className="d-flex" onClick={() => {
                                openInNewTab(process.env.REACT_APP_KEYCLOAK_PROFILE)
                            }}>
                                <div className="mr-2"><i className="fas fa-cog dropdown-icon"></i></div>
                                <div className="font-16">กำหนดค่า</div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item href="#">
                            <div className="d-flex" onClick={(e) => keycloak.logout()}>
                                <div className="mr-2"><i className="fas fa-power-off dropdown-icon"></i></div>
                                <div className="font-16">ออกจากระบบ</div>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </ul>

            <Modal show={infoModal} onHide={infoClose} size="md" centered>
                <div className="card-header card-default">
                    <div className="media align-items-center">
                        <div className="d-flex mr-15">
                            <div className="avatar avatar-sm">
                                <i className="fas fa-3x fa-user-circle label-color-dark"></i>
                            </div>
                        </div>
                        <div className="media-body text-left margin-left-15">
                            <div className="text-capitalize font-weight-500 text-dark"><span>{employee.fname} {employee.lname}</span></div>
                            <div className="subtitle-profile"><span>{employee.email}</span></div>
                        </div>
                    </div>
                </div>
                <div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <div>
                                <span className="label label-dark font-14-force">สิทธิ์:</span>
                                <span className="ml-3 text-dark font-13-force">{empRoleText()}</span>
                            </div>
                            <div className="mt-2">
                                <span className="label label-dark font-14-force">เวอร์ชัน:</span>
                                <span className="ml-2 text-dark font-13-force">{packageJson.version}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>
        </nav>
    );
};

export default Topnav;
