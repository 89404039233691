/* eslint-disable */
import React, { useState } from 'react';
import config from 'react-global-configuration';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useKeycloak } from '@react-keycloak/web';
import { Button } from '@material-ui/core';
import DateInput from '../util/DateInput';

const CardProcess = () => {
  const [keycloak] = useKeycloak();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const startDateChange = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const endDateChange = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setStartDate(date);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let start = moment(startDate).format('YYYY-MM-DD');
    let end = moment(endDate).format('YYYY-MM-DD');
    await axios
      .get(config.get('apiUrl') + `/api/card/process/${start}/${end}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    ผู้ดูแลระบบ
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ประมวลผลสแกนบัตร
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-1 pt-2">
                        <span className="text-color-form align-middle">
                          ช่วงวันที่
                        </span>
                      </div>
                      <div className="col-12 col-md-6 pt-1">
                        <div className="row">
                          <div className="col-5">
                            <DateInput
                              value={startDate}
                              onChange={(date) => startDateChange(date)}
                              label="วันที่เริ่มต้น"
                            />
                          </div>
                          <div className="col-2 text-center mt-2">ถึง</div>
                          <div className="col-5">
                            <DateInput
                              value={endDate}
                              onChange={(date) => endDateChange(date)}
                              label="วันที่สิ้นสุด"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mt-3 ml-3">
                        <Button
                          type="button"
                          variant="contained"
                          className="btn-no-border btn-color-search"
                          onClick={onSubmit}
                          disabled={isLoading}
                        >
                          <i className="fas fa-server pr-2"></i>
                          ประมวลผล
                          {isLoading && (
                            <div
                              className="spinner-border spinner-border-sm ml-2"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default CardProcess;
