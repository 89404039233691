/* eslint-disable */
import React, { useState, useEffect } from 'react';
import config from 'react-global-configuration';
import { useForm } from 'react-hook-form';
import { Container, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useKeycloak } from '@react-keycloak/web';
import { WatDatePicker } from 'thaidatepicker-react';
import { Button, TextField } from '@material-ui/core';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import th from 'date-fns/locale/th';
registerLocale('th', th);
import isRole from '../util/isRole';
import DateInput from '../util/DateInput';
import { useSelector, useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';
import { userId, setUserId } from '../../app/Admin/PersonnelInfoSlice';

import $ from 'jquery';
import { hasAnyRole } from '../util/AuthUtil';

const OrgDataTable = () => {
    let history = useHistory();
    const [keycloak] = useKeycloak();

    const [dataTable, setDataTable] = useState(null);
    const [workbu, setWorkbu] = useState([]);
    const [orgId, setOrgId] = useState('');
    const [selectOrg, setSelectOrg] = useState({});
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        setShowModal(false);
    };

    const initOrhChart = (data) => {
        setSelectOrg({});
        setSelectOrg(data);
        setShowModal(true);
    };

    useEffect(() => {
        fetchData();
    }, [keycloak.token]);

    const loadDataTableOrgChart = async () => {
        $('#data-table-add-special-organization').DataTable({
            // order: [1, 'asc'],
            searching: false,
            ajax: {
                contentType: 'application/json',
                url: config.get('apiUrl') + '/api/develop/org-chart/datatable/' + selectOrg.orgId,
                type: 'POST',
                data: function (d) {
                    return JSON.stringify(d);
                },
                headers: {
                    Authorization: `bearer ${keycloak.token}`,
                },
            },
            processing: true,
            serverSide: true,
            columnDefs: [{ width: '100px' }],
            columns: [
                {
                    data: 'orgId',
                    className: 'text-center',
                    width: '20%',
                },
                {
                    data: 'orgName',
                    className: 'text-left',
                    width: '20%',
                },
                {
                    data: 'depth',
                    className: 'text-center',
                    width: '20%',
                },
            ],
            rowCallback: function (row, data) {},
            createdRow: function (row, data, dataIndex) {

              if (String(data['depth']) == "0") {
                  console.log($(row))
                  $(row).css("background-color", "#e84c93");
              }
          },
            lengthChange: true,
            ordering: false,
            info: true,
            responsive: true,
            dom: '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'ค้นหา...',
                lengthMenu: '_MENU_ รายการ/หน้า',
                sLengthMenu: '_MENU_ รายการ/หน้า',
                sProcessing: 'กำลังดำเนินการ...',
                sZeroRecords: 'ไม่พบข้อมูล',
                sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                sInfoFiltered: '',
                sSearch: '',
                sUrl: '',
                oPaginate: {
                    sFirst: 'หน้าแรก',
                    sPrevious: 'ก่อนหน้า',
                    sNext: 'ถัดไป',
                    sLast: 'หน้าสุดท้าย',
                },
            },
        });
    };

    const fetchData = async () => {
        let rsWorkbu = await axios.get(config.get('apiUrl') + '/api/master/org/1/1001', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `bearer ${keycloak.token}`,
            },
        });
        rsWorkbu.data.data.records.sort((a, b) => (a.name > b.name ? 1 : -1));
        setWorkbu(rsWorkbu.data.data.records);

        if (keycloak.tokenParsed) {
            let rsUser = await axios.get(config.get('apiUrl') + '/api/employee/user-login/work-bu1', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `bearer ${keycloak.token}`,
                },
            });
            if (rsUser.data.status === 'success') {
                let empOrgId = rsUser.data.data || '';
                setOrgId(empOrgId);

                initDataTable(empOrgId);
            }
        }
    };

    const initDataTable = (empOrgId = null) => {
        $.fn.dataTable.ext.errMode = 'none';

        empOrgId = empOrgId ? empOrgId : orgId;

        setDataTable(
            $('#data-table').DataTable({
                order: [1, 'asc'],
                ajax: {
                    contentType: 'application/json',
                    url: config.get('apiUrl') + '/api/develop/org/datatable',
                    type: 'POST',
                    data: function (d) {
                        return JSON.stringify(d);
                    },
                    headers: {
                        Authorization: `bearer ${keycloak.token}`,
                    },
                },
                processing: true,
                serverSide: true,
                columns: [
                    {
                        width: '100px',
                        orderable: true,
                        data: 'orgId',
                        className: 'text-center',
                        responsivePriority: 1,
                    },
                    {
                        width: '800px',
                        orderable: true,
                        data: 'orgName',
                        className: 'text-left',
                    },
                    {
                        width: '100px',
                        orderable: false,
                        data: '',
                        className: 'text-center',
                        render: function (data, type, row) {
                            return `<button class="btn bg-color text-light btn-show" type="button""><i class="far fa-eye mr-2"></i>ดูข้อมูล</button>`;
                        },
                    },
                ],
                rowCallback: function (row, data) {
                    $('td', row)
                        .find('.btn-show')
                        .on('click', function (e) {
                            initOrhChart(data);
                        });
                },
                lengthChange: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'ค้นหา...',
                    lengthMenu: '_MENU_ รายการ/หน้า',
                    sLengthMenu: '_MENU_ รายการ/หน้า',
                    sProcessing: 'กำลังดำเนินการ...',
                    sZeroRecords: 'ไม่พบข้อมูล',
                    sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                    sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                    sInfoFiltered: '',
                    sSearch: '',
                    sUrl: '',
                    oPaginate: {
                        sFirst: 'หน้าแรก',
                        sPrevious: 'ก่อนหน้า',
                        sNext: 'ถัดไป',
                        sLast: 'หน้าสุดท้าย',
                    },
                    processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
                },
            })
        );

        // Datable Component CSS.
        document.querySelector('div.dataTables_length select').classList.add('datatable-length');
        document.querySelector('div.dataTables_filter input').classList.add('datatable-search');
    };

    // const searchDataTable = () => {
    //     if (dataTable) {
    //         dataTable.ajax.url(config.get('apiUrl') + '/api/v2/employee/datatable').load();
    //     }
    // };

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="row mb-3 border-bottom">
                            <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                    <li className="breadcrumb-item text-breadcrumb">
                                        <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                                        <Link to="./" className="text-breadcrumb">
                                            หน้าหลัก
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item text-breadcrumb">ผู้ดูแลระบบ</li>
                                    <li className="breadcrumb-item text-breadcrumb-active">ข้อมูลแผนผังหน่วยงาน</li>
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <span className="text-header-x2 ddc-color">ข้อมูลแผนผังหน่วยงาน</span>
                                    <br />
                                    <br />
                                    <br />
                                    <table id="data-table" className="table table-striped table-bordered display w-100 margin-bottom-30">
                                        <thead>
                                            <tr>
                                                <th className="text-center">รหัสหน่วยงาน</th>
                                                <th className="text-center">ชื่อหน่วยงาน</th>
                                                <th className="text-center">ดำเนินการ</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>

            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                onEntered={() => {
                    loadDataTableOrgChart();
                }}
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-sitemap mr-2"></i>แผนผังหน่วยงาน
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mt-2">
                                        <div className="table-header-masexpert card-header text-dark">ลำดับหน่วยงาน</div>
                                        <div className="card-body">
                                            <table id="data-table-add-special-organization" className="table table-striped table-bordered display w-100 margin-bottom-30">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">รหัสหน่วยงาน</th>
                                                        <th className="text-center">ชื่อหน่วยงาน</th>
                                                        <th className="text-center">ลำดับ</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn-color-delete-small" onClick={handleClose}>
                        <i className="fas fa-ban pr-2"></i>
                        ปิด
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default OrgDataTable;
