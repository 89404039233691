import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';


export function PrivateRoute({ component: Component, roles, ...rest }) {
  const [keycloak] = useKeycloak();
  const isAutherized = (roles) => {
    if (keycloak && roles) {
        return roles.some(r => {
            const realm =  keycloak.hasRealmRole(r);
            const resource = keycloak.hasResourceRole(r);
            return realm || resource;
        });
    }
    return false;
   }
   
   return (
      <Route
        {...rest}
        render={props => {
            return isAutherized(roles)
                ? <Component {...props}/>
                : <Redirect to={{ pathname: '/', }} />
        }}
      />
      /*<Route
        {...rest}
        render={props => {
            return <Component {...props}/>
        }}
      />*/
    )
}

export function IndexRoute({ validcomponent: Validcomponent, invalidcomponent: InvalidcomponentFalse, roles, ...rest }) {
  const [keycloak] = useKeycloak();
  const isAutherized = (roles) => {
    if (keycloak && roles) {
        return roles.some(r => {
            const realm =  keycloak.hasRealmRole(r);
            const resource = keycloak.hasResourceRole(r);
            return realm || resource;
        });
    }
    return false;
   }
  const setIndexPage = (props) => {
    if(isAutherized(['admin', 'admin-dep'])){
      return <Validcomponent {...props}/>
    }else if(isAutherized(['user', "boss", "center", "supervisor"])){
      return <InvalidcomponentFalse {...props}/>
    }else{
      return <Redirect to={{ pathname: '/', }} />
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        return setIndexPage(props);
        // return <Redirect to={{ pathname: setIndexPage(), }} />
    }}
    />
  )
}